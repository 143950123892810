/**
 * ResponsiveProvider
 */

import React, { createContext, ReactNode, useEffect, useState } from 'react'

import { RESPONSIVE_MOBILE_BREAKPOINT } from '../../constants/config'

interface IResponsiveActions {
  //
}

interface IResponsiveStore {
  isDesktop: boolean
  isMobile: boolean
}

interface IResponsiveContext {
  actions: IResponsiveActions;
  store: IResponsiveStore;
}

interface ResponsiveProviderProps {
  children: ReactNode
}

export const ResponsiveContext = createContext<IResponsiveContext>({} as IResponsiveContext)

const ResponsiveProvider = (props: ResponsiveProviderProps) => {
  const { children } = props

  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const listener = () => {
      if (window.innerWidth > RESPONSIVE_MOBILE_BREAKPOINT) {
        setIsDesktop(true)
        setIsMobile(false)
      } else {
        setIsDesktop(false)
        setIsMobile(true)
      }
    }
    listener()
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [])

  const actions: IResponsiveActions = {
    isDesktop,
    isMobile
  }

  const store: IResponsiveStore = {
    isDesktop,
    isMobile
  }

  return (
    <ResponsiveContext.Provider value={{ actions, store }}>
      {children}
    </ResponsiveContext.Provider>
  )
}

export default ResponsiveProvider
