import React from 'react'

import StreamhubPage from '../../components/StreamhubPage/StreamhubPage'
import StreamhubProcessesView from './StreamhubProcessesView'

class StreamhubProcessesPage extends React.Component<{}, {}> {
  render () {
    return (
      <StreamhubPage>
        <StreamhubProcessesView />
      </StreamhubPage>
    )
  }
}
export default StreamhubProcessesPage
