import React, { useContext } from 'react'
import _ from 'lodash'

import ServerAPIClient from '../services/ServerAPIClient'
import ServerAuthAPI from '../services/ServerAuthAPI'
import ServerCompanyAPI from '../services/ServerCompanyAPI'
import ServerProjectAPI from '../services/ServerProjectAPI'
import ServerUserAPI from '../services/ServerUserAPI'
import ServerGuestAPI from '../services/ServerGuestAPI'
import { Channel, Program, Project, User, UserCache, UserCompany, UserCompanyRole, UserProject, UserProjectRole } from '../models'
import {
  /* withNavContext, */ INavMultiContext,
  withAuthContext, IAuthMultiContext, AuthStatus
  // AuthCompanyStatus, AuthProjectStatus
} from './'
// NB: hit an odd 'TypeError: Object(...) is not a function' error importing withNavContext with the others from './', importing directly seems to fix it - maybe related to ref: https://stackoverflow.com/a/51631743
import { withNavContext, NavSection } from './NavProvider'
import * as ROUTES from 'src/constants/routes'

import UserService from '../services/UserService'
import UserSelectionsService from '../services/UserSelectionsService'
import { IServerConfigContext } from './ServerConfigProvider'

// TODO: remove error status state so we always know if logged in/out or are loading, & add a specific error var to check for issues?
export enum UserStatus {
  init, loading, loggedIn, loggedOut, error
}

export type ProjectCompanyAccessLookup = { companyId: number, access: Array<'view' | 'admin'>}

export interface IUserStore {
  userStatus: UserStatus // NB: this is only set to 'done' once the auth is passed AND all key user data has been loaded (via a few api calls)
  user?: User // NB: the user object is currently a ref to the original within ServerAuthAPI, which we update when the AuthProvider triggers updates
  userCache?: UserCache
  userCompanies?: Array<UserCompany>
  userProjects?: Array<UserProject>
  adminProjects?: Array<Project> // NB: these are currently only used for 'company admin' level projects (not project manager or admin level ones)
  projectChannels?: Array<Channel>
  channelPrograms?: Array<Program>
  channelProgramsUpdatedAt?: Date
  selectedCompany?: UserCompany
  selectedProject?: UserProject | Project // NB: Project is used for admin only project access
  selectedChannel?: Channel
  loadingCompanyData: boolean // currently loadingX is true if doing an initial load or an update (where updatingX will also be true)
  loadingProjectData: boolean
  loadingChannelData: boolean
  updatingCompanyData: boolean // updatingX is true only if an update is running, no selection change
  updatingProjectData: boolean
  updatingChannelData: boolean
}

export interface IUserActions {
  // user data
  reloadUserData: () => Promise<void>
  // user selections
  selectCompany: (companyId: number, autoLoadProjectAndChannel?: boolean) => Promise<void>
  selectProject: (projectId: number, autoLoadChannel?: boolean) => Promise<void>
  selectChannel: (channelId: number, redirect?: boolean) => Promise<void>
  deselectCurrentCompany: () => void
  deselectCurrentProject: () => void
  deselectCurrentChannel: () => void
  // user selection - data reload helpers
  updateSelectedProject: () => Promise<void>
  // user selection - channel program helpers
  updateSelectedChannelPrograms: () => Promise<void>
  doAnySelectedChannelProgramsHaveSLDPHotlinkEnabled: () => boolean
  // users company project helpers
  getProjectManagerOrAdminProjects: () => Array<UserProject>
  getCompanyAdminOrProjectManagerOrAdminProjects: () => Array<Project | UserProject>
  // user status
  isVerified: () => boolean
  // user roles/access-levels
  isAdmin: () => boolean
  isSiteAdmin: () => boolean
  isCompanyAdminOrHigher: () => boolean // company/site admin
  isProjectAdminOrHigher: () => boolean // project/company/site admin
  isProjectManagerOrHigher: () => boolean // project manager, or project/company/site admin
  isCompanyAdminOrHigherInAnyCompany: () => boolean
  isProjectManagerOrHigherInAnyCompany: () => boolean
  // user updates
  updateUserName: (firstName: string, lastName: string) => Promise<void>
  // user cache
  saveUserCache: (userCache: UserCache) => void
  // refresh
  refreshChannels: () => Promise<void>
}

export interface IUserContext {
  actions: IUserActions;
  store: IUserStore;
}

export interface IUserMultiContext {
  userContext: IUserContext
}

export const UserContext = React.createContext<IUserContext>({} as IUserContext)

export const useUser = () => useContext(UserContext)

export interface UserProviderProps extends INavMultiContext, IAuthMultiContext {
  apiClient: ServerAPIClient
  authApi: ServerAuthAPI
  userService?: UserService
  userSelectionsService?: UserSelectionsService
  serverConfigContext?: IServerConfigContext
}
export interface UserProviderState extends IUserStore {
  userService: UserService
  userSelectionsService: UserSelectionsService
}

class UserProvider extends React.Component<UserProviderProps, UserProviderState> {
  // static contextType = AuthContext // access the auth context provider
  private _companyAPI: ServerCompanyAPI
  private _projectAPI: ServerProjectAPI
  private _userAPI: ServerUserAPI
  private _guestAPI: ServerGuestAPI

  constructor (props: UserProviderProps) {
    super(props)
    this._companyAPI = new ServerCompanyAPI(props.apiClient)
    this._projectAPI = new ServerProjectAPI(props.apiClient)
    this._userAPI = new ServerUserAPI(props.apiClient)
    this._guestAPI = new ServerGuestAPI(props.apiClient)
    this.state = {
      // store
      userStatus: UserStatus.init,
      user: undefined,
      userCache: undefined,
      userCompanies: undefined,
      userProjects: undefined,
      adminProjects: undefined,
      projectChannels: undefined,
      channelPrograms: undefined,
      channelProgramsUpdatedAt: undefined,
      selectedCompany: undefined,
      selectedProject: undefined,
      selectedChannel: undefined,
      loadingCompanyData: false,
      loadingProjectData: false,
      loadingChannelData: false,
      updatingCompanyData: false,
      updatingProjectData: false,
      updatingChannelData: false,
      // local state
      userService: props.userService ?? new UserService(),
      userSelectionsService: props.userSelectionsService ?? new UserSelectionsService()
    }
  }

  componentDidUpdate (prevProps: UserProviderProps, _prevState: UserProviderState) {
    // check for any auth changes & update local state accordingly
    const authStatus = this.props.authContext.store.authStatus
    const prevAuthStatus = prevProps.authContext.store.authStatus
    if (authStatus === AuthStatus.loggedIn && prevAuthStatus !== AuthStatus.loggedIn) {
      // logged in
      console.log('UserProvider - componentDidUpdate - USER LOGGED IN')
      // TESTING: check the path we came from to see if we just logged in vs a page refresh (which still triggers this 'user logged in' event)
      const currentPath = this.props.navContext.store.currentPath
      const justLoggedIn = (currentPath === ROUTES.LOGIN || currentPath === ROUTES.LOGIN_SSO || currentPath === ROUTES.REGISTER || currentPath === ROUTES.REGISTER_SSO)
      // TESTING: check if a redirect was set before the login, so we can flag if so & avoid auto redirecting to cached project/channel selections when `loadUserData` & in turn `loadUserSelections` are triggered below
      const redirectFrom = this.props.navContext.actions.getRedirectPath()
      const didRedirect = (redirectFrom !== undefined)
      console.log('UserProvider - componentDidUpdate - redirectFrom:', redirectFrom, ' didRedirect:', didRedirect)
      this.loadUserData(false, justLoggedIn, didRedirect)
    } else if (authStatus === AuthStatus.loggedOut && prevAuthStatus !== AuthStatus.loggedOut) {
      // logged out
      console.log('UserProvider - componentDidUpdate - USER LOGGED OUT')
      this.clearUserData()
    } else if (authStatus !== prevAuthStatus) {
      console.log('UserProvider - componentDidUpdate - OTHER AUTH STATUS CHANGE - FROM: ', prevAuthStatus, ' TO: ', authStatus)
      // NB: shouldn't need to do anything here (should only fire when transitioning into the initial loading auth status)
    } else if (this.props.authContext.store.authUpdated !== prevProps.authContext.store.authUpdated) {
      console.log('UserProvider - componentDidUpdate - OTHER USER AUTH/STATUS CHANGE - this.state.userStatus: ', this.state.userStatus)
      // if the auth was updated, re-grab the user object to grab whatever changes were made (e.g email verified, 2fa enabled/disabled etc.)
      // TESTING: re-run the full user data updates, incase the auth/status change effected access to any of the data
      // TODO: don't change the userStatus while doing it though?
      // TODO: call the newer reloadUserData? but don't trigger an /auth/me update as this would of just been from one??
      // TESTING: don't re-trigger while the userStatus is already loading
      if (this.state.userStatus !== UserStatus.loading) {
        this.loadUserData(true)
      }
    }
    // check for any company/project 2fa auth changes
    // TODO: DEPRECIATE - org/project forced 2fa switch auth
    // // trigger additional data to load once verification completes, so we can load the needed extra data (like projects available for a given company which 2fa blocks until complete)
    // // TODO: this gets the available projects loaded when a force 2fa company is logged in to, but doesn't currently auto reload previous selections
    // const companyAuthStatus = this.props.authContext.store.companyAuthStatus
    // const prevCompanyAuthStatus = prevProps.authContext.store.companyAuthStatus
    // const projectAuthStatus = this.props.authContext.store.projectAuthStatus
    // const prevProjectAuthStatus = prevProps.authContext.store.projectAuthStatus
    // if (prevCompanyAuthStatus === AuthCompanyStatus.tfa && companyAuthStatus === AuthCompanyStatus.verified) {
    //   // company 2fa just verified/completed
    //   console.log('UserProvider - componentDidUpdate - COMPANY 2FA VERIFIED')
    //   // NB: reloadUserData also calls /auth/me but we don't need to reload the base user data, just the additional sub-data like projects for the now 2fa verified company
    //   // this.reloadUserData()
    //   // this.loadUserData(true)
    //   this.loadUserDataAfterCompany2FA()
    // } else if (prevProjectAuthStatus === AuthProjectStatus.tfa && projectAuthStatus === AuthProjectStatus.verified) {
    //   // project 2fa just verified/completed
    //   console.log('UserProvider - componentDidUpdate - PROJECT 2FA VERIFIED')
    //   // NB: reloadUserData also calls /auth/me but we don't need to reload the base user data, just the additional sub-data like projects for the now 2fa verified company
    //   // this.reloadUserData()
    //   // this.loadUserData(true)
    //   this.loadUserDataAfterProject2FA()
    // }
  }

  // -------

  // TODO: should this only been called for initial login/page-load, & if we need to re-load, add a specific reloadUserData function?
  // TODO: (if not, maybe only conditionally re-load the cache & maybe certain other actions?)
  loadUserData = async (reload: boolean = false, justLoggedIn: boolean = false, didRedirect: boolean = false) => {
    console.log('UserProvider - loadUserData - reload: ', reload, ' justLoggedIn:', justLoggedIn, ' didRedirect:', didRedirect, ' window.location.pathname:', window.location.pathname)
    const user = this.props.authApi.authUser
    if (!user) {
      this.setState({ userStatus: UserStatus.loggedOut })
      return
    }

    this.setState({ userStatus: (reload ? this.state.userStatus : UserStatus.loading), user: user })

    const userCache = this.loadUserCache(user.id)
    this.setState({ userCache })

    if (this.props.authApi.authToken === 'DUMMY') {
      this.setState({ userStatus: UserStatus.loggedIn })
      return
    }

    // TESTING: load the server config data so its available site-wide after logging in (as it requires an auth token)
    // TODO: run when reload === true or only false? (does it return if the user isn't verified yet etc. if so always load on reload? or trigger on certain events like 'just verified' (if we can detect that))
    await this.props.serverConfigContext?.actions.loadServerConfig()

    try {
      let userCompanies = await this._companyAPI.getUserCompanies() ?? undefined

      // TESTING: for site-admins/god, load all companies & map them to UserCompany models, so they can be referenced in the normal UI
      if (user.isSiteAdmin()) {
        const allCompanies = await this._companyAPI.getAllCompanies()
        if (allCompanies) {
          const allUserCompanies: Array<UserCompany> = []
          for (const company of allCompanies) {
            // NB: manually setting the company role as UserCompanyRole.admin for now - TODO: add a dedicated SiteAdmin role instead?
            const userCompany = company.asUserCompany(UserCompanyRole.admin)
            allUserCompanies?.push(userCompany)
          }
          userCompanies = allUserCompanies ?? undefined
        }
      }

      this.setState({ userCompanies })
    } catch (error) {
      console.error('UserProvider - loadUserData - error: ', error)
      // TODO: consider this logged out?? but what the auth status, user data etc. would need to clear that all as well?
      // TODO: or set to loggedIn but flag an error occured, would need to make sure AppRouter or a core/root component catches & handles it if we do..
      this.setState({ userStatus: UserStatus.loggedOut })
      return
    }

    await this.loadUserSelections(reload, justLoggedIn, didRedirect)

    this.setState({ userStatus: UserStatus.loggedIn })
  }

  reloadUserData = async () => {
    console.log('UserProvider - reloadUserData')

    // TESTING: also re-load the main user object (currently done via the original auth api layer)
    // TESTING: NB: we don't do this within loadUserData, as on init the user object has just been loaded by the AuthProvider,
    // TESTING: NB: ..so we just grab that version from its cache to save a dupe api call
    // NB: this will trigger the AuthProvider authUpdated date prop to update, which the componentDidUpdate catches above & calls loadUserData to load the rest of the data
    await this.props.authApi.loadLoggedInUser()

    // UPDATE: commented out to stop dupe calls (remove after testing reloads throughout the app to make sure it always fires like that)
    // NB: the call to loadLoggedInUser triggers the AuthProvider authUpdated date prop to update,
    // NB: ..which the componentDidUpdate catches above & triggers loadUserData anyway
    // await this.loadUserData(true)

    // TESTING: also update the selections otherwise they can refer to old/stale data
    // TESTING: having to manually trigger a (re)select of each company, project, & channel instead of using the selectCompany `autoLoadProjectAndChannel` arg
    // TESTING: ..as that currently also kicks in the redirect handling which we don't want, so instead we manually reload each & disable auto reloads & redirects
    if (this.state.selectedCompany) {
      // TODO: stop the re-directs kicking in!!! <<<
      await this.selectCompany(this.state.selectedCompany.id, false, false)
      if (this.state.selectedProject) {
        await this.selectProject(this.state.selectedProject.id, false, false)
        if (this.state.selectedChannel) {
          await this.selectChannel(this.state.selectedChannel.id, false)
        }
      }
    }
  }

  // TODO: DEPRECIATE - org/project forced 2fa switch auth
  // TESTING: only certain data & urls maybe set after a forced 2fa login, so we handle those scenarios here
  // loadUserDataAfterCompany2FA = async () => {
  //   // first run the normal user data load in reload mode
  //   // which in turn also calls loadUserSelections, but only in a way it loads ids from the url
  //   // but due to the 2fa restriction won't have the channel id in the url
  //   await this.loadUserData(true)

  //   console.log('UserProvider - loadUserDataAfterCompany2FA - selectedCompany: ', this.state.selectedCompany, ' selectedProject: ', this.state.selectedProject, ' selectedChannel: ', this.state.selectedChannel)
  //   if (this.state.selectedCompany && !this.state.selectedProject) {
  //     // TESTING: re-select the company but this time enable loading of the selected project (& channel) from the cache (if one is set in the cache)
  //     this.selectCompany(this.state.selectedCompany.id, true, false, true) // indicate force2faVerified = true so it loads the selected project
  //   }
  // }

  // TODO: DEPRECIATE - org/project forced 2fa switch auth
  // loadUserDataAfterProject2FA = async () => {
  //   // first run the normal user data load in reload mode
  //   // which in turn also calls loadUserSelections, but only in a way it loads ids from the url
  //   // but due to the 2fa restriction won't have the channel id in the url
  //   await this.loadUserData(true)

  //   // so we then try loading the project channel selection from the cache if one is set for the current project
  //   console.log('UserProvider - loadUserDataAfterProject2FA - selectedProject: ', this.state.selectedProject, ' selectedChannel: ', this.state.selectedChannel)
  //   if (this.state.selectedProject && !this.state.selectedChannel) {
  //     // TESTING: re-select the project but this time enable loading of the selected channel from the cache (if one is set in the cache)
  //     this.selectProject(this.state.selectedProject.id, true)
  //   }
  // }

  clearUserData = () => {
    console.log('UserProvider - clearUserData')
    this.setState({
      userStatus: UserStatus.loggedOut,
      user: undefined,
      userCache: undefined,
      userCompanies: undefined,
      userProjects: undefined,
      adminProjects: undefined,
      projectChannels: undefined,
      channelPrograms: undefined,
      channelProgramsUpdatedAt: undefined,
      selectedCompany: undefined,
      selectedProject: undefined,
      selectedChannel: undefined,
      loadingCompanyData: false,
      loadingProjectData: false,
      loadingChannelData: false,
      updatingCompanyData: false,
      updatingProjectData: false,
      updatingChannelData: false
    })
  }

  // -------

  // this handles loading additional data for the current selections
  // NB: this is called (via `loadUserData`) on page load/init & when certain data changes which then needs the main user data to be reloaded
  // NB: the `reload` arg was originally added to distinguish between the two main call scenarios
  // NB: but we now also need to distinguish between 'just logged in' vs refreshing/reloading the page, to decide if we redirect to cached vars or not
  loadUserSelections = async (reload: boolean = false, justLoggedIn: boolean = false, didRedirect: boolean = false) => {
    console.log('UserProvider - loadUserSelections - reload: ', reload, ' justLoggedIn:', justLoggedIn, ' didRedirect:', didRedirect)

    const user = this.state.user
    if (!user) return

    // TESTING: don't attempt to load the user data selections if the account/email isn't verified yet (they won't have access to the api endpoints until they do)
    if (!user.emailVerified) {
      console.log('UserProvider - loadUserSelections - email NOT VERIFIED - HALT')
      return
    }

    // parse the project & channel ids from the url if they're set
    const projectId = this.props.navContext.store.urlIds.projectId
    const channelId = this.props.navContext.store.urlIds.channelId
    console.log('UserProvider - loadUserSelections - projectId: ', projectId, ' channelId: ', channelId)

    // NB: we use the companyId from the cache, as its not supplied via the url like the projectId & channelId are
    const currentCompanyId: number | undefined = this.state.userCache?.currentCompanyId
    console.log('UserProvider - loadUserSelections - currentCompanyId:', currentCompanyId)
    let loadCompanyId = currentCompanyId
    if (currentCompanyId) {
      if (projectId) {
        // TESTING: check if the projectId is for the current selected company, or another one the user has access too
        // NB: this will be undefined if the project>company id lookup failed to find a matching project the user has access to (but it maybe a valid project id that others with access can still use)
        // TODO: take into account the new view/admin access level returned with the companyId - handle differently depending on that?
        const companyAccessLookup = this.lookupCompanyIdForProject(projectId)
        console.log('UserProvider - loadUserSelections - companyAccessLookup: ', companyAccessLookup)
        if (companyAccessLookup !== undefined && companyAccessLookup.companyId !== currentCompanyId) {
          console.log('UserProvider - loadUserSelections - WARNING - PROJECT ID BELONGS TO A DIFFERENT COMPANY THE USER HAS ACCESS TOO - SWITCH COMPANIES...')
          // TODO: switch companies? run this before the initial selectCompany call above?
          // TODO: what if that company has required 2fa enabled (once we support & enforce it) will need to handle that (as part of the normal base company switching?)
          loadCompanyId = companyAccessLookup.companyId
        } else if (companyAccessLookup === undefined) {
          console.log('UserProvider - loadUserSelections - WARNING - USER DOESN\'T HAVE ACCESS TO THIS PROJECT(?)')
          // TODO: allow the cached company selection to load still
          // TODO: just leave the projectId to attempt to load & fail below, so standard error shows, or do something special here??
        }
      }
    } else {
      console.log('UserProvider - loadUserSelections - NO (CACHED) COMPANY SELECTED')
      // TESTING: check if the projectId is set when a company isn't (fresh login)
      if (projectId) {
        const companyAccessLookup = this.lookupCompanyIdForProject(projectId)
        console.log('UserProvider - loadUserSelections - companyAccessLookup: ', companyAccessLookup)
        if (companyAccessLookup !== undefined) {
          console.log('UserProvider - loadUserSelections - NOTICE - PROJECT ID BELONGS TO A COMPANY THE USER HAS ACCESS TOO - SELECT COMPANY...')
          // TODO: switch companies? run this before the initial selectCompany call above?
          // TODO: what if that company has required 2fa enabled (once we support & enforce it) will need to handle that (as part of the normal base company switching?)
          loadCompanyId = companyAccessLookup.companyId
        } else if (companyAccessLookup === undefined) {
          console.log('UserProvider - loadUserSelections - WARNING - USER DOESN\'T HAVE ACCESS TO THIS PROJECT(?)')
          // TODO: allow the cached company selection to load still
          // TODO: just leave the projectId to attempt to load & fail below, so standard error shows, or do something special here??
        }
      }
    }

    // select the company - but don't load & apply its cached selections (project, channel) if there are any, we use the url args on init
    // UPDATE: DO load the cached selections if they're set, but only if none were specified in the url (so we don't override them)
    // UPDATE: ONLY auto load the project/channel if the user just logged in vs reloading/refreshing the page (so we don't naviagte away from the current page when refreshing)
    // NOTE: if a url/path was specified before logging in, that gets loaded/applied in the `AppRouter` `componentDidUpdate` handling & so takes priority over this
    // NOTE: we check if `didRedirect` is not true before redirecting to the project/channel, otherwise non-viewer url redirects would get overridden by this
    if (loadCompanyId) {
      const redirectFrom = this.props.navContext.actions.getRedirectPath()
      const autoLoadProjectAndChannel = (projectId === undefined && channelId === undefined) && justLoggedIn && !didRedirect
      console.log('UserProvider - loadUserSelections - autoLoadProjectAndChannel:', autoLoadProjectAndChannel, ' projectId:', projectId, ' channelId:', channelId, ' justLoggedIn:', justLoggedIn, ' redirectFrom:', redirectFrom, ' didRedirect:', didRedirect)
      const resetAuth = false // keep the company/project 2fa auth tokens from init (should only be kept on iniial load or reload of same selection - so always disable it here regardless? (should be ok for now regardless as only used on init))
      console.log('UserProvider - loadUserSelections - loadCompanyId:', loadCompanyId)
      await this.selectCompany(loadCompanyId, autoLoadProjectAndChannel, resetAuth)
      // select the relevant project/channel if any set
      if (this.state.selectedCompany && projectId) {
        await this.selectProject(projectId, autoLoadProjectAndChannel, resetAuth)
        if (this.state.selectedProject && this.state.selectedProject instanceof UserProject && this.state.selectedProject.userAccessEnabled && channelId) {
          await this.selectChannel(channelId)
        }
      }
    }
  }

  // loads projects only the user has access too
  loadCompanyProjects = async (companyId: number, userCompanyRole?: UserCompanyRole): Promise<{ userProjects?: Array<UserProject>, adminProjects?: Array<Project> }> => {
    try {
      // load the user accessible projects for the specified company
      const userProjects = await this._projectAPI.getUserCompanyProjects(companyId) ?? undefined
      // if user is a company admin also load all company projects
      let adminProjects: Array<Project> | undefined
      if (userCompanyRole === UserCompanyRole.admin) {
        adminProjects = await this._projectAPI.getAllCompanyProjects(companyId) ?? undefined
      }
      return { userProjects, adminProjects }
    } catch (error) {
      console.error('UserProvider - loadCompanyProjects - error: ', error)
      // TODO: throw an error & make sure calling code is setup to catch? or mute the error & just return an empty result like this?
      return { userProjects: undefined, adminProjects: undefined }
    }
  }

  // loads project channels only the user has access too
  loadUserCompanyProjectChannels = async (companyId: number, projectId: number) => {
    console.log('UserProvider - loadUserCompanyProjectChannels - companyId: ', companyId, ' projectId: ', projectId)
    const user = this.state.user
    if (!user) { return }
    try {
      const projectChannels = await this._projectAPI.getUserCompanyProjectChannels(companyId, projectId)
      const company = this.getUserCompany(companyId)
      if (company) {
        const project = this.getUserProject(projectId)
        if (project) {
          // project.channels = projectChannels ?? undefined
          return projectChannels ?? undefined
        }
      }
    } catch (error) {
      console.error('UserProvider - loadUserCompanyProjectChannels - error: ', error)
      // TODO: ...
    }
  }

  loadUserCompanyProjectChannelPrograms = async (companyId: number, projectId: number, channelId: number) => {
    const user = this.state.user
    if (!user) { return }
    try {
      const channelPrograms = await this._projectAPI.getUserCompanyProjectChannelPrograms(companyId, projectId, channelId)
      const company = this.getUserCompany(companyId)
      if (company) {
        const project = this.getUserProject(projectId)
        if (project) {
          const channel = this.getChannel(channelId)
          if (channel) {
            // channel.programs = channelPrograms ?? undefined
            return channelPrograms ?? undefined
          }
        }
      }
    } catch (error) {
      console.error('UserProvider - loadCompanyProjectChannelPrograms - error: ', error)
      throw error
    }
  }

  // -------

  selectCompany = async (companyId: number, autoLoadProjectAndChannel: boolean = true, resetAuth: boolean = true, force2faVerified: boolean = false) => {
    // console.log('UserProvider - selectCompany - companyId: ', companyId, ' autoLoadProjectAndChannel:', autoLoadProjectAndChannel, ' resetAuth:', resetAuth, ' force2faVerified:', force2faVerified)
    if (this.state.user && this.state.userCache && this.state.userCompanies) {
      const company = this.getUserCompany(companyId)
      if (company) {
        const userCache = this.state.userCache
        userCache.currentCompanyId = companyId
        this.saveUserCache(userCache)

        const updating = (this.state.selectedCompany !== undefined && this.state.selectedCompany.id === companyId)

        // TESTING: only clear the previously selected project & channel here if the company selection has changed (or no company was selected before)
        // NB: don't clear them here if we're reloading the same company (which triggers this selectCompany)
        // NB: otherwise this will briefly cause section routers to reload with no project/channel showing & then reload once they're auto reselected (from being the previous cached selection)
        // NB: & that can cause the current page a user was on to reset & loose any component state changes it had (most obvious in some project manager sections like the project > group > channel mappings)
        const resetSubSelections = !updating

        // select the company instantly before loading any additonal data it needs, clear any sub-data so it doesn't remain/show during loading
        this.setState({
          selectedCompany: company,
          selectedProject: resetSubSelections ? undefined : this.state.selectedProject,
          selectedChannel: resetSubSelections ? undefined : this.state.selectedChannel,
          loadingCompanyData: true, // indicate we're still loading additional sub-data
          updatingCompanyData: updating,
          userCache,
          userProjects: undefined,
          adminProjects: undefined,
          projectChannels: undefined,
          channelPrograms: undefined,
          channelProgramsUpdatedAt: undefined
        })

        // TESTING - reset whenever a company is selected (skip if updating an existing selection)
        // TODO: handle this depending if forced 2fa is enabled AND if the user has a valid company tfa token (which may require an api call to check, so might just want to clear it here & let future api calls update if/when its needed?)
        if (resetAuth && !updating) {
          // TODO: DEPRECIATE - org/project forced 2fa switch auth
          // this.props.authApi.updateCompanyAuthToken(undefined)
          // this.props.authApi.updateProjectAuthToken(undefined)
          this.props.authApi.setCompany2FARequired(false)
          this.props.authApi.setProject2FARequired(false)
        }

        // TODO: this will fail if company 2fa is required - should we just not run it until we have a company 2fa token set?
        // TODO: we could instead use the user.projectViewLookup & user.projectAdminLookup vars if we just need to know if a user has access to them
        console.log('UserProvider - selectCompany - company: ', company, ' autoLoadProjectAndChannel: ', autoLoadProjectAndChannel)
        const { userProjects, adminProjects } = await this.loadCompanyProjects(companyId, company?.userCompanyRole)
        console.log('UserProvider - selectCompany - userProjects: ', userProjects, ' adminProjects: ', adminProjects)

        this.setState({
          userProjects,
          adminProjects,
          loadingCompanyData: false,
          loadingProjectData: false,
          loadingChannelData: false,
          updatingCompanyData: false,
          updatingProjectData: false,
          updatingChannelData: false
        })

        // auto load the project (& potentially) channel if the user only has access to a single project in this company, or past cached selections
        if (autoLoadProjectAndChannel) {
          const loadCachedSubSelections = true
          // TESTING: if the user only has access to a single project, auto select it (skip this if the user has admin access to other projects) (regardless if the page just loaded or they're navigating within it)
          if (userProjects && userProjects.length === 1 && (adminProjects === undefined || adminProjects.length === 0)) {
            console.log('UserProvider - selectCompany - AUTO SELECT ONLY (USER) PROJECT ACCESSIBLE IN COMPANY - projectId: ', userProjects[0].id)
            await this.selectProject(userProjects[0].id)
          } else if (adminProjects && adminProjects.length === 1 && (userProjects === undefined || userProjects.length === 0)) {
            console.log('UserProvider - selectCompany - AUTO SELECT ONLY (ADMIN) PROJECT ACCESSIBLE IN COMPANY - projectId: ', adminProjects[0].id)
            await this.selectProject(adminProjects[0].id)
          } else {
            // auto select the project if its set in the cache for this company (& cached sub selections are enabled)
            if (loadCachedSubSelections) {
              // check if a project selection for this company is cached, load it if so
              // UPDATE: but skip loading it if force 2fa is enabled for the company (we don't have the needed project data at this point if so)
              // UPDATE: we instead just let it load the default section instead, not the project (can we load the project after company 2fa is complete?)
              const selectedProjectId = userCache.getSelectedCompanyProjectId(company.id)
              console.log('UserProvider - selectCompany - selectedProjectId: ', selectedProjectId)
              if (selectedProjectId && (!company.force2fa || force2faVerified)) {
                console.log('UserProvider - selectCompany - AUTO SELECT CACHED SELECTED PROJECT - projectId: ', selectedProjectId)
                await this.selectProject(selectedProjectId)
              } else {
                // check if its ok to navigate away from the current path/url
                const canAutoNav = this.props.navContext.actions.canAutoNav()
                if (canAutoNav) {
                  // TESTING: stay in the same section as the user is currently in (/viewer or /project etc.) or default to the viewer if they're not in a main section currently
                  // TODO: if admin only project don't redirect to the viewer, only redirect to /project?
                  const currentSection = this.props.navContext.store.currentSection
                  const newSection = ([NavSection.company, NavSection.project, NavSection.viewer].includes(currentSection) ? currentSection : NavSection.viewer)
                  const path = this.props.navContext.actions.getSectionBasePath(newSection)
                  console.log('UserProvider - selectCompany - currentSection: ', currentSection, ' newSection: ', newSection, ' path: ', path)
                  this.props.navContext.actions.goto(path)
                }
              }
            }
          }
        }
        return
      }
    }
    console.warn('UserProvider - selectCompany - WARNING: FAILED TO SELECT COMPANY')
  }

  selectProject = async (projectId: number, autoLoadChannel: boolean = true, resetAuth: boolean = true) => {
    // console.log('UserProvider - selectProject - projectId: ', projectId, ' autoLoadChannel:', autoLoadChannel, ' resetAuth:', resetAuth)
    // checks to make sure the user currently has access to the project (will have an entry locally from a previous api call)
    if (this.state.user && this.state.userCache && this.state.userProjects && this.state.selectedCompany) {
      const company = this.state.selectedCompany
      if (company) {
        const project = this.getUserProject(projectId) ?? this.getAdminProject(projectId)
        if (project) {
          const userCache = this.state.userCache
          userCache.setSelectedCompanyProject(company.id, project.id)
          this.saveUserCache(userCache)

          const updating = (this.state.selectedProject !== undefined && this.state.selectedProject.id === projectId)
          const resetSubSelections = !updating

          // select the project instantly before loading any additonal data it needs, clear any sub-data so it doesn't remain/show during loading
          this.setState({
            selectedProject: project,
            selectedChannel: resetSubSelections ? undefined : this.state.selectedChannel,
            loadingProjectData: true, // indicate we're still loading additional sub-data
            updatingProjectData: updating,
            userCache,
            projectChannels: undefined,
            channelPrograms: undefined,
            channelProgramsUpdatedAt: undefined
          })

          // TESTING - reset whenever a project is selected (skip if updating an existing selection)
          // TODO: handle this depending if forced 2fa is enabled AND if the user has a valid project tfa token (which may require an api call to check, so might just want to clear it here & let future api calls update if/when its needed?)
          if (resetAuth && !updating) {
            // TODO: DEPRECIATE - org/project forced 2fa switch auth
            // this.props.authApi.updateProjectAuthToken(undefined)
            this.props.authApi.setProject2FARequired(false)
          }

          // load channels for the selected project if its a user (viewer) project (not admin only) & they have access enabled
          let projectChannels: Array<Channel> | undefined
          if ((project instanceof UserProject) && project.userAccessEnabled) {
            projectChannels = await this.loadUserCompanyProjectChannels(company.id, project.id)
          }

          this.setState({
            projectChannels,
            loadingProjectData: false,
            loadingChannelData: false,
            updatingProjectData: false,
            updatingChannelData: false
          })

          // auto load the channel if the user only has access to a single channel in this project, or past cached selections
          if (autoLoadChannel) {
            const loadCachedSubSelections = true
            // TESTING: if the user only has access to a single channel, auto select it (regardless if the page just loaded or they're navigating within it)
            if (projectChannels && projectChannels.length === 1) {
              console.log('UserProvider - selectProject - AUTO SELECT ONLY CHANNEL ACCESSIBLE IN PROJECT - channelId: ', projectChannels[0].id)
              await this.selectChannel(projectChannels[0].id)
            } else {
              // auto select the channel if its set in the cache for this project (& cached sub selections are enabled)
              if (loadCachedSubSelections) {
                const selectedChannelId = this.state.userCache.getSelectedCompanyProjectChannelId(company.id, projectId)
                console.log('UserProvider - selectProject - selectedChannelId: ', selectedChannelId)
                // TESTING: if 2fa is required & not passed yet projectChannels will have failed to load, so don't attempt to auto load the past selection (added `projectChannels !== undefined` to the existing check)
                if (selectedChannelId && projectChannels !== undefined) {
                  console.log('UserProvider - selectProject - AUTO SELECT CACHED SELECTED CHANNEL - channelId: ', selectedChannelId)
                  await this.selectChannel(selectedChannelId)
                } else {
                  // check if its ok to navigate away from the current path/url
                  const canAutoNav = this.props.navContext.actions.canAutoNav()
                  if (canAutoNav) {
                    // TESTING: stay in the same section as the user is currently in (/viewer or /project etc.) or default to the viewer if they're not in a main section currently
                    // TODO: if admin only project don't redirect to the viewer, only redirect to /project?
                    const currentSection = this.props.navContext.store.currentSection
                    const newSection = ([NavSection.company, NavSection.project, NavSection.viewer].includes(currentSection) ? currentSection : NavSection.viewer)
                    const path = this.props.navContext.actions.getSectionProjectPath(newSection, projectId)
                    console.log('UserProvider - selectProject - canAutoNav: ', canAutoNav, ' currentSection: ', currentSection, ' newSection: ', newSection, ' path: ', path)
                    this.props.navContext.actions.goto(path)
                  } else {
                    console.log('UserProvider - selectProject - canAutoNav: ', canAutoNav, ' - WARNING: DON\'T NAV AWAY FROM CURRENT PATH')
                  }
                }
              }
            }
          }
          return
        }
      }
    }
    console.warn('UserProvider - selectProject - WARNING: FAILED TO SELECT PROJECT')
  }

  // selects a channel for the current company project
  selectChannel = async (channelId: number, redirect: boolean = true) => {
    console.log('UserProvider - selectChannel - channelId: ', channelId)
    // checks to make sure the user currently has access to the project (will have an entry locally from a previous api call)
    // if (this.user && this.user.userCache?.currentCompanyId && this.currentProjectId) {
    if (this.state.user && this.state.userCache && this.state.selectedCompany && this.state.selectedProject) {
      const company = this.state.selectedCompany
      if (company) {
        const project = this.state.selectedProject
        if (project) {
          const channel = this.getChannel(channelId)
          if (channel) {
            // // user has access to the project channel (in some form), ok to set it as the current/selected channel...
            // this.currentChannelId = channelId
            // this.user.userCache.setSelectedCompanyProjectChannel(company.id, project.id, channelId)
            // this.saveUserCache(this.user)

            // // TODO: once we cache channel program data, load any program specific selections/settings from the local cache...

            // // trigger data to load for the new selections
            // await this.loadUserSelections()

            const userCache = this.state.userCache
            userCache.setSelectedCompanyProjectChannel(company.id, project.id, channelId)
            this.saveUserCache(userCache)

            const updating = (this.state.selectedChannel !== undefined && this.state.selectedChannel.id === channelId)

            this.setState({
              selectedChannel: channel,
              loadingChannelData: true,
              updatingChannelData: updating,
              userCache,
              channelPrograms: undefined,
              channelProgramsUpdatedAt: undefined
            })

            // load programs for the selected channel
            try {
              const channelPrograms = await this.loadUserCompanyProjectChannelPrograms(company.id, project.id, channelId)
              console.log('UserProvider - selectChannel - channelPrograms:', channelPrograms)

              this.setState({
                channelPrograms,
                channelProgramsUpdatedAt: new Date(),
                loadingChannelData: false,
                updatingChannelData: false
              })
            } catch (error) {
              // TODO: how to handle errors loading the channel programs here??
              this.setState({
                // channelPrograms,
                // channelProgramsUpdatedAt: new Date(),
                loadingChannelData: false,
                updatingChannelData: false
              })
            }

            if (redirect) {
              // redirect to the channel viewer if the user has viewer access (& not admin only)
              // NB: channel selection only happens in the viewer, so keep the user in that section
              const isUserProject = (project instanceof UserProject)
              if (isUserProject) {
                // check if its ok to navigate away from the current path/url
                const canAutoNav = this.props.navContext.actions.canAutoNav()
                if (canAutoNav) {
                  // TESTING: only update the url if we're already in the viewer, otherwise leave it (otherwise it can trigger when say selecting a project while in the project mgr & this is loaded from the cache)
                  // UPDATE: but we need it to run if the user changed company or project (& we reach here)?
                  // UPDATE: testing out using the same handling as the selectProject redirect
                  // const currentSection = this.props.navContext.store.currentSection
                  // if (currentSection === NavSection.viewer) {
                  //   const newSection = NavSection.viewer
                  // TESTING: stay in the same section as the user is currently in (/viewer or /project etc.) or default to the viewer if they're not in a main section currently
                  // TODO: if admin only project don't redirect to the viewer, only redirect to /project?
                  const currentSection = this.props.navContext.store.currentSection
                  const newSection = ([NavSection.company, NavSection.project, NavSection.viewer].includes(currentSection) ? currentSection : NavSection.viewer)
                  const path = this.props.navContext.actions.getSectionProjectChannelPath(newSection, project.id, channelId)
                  console.log('UserProvider - selectChannel - currentSection: ', currentSection, ' newSection: ', newSection, ' path: ', path)
                  this.props.navContext.actions.goto(path)
                  // }
                }
              }
            }

            return
          }
        }
      }
    }
    console.warn('UserProvider - selectChannel - WARNING: FAILED TO SELECT CHANNEL')
  }

  // -------

  // TESTING: update the current project data from the api after its been edited
  // WARNING: this does NOT update any other data around/linked to the project like channels etc. just the project data model itself so its in sync
  updateSelectedProject = async () => {
    if (this.state.selectedCompany && this.state.selectedProject) {
      // TODO: should `updatingProjectData` be set before & after as well? (technically thats normally only updated when its child data is loading, & the updating compant data var equiv was being set?) <<<<
      // TODO: instead of updating ALL projects could/should we just update a specific one (if theres a suitable endpoint that would give us the exact data we'd need equiv to the multi/all project `loadCompanyProjects` call?)
      const company = this.state.selectedCompany
      const projectId = this.state.selectedProject.id
      const { userProjects, adminProjects } = await this.loadCompanyProjects(company.id, company.userCompanyRole)
      this.setState({ userProjects, adminProjects })
      // NB: we pass in the user & admin projects arrays incase the setState call hasn't applied/finished by the time we call these helper lookup calls..
      const project = this.getUserProject(projectId, userProjects) ?? this.getAdminProject(projectId, adminProjects)
      if (project) {
        this.setState({
          selectedProject: project
        })
      }
    }
  }

  // -------

  // triggers an update of the current channel programs data - so we can get fresh hotlink tokens in the urls (if hotlinking it enabled)
  // TODO: add a way to force update regardless of last update time? (if triggering manually instead of part of hotlinking)
  updateSelectedChannelPrograms = async () => {
    console.warn('UserProvider - updateSelectedChannelPrograms - updatingChannelData:', this.state.updatingChannelData, ' channelProgramsUpdatedAt:', this.state.channelProgramsUpdatedAt)
    // ignore if already updating
    if (this.state.updatingChannelData) {
      console.warn('UserProvider - updateSelectedChannelPrograms - ALREADY UPDATING - IGNORE')
      return
    }
    // TESTING: don't allow too many calls in quick succession (mainly for hotlink expiry triggers from multiple different players in a short period)
    const lastUpdateSecs = this.state.channelProgramsUpdatedAt ? Math.floor((+new Date() - this.state.channelProgramsUpdatedAt.getTime()) / 1000) : undefined
    console.warn('UserProvider - updateSelectedChannelPrograms - lastUpdateSecs:', lastUpdateSecs)
    if (lastUpdateSecs && lastUpdateSecs < 30) {
      console.warn('UserProvider - updateSelectedChannelPrograms - UPDATED RECENTLY (' + lastUpdateSecs + 'secs ago) - IGNORE')
      return
    }
    if (this.state.user && this.state.userCache && this.state.selectedCompany && this.state.selectedProject && this.state.selectedChannel) {
      // reload programs for the selected channel
      this.setState({ updatingChannelData: true })
      try {
        const channelPrograms = await this.loadUserCompanyProjectChannelPrograms(this.state.selectedCompany.id, this.state.selectedProject.id, this.state.selectedChannel.id)
        console.log('UserProvider - updateSelectedChannelPrograms - channelPrograms:', channelPrograms)
        this.setState({
          channelPrograms,
          channelProgramsUpdatedAt: new Date(),
          updatingChannelData: false
        })
      } catch (error) {
        console.error('UserProvider - updateSelectedChannelPrograms - error:', error)
        // TODO: how to handle errors loading the channel programs here??
        this.setState({
          // channelPrograms,
          // channelProgramsUpdatedAt: new Date(),
          updatingChannelData: false
        })
      }
    }
  }

  // helper that returns true if one of more of the current selected channel programs have SLDP hotlink enabled (so we know urls will need updating periodically to refresh the hotlink token)
  doAnySelectedChannelProgramsHaveSLDPHotlinkEnabled = () => {
    if (this.state.channelPrograms && this.state.channelPrograms.length > 0) {
      for (const channelProgram of this.state.channelPrograms) {
        if (channelProgram.isHotlinkEnabledForSLDP()) {
          return true
        }
      }
    }
    return false
  }

  // -------

  // DEBUG USE ONLY CURRENTLY:

  deselectCurrentCompany = () => {
    console.log('UserProvider - deselectCurrentCompany')
    if (this.state.user && this.state.userCache) {
      const userCache = this.state.userCache
      userCache.currentCompanyId = undefined
      this.saveUserCache(userCache)
      this.setState({
        userCache,
        selectedCompany: undefined,
        selectedProject: undefined,
        selectedChannel: undefined,
        userProjects: undefined,
        adminProjects: undefined,
        projectChannels: undefined,
        channelPrograms: undefined,
        loadingCompanyData: false,
        loadingProjectData: false,
        loadingChannelData: false,
        updatingCompanyData: false,
        updatingProjectData: false,
        updatingChannelData: false
      })

      // TODO: DEPRECIATE - org/project forced 2fa switch auth
      // TESTING - reset whenever a company is deselected
      // this.props.authApi.updateCompanyAuthToken(undefined)
      // this.props.authApi.updateProjectAuthToken(undefined)
      this.props.authApi.setCompany2FARequired(false)
      this.props.authApi.setProject2FARequired(false)

      // TODO: redirect?
    }
  }

  deselectCurrentProject = () => {
    console.log('UserProvider - deselectCurrentProject')
    if (this.state.user && this.state.userCache && this.state.selectedCompany) {
      const userCache = this.state.userCache
      userCache.setSelectedCompanyProject(this.state.selectedCompany.id, undefined)
      this.saveUserCache(userCache)
      this.setState({
        userCache,
        selectedProject: undefined,
        selectedChannel: undefined,
        projectChannels: undefined,
        channelPrograms: undefined,
        loadingProjectData: false,
        loadingChannelData: false,
        updatingProjectData: false,
        updatingChannelData: false
      })

      // TODO: DEPRECIATE - org/project forced 2fa switch auth
      // TESTING - reset whenever a project is deselected
      // this.props.authApi.updateProjectAuthToken(undefined)
      this.props.authApi.setProject2FARequired(false)

      // check if its ok to navigate away from the current path/url
      const canAutoNav = this.props.navContext.actions.canAutoNav()
      if (canAutoNav) {
        // TESTING: if in the /viewer or /project sections redirect to the relevant project select page
        const currentSection = this.props.navContext.store.currentSection
        if ([NavSection.viewer, NavSection.project].includes(currentSection)) {
          const path = this.props.navContext.actions.getSectionProjectSelectPath(currentSection)
          console.log('UserProvider - deselectCurrentProject - REDIRECT TO - currentSection: ', currentSection, ' path: ', path)
          this.props.navContext.actions.goto(path)
        }
      }
    }
  }

  deselectCurrentChannel = () => {
    if (this.state.user && this.state.userCache && this.state.selectedCompany && this.state.selectedProject) {
      const userCache = this.state.userCache
      userCache.setSelectedCompanyProjectChannel(this.state.selectedCompany.id, this.state.selectedProject.id, undefined)
      this.saveUserCache(userCache)
      this.setState({
        userCache,
        selectedChannel: undefined,
        channelPrograms: undefined,
        loadingChannelData: false,
        updatingChannelData: false
      })

      // TODO: redirect?
    }
  }

  // -------

  getUserCompany = (companyId: number) : UserCompany | undefined => {
    return this.state.userCompanies?.find((company: UserCompany) => company.id === companyId)
  }

  // TESTING: helper that returns the company id for a given project id IF the user has access to the project & so has the mapping data available
  // NB: currently just using this for multi/cross-company url support, to allow loading of a project from a company that isn't selected if the user has access to it
  // NB: now checks against both view & admin specific lookups, & returns an object that includes the companyId and which access levels the user has
  lookupCompanyIdForProject = (projectId: number): ProjectCompanyAccessLookup | undefined => {
    const projectViewLookup = this.state.user?.projectViewLookup
    const projectAdminLookup = this.state.user?.projectAdminLookup
    let viewCompId: number | undefined
    let adminCompId: number | undefined
    if (projectViewLookup) {
      for (const [companyId, projectIds] of projectViewLookup) {
        if (projectIds.includes(projectId)) {
          viewCompId = companyId
        }
      }
    }
    if (projectAdminLookup) {
      for (const [companyId, projectIds] of projectAdminLookup) {
        if (projectIds.includes(projectId)) {
          adminCompId = companyId
        }
      }
    }
    if (viewCompId || adminCompId) {
      if (viewCompId && adminCompId) {
        // TODO: check if viewCompId !== adminCompId? how to handle it that happens (although they should always match if no errors api side)
        return { companyId: viewCompId, access: ['view', 'admin'] }
      } else if (viewCompId) {
        return { companyId: viewCompId, access: ['view'] }
      } else if (adminCompId) {
        return { companyId: adminCompId, access: ['admin'] }
      }
    }
    return undefined
  }

  // -------

  getUserProject = (projectId: number, _userProjects?: Array<UserProject>) : UserProject | undefined => {
    return (_userProjects || this.state.userProjects)?.find((project) => project.id === projectId)
  }

  getAdminProject = (projectId: number, _adminProjects?: Array<Project>) : Project | undefined => {
    return (_adminProjects || this.state.adminProjects)?.find((project) => project.id === projectId)
  }

  // returns all projects from the currently selected company that the user either has project level admin or manager access (NOT company level)
  getProjectManagerOrAdminProjects = () => {
    return _.filter(this.state.userProjects, (project) => _.includes([UserProjectRole.admin, UserProjectRole.manager], project.userProjectRole))
  }

  // returns all projects from the currently selected company that the user has either company admin access too, or project level admin or manager access
  getCompanyAdminOrProjectManagerOrAdminProjects = () => {
    // NB: we can have dupes across the two source arrays so we first get all relevant projects from the userProjects array (as those have some extra data as UserProject instead of Project models)
    // NB: ..& then loop through the adminProjects adding them if they're not already in the result array, then sort the result
    const projectManagerOrAdminProjects = this.getProjectManagerOrAdminProjects()
    // return [...(this.state.adminProjects ?? []), ...projectManagerOrAdminProjects]
    const companyAdminOrProjectManagerOrAdminProjects: Array<Project | UserProject> = [...projectManagerOrAdminProjects]
    if (this.state.adminProjects) {
      for (const adminProject of this.state.adminProjects) {
        if (!companyAdminOrProjectManagerOrAdminProjects.find((p) => p.id === adminProject.id)) {
          companyAdminOrProjectManagerOrAdminProjects.push(adminProject)
        }
      }
      // TODO: do we want to sort or leave admin only projects at the bottom of the list (the project dropdown currently does that, we should decide & get both to match)
      companyAdminOrProjectManagerOrAdminProjects.sort((a: Project, b: Project) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
    }
    return companyAdminOrProjectManagerOrAdminProjects
  }

  // -------

  getChannel = (channelId: number) : Channel | undefined => {
    return this.state.projectChannels?.find((channel) => channel.id === channelId)
  }

  // -------

  isVerified = () : boolean => {
    return this.state.user?.emailVerified ?? false
  }

  // -------

  // TODO: rename to be more specific to the type of admin?
  // TODO: this doesn't currently cover god admins, only company or project admins or managers, should it also include god?
  isAdmin = () => {
    const company = this.state.selectedCompany
    const project = this.state.selectedProject
    const isCompanyAdmin = company?.userCompanyRole === UserCompanyRole.admin
    const isProjectAdminOrManager = (project instanceof UserProject && (project.userProjectRole === UserProjectRole.manager || project.userProjectRole === UserProjectRole.admin))
    const value = isCompanyAdmin || isProjectAdminOrManager
    return value
  }

  // NB: site admin === god
  isSiteAdmin = () =>
    this.state.user?.isSiteAdmin() || false

  // Company Specific...

  // NB: was `isCompanyAdmin` - TODO: current company only - further rename to make it obvious?
  isCompanyAdminOrHigher = () =>
    this.isSiteAdmin() || this.state.selectedCompany?.userCompanyRole === UserCompanyRole.admin

  // NB: was `isProjectAdmin` - possibly further rename to `hasProjectAdminOrHigherAccess`?
  isProjectAdminOrHigher = () => {
    if (this.isCompanyAdminOrHigher()) return true
    const projects = this.state.userProjects
    if (!projects) return false
    return _.some(projects, project => _.includes([UserProjectRole.admin], project.userProjectRole))
  }

  // NB: was `isProjectManager` - possibly further rename to `hasProjectManagerOrHigherAccess`?
  isProjectManagerOrHigher = () => {
    if (this.isCompanyAdminOrHigher()) return true
    const projects = this.state.userProjects
    if (!projects) return false
    return _.some(projects, project => _.includes([UserProjectRole.admin, UserProjectRole.manager], project.userProjectRole))
  }

  // All/Any Company (the user has some form of access too)...

  isCompanyAdminOrHigherInAnyCompany = () =>
    this.isSiteAdmin() || _.some(this.state.userCompanies, company => company.userCompanyRole === UserCompanyRole.admin)

  isProjectManagerOrHigherInAnyCompany = () => {
    if (this.isCompanyAdminOrHigherInAnyCompany()) return true
    // loop through the project (manager) or admin lookup & check if the user has any projects they can manage or admin
    if (this.state.user?.projectAdminLookup) {
      for (const [, compManagerOrAdminProjectIds] of this.state.user.projectAdminLookup) {
        if (compManagerOrAdminProjectIds.length > 0) {
          return true
        }
      }
    }
    return false
  }

  // -------

  updateUserName = async (firstName: string, lastName: string) => {
    try {
      const result = await this._userAPI.updateUserName(firstName, lastName)
      if (result && this.state.user) {
        const user = this.state.user
        user.firstName = firstName
        user.lastName = lastName
        this.setState({ user }) // TODO: is this ok to do with other user updates coming from the auth api? should we also update that user ref?
      }
    } catch (error) {
      console.error('UserProvider - updateUserName - error: ', error)
      throw error // TODO: how to handle this, currently just throwing, should we instead propigate it somehow through the provider state?
    }
  }

  // -------

  // loads any locally cached user data from a previous page session/load
  // NB: the user cache only saves minimal data, mainly id's (we need to then load the relevant data from the api)
  loadUserCache = (userId: number) => {
    let userCache: UserCache | undefined
    if (userId) {
      const jsonData = localStorage.getItem('userCache_' + userId)
      if (jsonData) {
        userCache = UserCache.fromJSONString(userId, jsonData) ?? new UserCache(userId)
      } else {
        userCache = new UserCache(userId)
      }
    }
    return userCache
  }

  saveUserCache = (userCache: UserCache) => {
    if (this.state.user && userCache) {
      localStorage.setItem('userCache_' + this.state.user.id, userCache.getJSON())
    }
  }

  // TODO: when should we clear a users local cache? (we want it to persist across logins usually, but may still want to clear it at specific times?)
  clearUserCacheForUserId = (userId: number) => {
    if (userId) {
      localStorage.removeItem('userCache_' + userId)
    }
  }

  clearUserCacheForUser = (user: User) => {
    if (user) this.clearUserCacheForUserId(user.id)
  }

  // -------

  refreshChannels = async (): Promise<void> => {
    try {
    // console.log('UserProvider - refreshChannels')
      const companyId: number | undefined = this.state.selectedCompany?.id
      if (!companyId) throw new Error('no company id')
      const projectId: number | undefined = this.state.selectedProject?.id
      if (!projectId) throw new Error('no project id')
      const channels: Channel[] | undefined = await this.loadUserCompanyProjectChannels(companyId, projectId)
      if (channels === undefined) throw new Error('fetch channels failed')
      this.setState({
        projectChannels: channels,
        ...(this.state.selectedChannel && { selectedChannel: _.find(channels, { id: this.state.selectedChannel.id }) })
      })
    } catch (error) {
      // console.error('UserProvider - refreshChannels - error:', error)
    }
  }

  // -------

  actions: IUserActions = {
    // user data
    reloadUserData: this.reloadUserData,
    // user selections
    selectCompany: this.selectCompany,
    selectProject: this.selectProject,
    selectChannel: this.selectChannel,
    deselectCurrentCompany: this.deselectCurrentCompany,
    deselectCurrentProject: this.deselectCurrentProject,
    deselectCurrentChannel: this.deselectCurrentChannel,
    // user selection - data reload helpers
    updateSelectedProject: this.updateSelectedProject,
    // user selection - channel program helpers
    updateSelectedChannelPrograms: this.updateSelectedChannelPrograms,
    doAnySelectedChannelProgramsHaveSLDPHotlinkEnabled: this.doAnySelectedChannelProgramsHaveSLDPHotlinkEnabled,
    // users company project helpers
    getProjectManagerOrAdminProjects: this.getProjectManagerOrAdminProjects,
    getCompanyAdminOrProjectManagerOrAdminProjects: this.getCompanyAdminOrProjectManagerOrAdminProjects,
    // user status
    isVerified: this.isVerified,
    // user roles/access-levels
    isAdmin: this.isAdmin,
    isSiteAdmin: this.isSiteAdmin,
    isCompanyAdminOrHigher: this.isCompanyAdminOrHigher,
    isProjectAdminOrHigher: this.isProjectAdminOrHigher,
    isProjectManagerOrHigher: this.isProjectManagerOrHigher,
    isCompanyAdminOrHigherInAnyCompany: this.isCompanyAdminOrHigherInAnyCompany,
    isProjectManagerOrHigherInAnyCompany: this.isProjectManagerOrHigherInAnyCompany,
    // user updates
    updateUserName: this.updateUserName,
    // user cache
    saveUserCache: this.saveUserCache,
    // refresh
    refreshChannels: this.refreshChannels
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: IUserStore = {
  //  ...
  // }

  render () {
    return (
      <UserContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for IUserStore */ }}
      >
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

const withUserContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withUserContextHOC = (props: any) => (
    <UserContext.Consumer>
      {(userContext) => {
        if (userContext === null) {
          throw new Error('UserConsumer must be used within a UserProvider')
        }
        // console.log('withUserContext - render - UserContext.Consumer - userContext.store: ', userContext.store)
        return (<Component {...props} {...{ userContext: userContext }} />)
      }}
    </UserContext.Consumer>
  )
  return withUserContextHOC
}

const UserProviderWithContext = withNavContext(withAuthContext(UserProvider))

export { UserProviderWithContext as UserProvider }
export { withUserContext }
