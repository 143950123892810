import React from 'react'
import { Switch, Route } from 'react-router-dom'

import * as ROUTES from '../../../constants/routes'
import * as STREAMHUB_CONFIG from './config/config'

import { withAuthContext, IAuthMultiContext, ProjectAdminProvider, IAPIMultiContext, withAPIContext } from '../../../core/providers' // NB: only currently needed for authToken & UUID values

import { StreamhubServerProvider } from './providers/StreamhubServerProvider'

import StreamhubStreamsPage from './pages/streams/StreamhubStreamsPage'
import StreamhubSourcesPage from './pages/sources/StreamhubSourcesPage'
import StreamhubAssetsPage from './pages/assets/StreamhubAssetsPage'
import StreamhubProcessesPage from './pages/processes/StreamhubProcessesPage'

interface IProps extends IAuthMultiContext, IAPIMultiContext {}
interface IState {}

class StreamhubRouter extends React.Component<IProps, IState> {
  render () {
    // NB: the parent router handles permission checks
    const authToken = this.props.authContext.actions.getAuthToken()
    const authDeviceUUID = this.props.authContext.actions.getAuthDeviceUUID()
    return (
      <StreamhubServerProvider servers={STREAMHUB_CONFIG.STREAMHUB_SERVERS} authToken={authToken} authDeviceUUID={authDeviceUUID}>
        <ProjectAdminProvider apiClient={this.props.apiContext.actions.getAPIClient()}>
          <Switch>
            <Route path={ROUTES.ADMIN_STREAMHUB} exact component={StreamhubStreamsPage} />
            <Route path={ROUTES.ADMIN_STREAMHUB_STREAMS} component={StreamhubStreamsPage} />
            <Route path={ROUTES.ADMIN_STREAMHUB_SOURCES} component={StreamhubSourcesPage} />
            <Route path={ROUTES.ADMIN_STREAMHUB_PROCESSES} component={StreamhubProcessesPage} />
            <Route path={ROUTES.ADMIN_STREAMHUB_ASSETS} component={StreamhubAssetsPage} />
          </Switch>
        </ProjectAdminProvider>
      </StreamhubServerProvider>
    )
  }
}

export default withAPIContext(withAuthContext(StreamhubRouter))
