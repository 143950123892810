import React, { Component } from 'react'

import StreamhubAssetInfoView from './StreamhubAssetInfoView'
import { StreamhubAssetsContext, IStreamhubAssetsContext, StreamhubAssetAction, StreamhubAssetStatus, StreamhubAssetsStatus } from '../../providers/StreamhubAssetsProvider'

import ArkButton from 'src/core/components/ArkButton'
import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { Header, List, Modal, Message } from 'semantic-ui-react'

import styles from '../Streamhub.module.css'

interface IProps {
  onDataChanged?: Function
}
interface IState {
  selectedMediaFile?: { [key: string] : any }
  selectedMediaType?: 'video' | 'audio'
  showInfoModal: boolean
  error?: Error
  successTitle?: string
  successMsg?: string
}

class StreamhubAssetsView extends Component<IProps & IStreamhubAssetsContext, IState> {
  private _isMounted: boolean = false
  private _videoUploadRef = React.createRef<HTMLInputElement>()
  private _audioUploadRef = React.createRef<HTMLInputElement>()

  constructor (props: IProps & IStreamhubAssetsContext) {
    super(props)
    this.state = {
      showInfoModal: false
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.loadData()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    return this.renderView()
  }

  renderView () {
    return (
      <div className={styles.assets}>
        <div className={styles.contentHeader}>
          <div className={styles.contentHeaderMain}>
            <Header as='h2' inverted>Media Assets</Header>
            <div className={styles.actions}>
              <ArkButton color='blue' size='mini' className={styles.refreshBtn} onClick={async () => { this.onDataChanged() }}>REFRESH</ArkButton>
            </div>
          </div>
        </div>
        {this.renderAssets()}
      </div>
    )
  }

  // -------

  renderAssets () {
    const { successTitle, successMsg } = this.state
    const error = this.props.store.assetsError
    const loading = this.props.store.assetsStatus === StreamhubAssetsStatus.loading
    if (loading) return <ArkLoaderView message='Loading' />
    return (
      <>
        {successMsg && (
          <Message positive>
            <Message.Header>{successTitle}</Message.Header>
            <p>{successMsg}</p>
          </Message>
        )}
        {error && (
          <Message negative>
            <Message.Header>Error</Message.Header>
            <p>{error.message}</p>
          </Message>
        )}

        {this.renderVideoAssets()}
        {this.renderVideoFileUploadForm()}

        <ArkSpacer />

        {this.renderAudioAssets()}
        {this.renderAudioFileUploadForm()}

        {this.renderStreamSourceFormModal()}
      </>
    )
  }

  renderVideoAssets () {
    const error = this.props.store.assetsError
    if (error) return
    const videoListItems = []
    const sourceMediaFiles = this.props.store.assets
    if (sourceMediaFiles && sourceMediaFiles.video) {
      let index = 0
      for (const sourceMediaFile of sourceMediaFiles.video) {
        videoListItems.push(
          <List.Item key={'video_' + sourceMediaFile.filename} className={styles.assetItem}>
            <List.Content className={styles.assetItemContent}>
              <List.Header className={styles.assetTitle}>{this._filenameStr(sourceMediaFile.filename)}</List.Header>
              <List.Description className={styles.assetDesc}>{this._extensionStr(sourceMediaFile.extension)} {this._fileSizeStr(sourceMediaFile.size)}</List.Description>
              <div className={styles.assetActions}>
                <ArkButton size='mini' onClick={() => { this.showMediaFileInfoModal('video', sourceMediaFile) }}>INFO</ArkButton>
                <ArkManagerDeleteButton
                  itemId={index}
                  itemName={'Video File ' + this._filenameStr(sourceMediaFile.filename)}
                  itemTypeName='Video File'
                  buttonTitle='X'
                  onDelete={this.onDeleteVideoFile}
                  onDeleteComplete={this.onDeleteVideoFileComplete}
                  fluid={false}
                  size='mini'
                  style={{ display: 'inline', marginLeft: 10 }}
                  buttonStyle={{ fontSize: '12px', padding: '8px 10px' }}
                />
              </div>
            </List.Content>
          </List.Item>
        )
        index++
      }
    }
    return (
      <>
        <Header as='h3' inverted>Video Assets</Header>
        <List celled className={styles.assetList} verticalAlign='middle'>
          {videoListItems}
        </List>
        {videoListItems.length === 0 && (<div>No Items</div>)}
      </>
    )
  }

  renderAudioAssets () {
    const error = this.props.store.assetsError
    if (error) return
    const audioListItems = []
    const sourceMediaFiles = this.props.store.assets
    if (sourceMediaFiles && sourceMediaFiles.audio) {
      let index = 0
      for (const sourceMediaFile of sourceMediaFiles.audio) {
        audioListItems.push(
          <List.Item key={'audio_' + sourceMediaFile.filename} className={styles.assetItem}>
            <List.Content className={styles.assetItemContent}>
              <List.Header className={styles.assetTitle}>{this._filenameStr(sourceMediaFile.filename)}</List.Header>
              <List.Description className={styles.assetDesc}>{this._extensionStr(sourceMediaFile.extension)} {this._fileSizeStr(sourceMediaFile.size)}</List.Description>
              <div className={styles.assetActions}>
                <ArkButton size='mini' onClick={() => { this.showMediaFileInfoModal('audio', sourceMediaFile) }}>INFO</ArkButton>
                <ArkManagerDeleteButton
                  itemId={index}
                  itemName={'Audio File ' + this._filenameStr(sourceMediaFile.filename)}
                  itemTypeName='Audio File'
                  buttonTitle='X'
                  onDelete={this.onDeleteAudioFile}
                  onDeleteComplete={this.onDeleteAudioFileComplete}
                  fluid={false}
                  size='mini'
                  style={{ display: 'inline', marginLeft: 10 }}
                  buttonStyle={{ fontSize: '12px', padding: '8px 10px' }}
                />
              </div>
            </List.Content>
          </List.Item>
        )
        index++
      }
    }
    return (
      <>
        <Header as='h3' inverted>Audio Assets</Header>
        <List celled className={styles.assetList} verticalAlign='middle'>
          {audioListItems}
        </List>
        {audioListItems.length === 0 && (<div>No Items</div>)}
      </>
    )
  }

  // -------

  renderVideoFileUploadForm = () => {
    return (
      <div>
        <ArkButton onClick={() => { this._videoUploadRef.current?.click() }}>
          UPLOAD VIDEO
        </ArkButton>
        <input ref={this._videoUploadRef} type="file" accept=".mp4, .mov" hidden onChange={this.videoFileUploadOnChange} />
      </div>
    )
  }

  renderAudioFileUploadForm = () => {
    return (
      <div>
        <ArkButton onClick={() => { this._audioUploadRef.current?.click() }}>
          UPLOAD AUDIO
        </ArkButton>
        <input ref={this._audioUploadRef} type="file" accept=".mp3" hidden onChange={this.audioFileUploadOnChange} />
      </div>
    )
  }

  // -------

  videoFileUploadOnChange = async (event: any) => {
    if (event.target.files) {
      event.persist() // keep the original synthetic event around - ref: https://fb.me/react-event-pooling
      try {
        const file = event.target.files[0]
        const data = new FormData()
        data.append('file', file)
        await this.props.actions.uploadAsset('video', data)
        if (this.props.store.assetAction === StreamhubAssetAction.upload) {
          if (this.props.store.assetStatus === StreamhubAssetStatus.done) {
            const filename = file.name
            this.setState({ successTitle: 'Video File Uploaded', successMsg: 'Video file \'' + filename + '\' uploaded successfully.' })
            this.props.actions.fetchAssets() // reload the assets / media list
            if (this.props.onDataChanged) this.props.onDataChanged()
            return
          } else if (this.props.store.assetStatus === StreamhubAssetStatus.error && this.props.store.assetError) {
            throw this.props.store.assetError // throw the error so we catch & display it below
          }
        }
        throw Error('Failed to upload video file') // fallback error
      } catch (error) {
        console.error('ArkTestStreamAssetsView - videoFileUploadOnChange - error: ', error)
        this.setState({ error: error, successTitle: undefined, successMsg: undefined })
      }
    }
    // TODO: should be called on success as well, currently won't be
    event.target.value = null // clear the input fields value so the onChange callback will fire even if you go to re-upload the same file
  }

  audioFileUploadOnChange = async (event: any) => {
    if (event.target.files) {
      event.persist() // keep the original synthetic event around - ref: https://fb.me/react-event-pooling
      try {
        const file = event.target.files[0]
        const data = new FormData()
        data.append('file', file)
        await this.props.actions.uploadAsset('audio', data)
        if (this.props.store.assetAction === StreamhubAssetAction.upload) {
          if (this.props.store.assetStatus === StreamhubAssetStatus.done) {
            const filename = file.name
            this.setState({ successTitle: 'Autio File Uploaded', successMsg: 'Audio file \'' + filename + '\' uploaded successfully.' })
            this.props.actions.fetchAssets() // reload the assets / media list
            if (this.props.onDataChanged) this.props.onDataChanged()
            return
          } else if (this.props.store.assetStatus === StreamhubAssetStatus.error && this.props.store.assetError) {
            throw this.props.store.assetError // throw the error so we catch & display it below
          }
        }
        throw Error('Failed to upload audio file') // fallback error
      } catch (error) {
        console.error('ArkTestStreamAssetsView - videoFileUploadOnChange - error: ', error)
        this.setState({ error: error, successTitle: undefined, successMsg: undefined })
      }
    }
    // TODO: should be called on success as well, currently won't be
    event.target.value = null // clear the input fields value so the onChange callback will fire even if you go to re-upload the same file
  }

  // -------

  loadData = async () => {
    this.props.actions.fetchAssets()
  }

  onDataChanged = () => {
    this.loadData()
    if (this.props.onDataChanged) this.props.onDataChanged()
  }

  // -------

  onDeleteVideoFile = async (index: number) => {
    // const { sourceMediaFiles } = this.props
    const sourceMediaFiles = this.props.store.assets
    try {
      if (sourceMediaFiles && index < sourceMediaFiles.video.length) {
        const mediaFile = sourceMediaFiles.video[index]
        await this.props.actions.deleteAsset('video', mediaFile.filename)
        if (this.props.store.assetAction === StreamhubAssetAction.delete) {
          if (this.props.store.assetStatus === StreamhubAssetStatus.done) {
            // NB: commented out - leave the delete modal to show a success message
            // this.setState({ successTitle: 'Video File Deleted', successMsg: 'Video file \'' + mediaFile.filename + '\' was deleted successfully.' })
            return true
          } else if (this.props.store.assetStatus === StreamhubAssetStatus.error && this.props.store.assetError) {
            throw this.props.store.assetError // throw the error so we catch & display it below
          }
        }
      }
      throw Error('Failed to delete video file') // fallback error
    } catch (error) {
      console.error('ArkTestStreamAssetsView - onDeleteVideoFile - error: ', error)
      this.setState({ error: undefined, successTitle: undefined, successMsg: undefined }) // NB: not showing the error locally now, showing it within the delete modal response
      throw error // TESTING: rethrow the error so the ArkManagerDeleteButton handling that calls this will catch & show the error message
    }
  }

  onDeleteVideoFileComplete = () => {
    this.props.actions.fetchAssets() // reload the assets / media list
    if (this.props.onDataChanged) this.props.onDataChanged()
  }

  onDeleteAudioFile = async (index: number) => {
    // const { sourceMediaFiles } = this.props
    const sourceMediaFiles = this.props.store.assets
    try {
      if (sourceMediaFiles && index < sourceMediaFiles.audio.length) {
        const mediaFile = sourceMediaFiles.audio[index]
        await this.props.actions.deleteAsset('audio', mediaFile.filename)
        if (this.props.store.assetAction === StreamhubAssetAction.delete) {
          if (this.props.store.assetStatus === StreamhubAssetStatus.done) {
            // NB: commented out - leave the delete modal to show a success message
            // this.setState({ successTitle: 'Audio File Deleted', successMsg: 'Audio file \'' + mediaFile.filename + '\' was deleted successfully.' })
            return true
          } else if (this.props.store.assetStatus === StreamhubAssetStatus.error && this.props.store.assetError) {
            throw this.props.store.assetError // throw the error so we catch & display it below
          }
        }
      }
      throw Error('Failed to delete audio file') // fallback error
    } catch (error) {
      console.error('ArkTestStreamAssetsView - onDeleteAudioFile - error: ', error)
      this.setState({ error: undefined, successTitle: undefined, successMsg: undefined }) // NB: not showing the error locally now, showing it within the delete modal response
      throw error // TESTING: rethrow the error so the ArkManagerDeleteButton handling that calls this will catch & show the error message
    }
  }

  onDeleteAudioFileComplete = () => {
    this.props.actions.fetchAssets() // reload the assets / media list
    if (this.props.onDataChanged) this.props.onDataChanged()
  }

  // -------

  showMediaFileInfoModal = (mediaType: 'video' | 'audio', mediaFile: { [key: string] : any }) => {
    this.setState({ selectedMediaFile: mediaFile, selectedMediaType: mediaType, showInfoModal: true })
  }

  hideMediaFileInfoModal = () => {
    this.setState({ selectedMediaFile: undefined, selectedMediaType: undefined, showInfoModal: false })
  }

  renderStreamSourceFormModal = () => {
    const { selectedMediaFile, selectedMediaType } = this.state
    if (!selectedMediaFile || !selectedMediaFile.filename || !selectedMediaType) return null
    return (
      <Modal
        onClose={() => this.hideMediaFileInfoModal()}
        /* onOpen={() => this.setState({ showProjectFormModal: true })} */
        open={this.state.showInfoModal}
        trigger={null /* <ArkButton>Show Modal</ArkButton> */}
        closeOnEscape={true}
        closeOnDimmerClick={false}
        closeIcon={true}
        // size='large'
      >
        <Modal.Content>
          <StreamhubAssetInfoView
            mediaType={selectedMediaType}
            mediaFilename={selectedMediaFile.filename}
            mediaFile={selectedMediaFile}
          />
        </Modal.Content>
      </Modal>
    )
  }

  // -------

  _filenameStr = (filename: string) => {
    const extIndex = filename.lastIndexOf('.')
    if (extIndex >= 0) filename = filename.substring(0, extIndex)
    return filename.replaceAll('-', ' ').replaceAll('_', ' ')
  }

  _extensionStr = (ext: string) => {
    return ext.replace('.', '').toUpperCase()
  }

  _fileSizeStr = (size: number) => {
    const i = Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, i) * 1.0).toFixed(0) + '' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }

  // -------
}

export class StreamhubAssetsViewWithContext extends Component<IProps, {}> {
  render () {
    return (
      <StreamhubAssetsContext.Consumer>
        {(assetsContext) => { // { status }
          if (assetsContext === null) {
            throw new Error('StreamhubAssetsConsumer must be used within a StreamhubAssetsProvider')
          }
          // console.log('StreamhubAssetsViewWithContext - render - StreamhubAssetsContext.Consumer - assetsContext.store.assets: ', assetsContext.store.assets)
          return (
            <StreamhubAssetsView
              {...this.props}
              {...assetsContext}
            />
          )
        }}
      </StreamhubAssetsContext.Consumer>
    )
  }
}
export default StreamhubAssetsViewWithContext
export { StreamhubAssetsView }
