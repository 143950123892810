/**
 * ArkLoader
 */

import React from 'react'

import ArkSpacer from '../ArkSpacer'

import styles from './ArkLoader.module.css'

interface ArkLoaderProps {
  horizontal?: boolean
  message?: string | React.ReactNode
  small?: boolean
  inline?: boolean
  className?: string
}

const ArkLoader = ({ horizontal, message, small, inline, className }: ArkLoaderProps) => (
  <div className={`${styles.container} ${horizontal ? styles.containerHorizontal : ''} ${inline ? styles.containerInline : ''}${className ? ' ' + className : ''}`}>
    <div className={`${styles.dots} ${small ? styles.small : ''}`}>
      <div className={`${styles.dot} ${styles.one}`} />
      <div className={`${styles.dot} ${styles.two}`} />
      <div className={`${styles.dot} ${styles.three}`} />
    </div>
    {message && (
      <>
        <ArkSpacer large />
        <div className={styles.message}>{message}</div>
      </>
    )}
  </div>
)
export default ArkLoader
