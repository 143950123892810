import React, { Component } from 'react'

import { StreamhubAssetsContext, StreamhubAssetInfoStatus } from '../../providers/StreamhubAssetsProvider'

import ArkLoaderView from '../../../../../core/components/ArkLoaderView'

import { Header } from 'semantic-ui-react'

interface IProps {
  mediaType: 'video' | 'audio'
  mediaFilename: string
  mediaFile: { [key: string] : any }
}
interface IState {}

class StreamhubAssetInfoView extends Component<IProps, IState> {
  static contextType = StreamhubAssetsContext

  componentDidMount () {
    this.context.actions.fetchAssetInfo(this.props.mediaType, this.props.mediaFilename)
  }

  render () {
    const { mediaType, mediaFilename } = this.props
    const loading = this.context.store.assetInfoStatus === StreamhubAssetInfoStatus.loading
    const mediaFileInfo = this.context.store.assetInfo
    return (
      <>
        <Header inverted>{mediaType === 'video' ? 'Video Asset: ' : 'Audio Asset: '}{mediaFilename}</Header>

        {loading && <ArkLoaderView message='Loading' />}

        {!loading && (
          <>
            {mediaFileInfo === undefined && (<>N/A</>)}
            {mediaFileInfo !== undefined && (
              <>
                <Header as='h3' inverted>Streams</Header>
                <pre><code>{JSON.stringify(mediaFileInfo.streams, null, 2)}</code></pre>

                <Header as='h3' inverted>Format</Header>
                <pre>{JSON.stringify(mediaFileInfo.format, null, 2)}</pre>
              </>
            )}
          </>
        )}
      </>
    )
  }
}

export default StreamhubAssetInfoView
