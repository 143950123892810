import React, { Component } from 'react'
import * as yup from 'yup'
import _ from 'lodash'

import { FFMpegSource, FFMpegSourceAudioType, FFMpegSourceVideoType } from '../../models/StreamhubModels'

import ArkButton from '../../../../../core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps, ArkFormFieldPlaceholder, ArkFormFieldOption } from '../../../../../core/components/ArkForm/ArkForm'

import { Image, Message } from 'semantic-ui-react'

import styles from './StreamhubSourceForm.module.css'

export enum ArkTestStreamSourceFormMode {
  Add = 'add',
  Edit = 'edit',
}

const formSchema = yup.object().shape({
  // title: yup.number().required(),
  videoJson: yup.string().optional().test(
    'is-json',
    // eslint-disable-next-line no-template-curly-in-string
    '${path} is not valid JSON',
    (value, _context) => { if (value) { try { JSON.parse(value) } catch (e) { return false } return true } return true }),
  videoFilename: yup.string().optional(),
  audioJson: yup.string().optional().test(
    'is-json',
    // eslint-disable-next-line no-template-curly-in-string
    '${path} is not valid JSON',
    (value, _context) => { if (value) { try { JSON.parse(value) } catch (e) { return false } return true } return true }),
  audioFilename: yup.string().optional(),
  overlaysJson: yup.string().optional().test(
    'is-json',
    // eslint-disable-next-line no-template-curly-in-string
    '${path} is not valid JSON',
    (value, _context) => { if (value) { try { JSON.parse(value) } catch (e) { return false } return true } return true }),
  overlayTitle: yup.string().optional(),
  overlayShowTimecode: yup.boolean().optional()
})

export type ArkFormSaveCallback = (data: {[key: string]: any}) => Promise<boolean>
export type { ArkFormProps }

interface IProps {
  mode: ArkTestStreamSourceFormMode
  streamSource?: FFMpegSource
  streamSourceImageUrl?: string
  sourceMediaFiles?: { video: Array<any>, audio: Array<any> }
  onCancel?: Function
  onSave?: ArkFormSaveCallback
  onDelete?: Function
  onClose?: Function
  error?: Error
}
interface IState {
  isSubmitting: boolean
  isSaved: boolean
  error?: Error
}

class StreamhubSourceForm extends Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      isSubmitting: false,
      isSaved: false
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { streamSource, sourceMediaFiles } = this.props
    const { error, isSubmitting } = this.state

    let videoInputs = streamSource?.videoInputs
    const videoInput = (videoInputs && videoInputs.length > 0 ? videoInputs[0] : undefined)
    const videoFilename = videoInput?.type === FFMpegSourceVideoType.file && videoInput?.filename ? videoInput?.filename : undefined

    let audioInputs = streamSource?.audioInputs
    const audioInput = (audioInputs && audioInputs.length > 0 ? audioInputs[0] : undefined)
    const audioFilename = audioInput?.type === FFMpegSourceAudioType.file && audioInput?.filename ? audioInput?.filename : undefined

    // if the filename fields were used, remove them from the raw json shown on the form
    if (videoFilename && videoInputs && videoInputs.length > 0) {
      const videoInputsCopy = _.cloneDeep(videoInputs) // Array.from(videoInputs) // [...videoInputs]
      delete (videoInputsCopy[0] as any).type
      delete (videoInputsCopy[0] as any).filename
      videoInputs = videoInputsCopy
    }
    // if no other fields in the input, remove the whole input entry
    if (videoInputs && videoInputs.length > 0) {
      if (Object.keys(videoInputs[0]).length === 0) {
        const videoInputsCopy = _.cloneDeep(videoInputs)
        videoInputsCopy.splice(0, 1)
        videoInputs = videoInputsCopy
      }
    }
    if (audioFilename && audioInputs && audioInputs.length > 0) {
      const audioInputsCopy = _.cloneDeep(audioInputs)
      delete (audioInputsCopy[0] as any).type
      delete (audioInputsCopy[0] as any).filename
      audioInputs = audioInputsCopy
    }
    // if no other fields in the input, remove the whole input entry
    if (audioInputs && audioInputs.length > 0) {
      if (Object.keys(audioInputs[0]).length === 0) {
        const audioInputsCopy = _.cloneDeep(audioInputs)
        audioInputsCopy.splice(0, 1)
        audioInputs = audioInputsCopy
      }
    }
    const videoInputsJson = (videoInputs && videoInputs.length > 0 ? JSON.stringify(videoInputs) : '')
    const audioInputsJson = (audioInputs && audioInputs.length > 0 ? JSON.stringify(audioInputs) : '')
    const overlaysJson = (streamSource?.overlays && streamSource?.overlays.length > 0 ? JSON.stringify(streamSource?.overlays) : '')

    const formFields: Array<ArkFormField> = []

    const videoFileOptions: Array<ArkFormFieldOption> = []
    videoFileOptions.push({ key: 'video_none', text: 'Select a video file', value: undefined })
    if (sourceMediaFiles && sourceMediaFiles.video) {
      for (const sourceMediaFile of sourceMediaFiles.video) {
        const filename = sourceMediaFile.filename
        videoFileOptions.push({ key: filename, text: filename, value: filename })
      }
    }

    const audioFileOptions: Array<ArkFormFieldOption> = []
    audioFileOptions.push({ key: 'audio_none', text: 'Select an audio file', value: undefined })
    if (sourceMediaFiles && sourceMediaFiles.audio) {
      for (const sourceMediaFile of sourceMediaFiles.audio) {
        const filename = sourceMediaFile.filename
        audioFileOptions.push({ key: filename, text: filename, value: filename })
      }
    }

    formFields.push({ type: ArkFormFieldType.FormErrorPlaceholder, key: 'formError' })

    formFields.push({
      type: ArkFormFieldType.Fieldset,
      key: 'inputFieldset',
      label: 'Input',
      fields: [
        {
          type: ArkFormFieldType.Dropdown,
          key: 'videoFilename',
          label: 'Video File',
          required: false,
          defaultValue: videoFilename,
          options: videoFileOptions,
          fieldProps: { scrolling: true } // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
        },
        {
          type: ArkFormFieldType.Dropdown,
          key: 'audioFilename',
          label: 'Audio File',
          required: false,
          defaultValue: audioFilename,
          options: audioFileOptions,
          fieldProps: { scrolling: true } // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
        }
      ],
      collapsible: false,
      collapsed: false
    })

    formFields.push({
      type: ArkFormFieldType.Fieldset,
      key: 'overlaysFieldset',
      label: 'Overlays',
      fields: [
        {
          type: ArkFormFieldType.Group,
          key: 'overlayOptions',
          fields: [
            { type: ArkFormFieldType.Input, key: 'overlayTitle', label: 'Overlay Title/Text', required: false, defaultValue: streamSource?.overlayTitle ?? '', fieldProps: {} },
            { type: ArkFormFieldType.Checkbox, key: 'overlayShowTimecode', label: 'Show Timecode', required: false, defaultValue: streamSource?.overlayShowTimecode ?? false, fieldProps: { style: { marginTop: 32, marginLeft: 10 } } }
          ],
          fieldProps: { widths: 'equal' }
        }
      ],
      collapsible: false,
      collapsed: false
    })

    formFields.push({
      type: ArkFormFieldType.Fieldset,
      key: 'advancedFieldset',
      label: 'Advanced Options',
      fields: [
        { type: ArkFormFieldType.TextArea, key: 'videoJson', label: 'Video Args (JSON)', required: false, defaultValue: videoInputsJson, fieldProps: { rows: 1 } },
        { type: ArkFormFieldType.TextArea, key: 'audioJson', label: 'Audio Args (JSON)', required: false, defaultValue: audioInputsJson, fieldProps: { rows: 1 } },
        { type: ArkFormFieldType.TextArea, key: 'overlaysJson', label: 'Overlays (JSON)', required: false, defaultValue: overlaysJson, fieldProps: { rows: 4 } }
      ],
      collapsible: true,
      collapsed: !!(videoInputsJson === '' && audioInputsJson === '' && overlaysJson === '') // open by default if any of the fields are set
    })

    formFields.push({
      type: ArkFormFieldType.Group,
      key: 'buttons',
      fields: [
        { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { loading: isSubmitting, onClick: this.onCancel, floated: 'left' } },
        { type: ArkFormFieldType.OKButton, key: 'submit', label: (this.props.mode === ArkTestStreamSourceFormMode.Edit ? 'SAVE' : 'CREATE'), fieldProps: { loading: isSubmitting, floated: 'right' } }
      ],
      fieldProps: { widths: 'equal' }
    })

    return (
      <ArkForm
        formKey="streamSource"
        className={styles.form}
        inverted
        header={(this.props.mode === ArkTestStreamSourceFormMode.Edit ? 'Edit' : 'Add') + ' Stream Source' + (this.props.streamSource ? ' ' + this.props.streamSource.id : '')}
        formError={error ?? this.props.error}
        formFields={formFields}
        formSchema={formSchema}
        onFormSubmit={this.onFormSubmit}
        insideModal={true}
      >
        <div className={styles.wrapper}>
          <div className={styles.image}>
            {this.props.streamSource && this.props.streamSourceImageUrl && (
              <Image src={this.props.streamSourceImageUrl} key={this.props.streamSource.updatedAt?.toString() ?? this.props.streamSource.createdAt?.toString() ?? this.props.streamSource.id ?? '' /* TODO: only change the key when we want to re-render an image after its changed! */} /* ref: https://stackoverflow.com/a/61023057 */ />
            )}
          </div>
          <div className={styles.formFields}>
            {this.state.isSaved && (<>
              <Message positive>
                <Message.Header>Stream Source {this.props.mode === ArkTestStreamSourceFormMode.Edit ? 'Updated' : 'Created'}</Message.Header>
                <Message.Item>The stream source has been {this.props.mode === ArkTestStreamSourceFormMode.Edit ? 'updated' : 'created'} successfully</Message.Item>
              </Message>
              <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={this.onCancel} style={{ marginBottom: '20px' }}>
                OK
              </ArkButton>
            </>)}
            {/* streamSource && (<div style={{ paddingBottom: 20 }}>Source ID: {streamSource.id}</div>) */}
            <ArkFormFieldPlaceholder fieldKey="formError" />
            <ArkFormFieldPlaceholder fieldKey="inputFieldset" />
            <ArkFormFieldPlaceholder fieldKey="overlaysFieldset" />
            <ArkFormFieldPlaceholder fieldKey="advancedFieldset" />
            <ArkFormFieldPlaceholder fieldKey="buttons" />
          </div>
        </div>
      </ArkForm>
    )
  }

  onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    const { videoFilename, videoJson, audioFilename, audioJson, overlayTitle, overlayShowTimecode, overlaysJson } = fieldValues
    if (!videoFilename && (!videoJson || videoJson === '' || videoJson === '[]')) {
      // TODO: ideally we'd set errors for both fields directly, so they highlight (would likely need to add an optional validation callback to ArkForm to support this?)
      this.setState({
        error: Error('A video input is required, select a video file or enter video args to create one')
      })
      return
    }
    this.setState({ error: undefined })
    if (this.props.onSave) {
      this.setState({ error: undefined, isSaved: false, isSubmitting: true })
      // NB: this MUST use await (despite the compiler warning it has no use)
      // TODO: add async to the props.onSave ArkFormSaveCallback declaration (not quite sure how yet)
      const saved = await this.props.onSave({ videoFilename, videoJson, audioFilename, audioJson, overlayTitle, overlayShowTimecode, overlaysJson })
      if (this._isMounted) this.setState({ isSaved: (saved === true), isSubmitting: false })
    }
  }

  onCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, _data: object) => {
    event.preventDefault()
    if (this.props.onClose) this.props.onClose()
  }
}

export default StreamhubSourceForm
