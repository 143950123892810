import { jsonObject, jsonMember, jsonArrayMember, jsonMapMember, TypedJSON, toJson, AnyT } from 'typedjson' // jsonMapMember
import { ChildProcess } from 'child_process'
import type { FFMpegOutputOptions } from './FFMpegOptions'

export enum FFMpegSourceVideoType {
  file = 'file', // NB: represents an actual file based input (not a virtual type)
  color = 'color', // add 'c=red' to video args - ref: https://ffmpeg.org/ffmpeg-utils.html#color-syntax
  pal75bars = 'pal75bars',
  pal100bars = 'pal100bars',
  rgbtestsrc = 'rgbtestsrc',
  smptebars = 'smptebars',
  smptehdbars = 'smptehdbars',
  testsrc = 'testsrc',
  testsrc2 = 'testsrc2',
  yuvtestsrc = 'yuvtestsrc',
}

export enum FFMpegStreamStatus {
  initial, stopped, starting, running, restarting, stopping, error
}

export interface IFFMpegStreamProgramData {
  server: string // api server (which then indicates the streaming server)
  companyId: number
  projectId: number
  programId: number
  companyName?: string
  projectName?: string
  programName?: string
  updatedAt?: Date // the last time the stream program data was updated - to help indicate if the cached names might be very old & more likely out of sync vs more recent updates
}

@jsonObject @toJson
export class FFMpegVideoInput {
  @jsonMember(String) // NB: can typedjson handle string enums directly? TESTING: as a String for now
  type: FFMpegSourceVideoType

  @jsonMember(Number)
  duration?: number

  @jsonMember(Number)
  fps?: number

  @jsonMember(String)
  resolution?: string

  @jsonMember(String)
  inputArgsStr?: string

  @jsonMember(String)
  filename?: string

  constructor (
    type: FFMpegSourceVideoType,
    duration?: number,
    fps?: number, // = 30, // TODO: when type === file fps should be optional? (& needs extra code to support fps changes to the output?)
    resolution?: string, // = '1280x720',  // TODO: when type === file resolution should be optional? (& needs extra code to support resizing the output?)
    inputArgsStr?: string, // = '',
    filename?: string
  ) {
    this.type = type
    this.duration = duration
    this.fps = fps
    this.resolution = resolution
    this.inputArgsStr = inputArgsStr
    this.filename = filename
  }
}

export enum FFMpegSourceAudioType {
  file = 'file', // NB: represents an actual file based input (not a virtual type)
  sine = 'sine',
}

@jsonObject @toJson
export class FFMpegAudioInput {
  @jsonMember(String) // NB: can typedjson handle string enums directly? TESTING: as a String for now
  type: FFMpegSourceAudioType

  @jsonMember(Number)
  duration?: number

  @jsonMember(String)
  inputArgsStr?: string

  @jsonMember(String)
  filename?: string

  constructor (
    type: FFMpegSourceAudioType,
    duration?: number,
    inputArgsStr?: string, // = '',
    filename?: string
  ) {
    this.type = type
    this.duration = duration
    this.inputArgsStr = inputArgsStr
    this.filename = filename
  }
}

@jsonObject @toJson
export class FFMpegText {
  @jsonMember(String)
  cmd: string

  @jsonMember(Boolean)
  isTimecode: boolean

  constructor (cmd: string, isTimecode: boolean = false) {
    this.cmd = cmd
    this.isTimecode = isTimecode
  }

  static timecode () {
    return new FFMpegText('drawtext=fontfile=/home/node/app/fonts/roboto/Roboto-Black.ttf:fontsize=15:timecode=\'00\\:00\\:00\\:00\':rate=25:text=\'TCR\\:\\    \':fontsize=72:fontcolor=\'white\':boxcolor=0x000000AA@0.5:boxborderw=20:box=1:x=(w-text_w)/2:y=(h-text_h)-(h/4)', true)
  }

  static text (txt: string) {
    return new FFMpegText("drawtext=fontfile=/home/node/app/fonts/roboto/Roboto-Black.ttf:text='" + txt + "':fontsize=72:fontcolor=white:boxcolor=0x000000AA@0.5:boxborderw=20:box=1:x=(w-text_w)/2:y=(h/4)")
  }
}

@jsonObject @toJson
export class FFMpegSource {
  @jsonMember(Number)
  id: number

  @jsonArrayMember(FFMpegVideoInput)
  videoInputs: Array<FFMpegVideoInput>

  @jsonArrayMember(FFMpegAudioInput)
  audioInputs: Array<FFMpegAudioInput>

  @jsonArrayMember(FFMpegText)
  overlays: Array<FFMpegText>

  @jsonMember(String)
  overlayTitle?: string

  @jsonMember(Boolean)
  overlayShowTimecode: boolean

  @jsonMember(AnyT) // TESTING: with AnyT
  outputOptions?: FFMpegOutputOptions

  @jsonMember(AnyT) // TESTING: with AnyT
  public avInfo?: FFMpegMainAVInfo

  @jsonMember(Date)
  public createdAt: Date

  @jsonMember(Date)
  public updatedAt?: Date

  constructor (
    id: number,
    videoInputs: Array<FFMpegVideoInput>,
    audioInputs: Array<FFMpegAudioInput>,
    overlays: Array<FFMpegText>,
    overlayTitle?: string,
    overlayShowTimecode: boolean = false,
    outputOptions?: FFMpegOutputOptions,
    avInfo?: FFMpegMainAVInfo,
    createdAt: Date = new Date(),
    updatedAt?: Date
  ) {
    this.id = id
    this.videoInputs = videoInputs
    this.audioInputs = audioInputs
    this.overlays = overlays
    this.overlayTitle = overlayTitle
    this.overlayShowTimecode = overlayShowTimecode
    this.outputOptions = outputOptions
    this.avInfo = avInfo
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }

  getJSON (): string {
    // return JSON.stringify(this)
    const serializer = new TypedJSON(FFMpegSource)
    return serializer.stringify(this)
  }

  static fromJSON (json: any): FFMpegSource {
    return new FFMpegSource(
      json.id,
      json.videoInputs,
      json.audioInputs,
      json.overlays,
      json.overlayTitle,
      json.overlayShowTimecode,
      json.outputOptions,
      json.avInfo,
      json.createdAt,
      json.updatedAt
    )
  }

  getMainAVInfo = () => {
    const mainAVInfo: FFMpegMainAVInfo = {
      videoInfo: this.getMainVideoInputInfo(),
      audioInfo: this.getMainAudioInputInfo()
    }
    return mainAVInfo
  }

  // NB: if the avInfo is set use that (as it means an input file was specified), if not look for a virtual input & respond with details from that
  getMainVideoInputInfo = () => {
    console.log('FFMpegSource - getMainVideoInputInfo - this.avInfo: ', this.avInfo)
    if (this.avInfo?.videoInfo) return this.avInfo?.videoInfo
    if (this.videoInputs.length === 0) return undefined
    const videoInput = this.videoInputs[0] // NB: only using the first entry
    let width: number | undefined
    let height: number | undefined
    const res = videoInput.resolution
    if (res && res.includes('x')) {
      const resSplit = res.split('x')
      if (resSplit.length === 2) {
        width = parseInt(resSplit[0])
        height = parseInt(resSplit[1])
      }
    }
    const mainVideoInfo: FFMpegMainVideoInfo = {
      filename: videoInput.filename,
      duration: videoInput.duration,
      // size: format.size ? parseInt(format.size) : undefined,
      // bitrate: format.bit_rate ? parseInt(format.bit_rate) : undefined,
      // codecName: stream.codec_name,
      // codecTag: stream.codec_tag,
      // codecTagAscii: stream.codec_tag_string,
      // profile: stream.profile,
      width: width,
      height: height,
      // aspectRatio: stream.display_aspect_ratio,
      // pixelFormat: stream.pix_fmt,
      // frameRate: (videoInput.fps ? videoInput.fps + '/1' : undefined), // mimic the ffprobe string format e.g. '30/1'
      fps: videoInput.fps,
      title: 'Virtual Video Input = \'' + videoInput.type + '\'' + (videoInput.inputArgsStr ? ' args: ' + videoInput.inputArgsStr : '')
    }
    return mainVideoInfo
  }

  getMainAudioInputInfo = () => {
    if (this.avInfo?.audioInfo) return this.avInfo?.audioInfo
    if (this.audioInputs.length === 0) return undefined
    const audioInput = this.audioInputs[0] // NB: only using the first entry
    // console.log('FFMpegSource - getMainAudioInputInfo - id: ', this.id, ' audioInput: ', audioInput)
    const filename = audioInput.filename ?? audioInput.type === FFMpegSourceAudioType.file ? audioInput.inputArgsStr?.replace('file=', '') : undefined
    const mainAudioInfo: FFMpegMainAudioInfo = {
      filename: filename,
      duration: audioInput.duration,
      // size: format.size ? parseInt(format.size) : undefined,
      // bitrate: format.bit_rate ? parseInt(format.bit_rate) : undefined,
      // codecName: stream.codec_name,
      // channels: stream.channels,
      // sampleRate: stream.sample_rate ? parseInt(stream.sample_rate) : undefined,
      // sampleFormat: stream.sample_fmt,
      // timeBase: stream.codec_time_base,
      title: 'Virtual Audio Input = \'' + audioInput.type + '\'' + (audioInput.inputArgsStr ? ' args: ' + audioInput.inputArgsStr : '')
    }
    return mainAudioInfo
  }
}

@jsonObject @toJson
export class FFMpegStream {
  @jsonMember(Number)
  id: number

  @jsonMember(Number)
  sourceId: number

  @jsonMember(String)
  name?: string

  @jsonMember(String)
  url?: string

  @jsonMember(Number)
  duration?: number // seconds

  @jsonMember(Boolean)
  isTemp: boolean

  @jsonMember(Boolean)
  isEnabled: boolean

  @jsonMember(Boolean)
  isActive: boolean

  @jsonMember(Number)
  status?: FFMpegStreamStatus

  @jsonMember(Number)
  pid?: number

  @jsonMember(Boolean)
  retryEnabled: boolean

  @jsonMember(Number)
  retryDelay: number

  @jsonMember(Number)
  retryMaxAttempts: number

  @jsonMember(Number)
  retryCount: number

  @jsonMember(Date)
  public createdAt: Date

  @jsonMember(Date)
  public updatedAt?: Date

  @jsonMember(Date)
  public startedAt?: Date

  @jsonMember(Date)
  public retryAt?: Date

  @jsonMember(Error)
  public error?: Error // NB: not sending over the socket.io emit usage? adding a string version fallback for that for now

  @jsonMember(String)
  errorMsg?: string // fallback for socket.io usage while the Error object doesn't seem to send

  programData?: IFFMpegStreamProgramData // NB: NOT added to the json handling as it''l be depreciated soon

  // TODO: describe thesse with typedjson annotations? (or leave?)
  child?: ChildProcess
  data?: any

  constructor (
    id: number,
    sourceId: number,
    name?: string,
    url?: string,
    duration?: number,
    isTemp: boolean = false,
    isEnabled: boolean = false,
    isActive: boolean = false,
    status?: FFMpegStreamStatus,
    pid?: number,
    retryEnabled: boolean = false,
    retryDelay: number = 30,
    retryMaxAttempts: number = 120,
    retryCount: number = 0,
    createdAt?: Date,
    updatedAt?: Date,
    startedAt?: Date,
    retryAt?: Date,
    error?: Error,
    errorMsg?: string,
    child?: ChildProcess,
    data?: any
  ) {
    this.id = id
    this.sourceId = sourceId
    this.name = name
    this.url = url
    this.duration = duration
    this.isTemp = isTemp
    this.isEnabled = isEnabled
    this.isActive = isActive
    this.status = status
    this.pid = pid
    this.retryEnabled = retryEnabled
    this.retryDelay = retryDelay
    this.retryMaxAttempts = retryMaxAttempts
    this.retryCount = retryCount
    this.createdAt = createdAt ?? new Date()
    this.updatedAt = updatedAt
    this.startedAt = startedAt
    this.retryAt = retryAt
    this.error = error
    this.errorMsg = errorMsg

    this.child = child
    this.data = data
  }

  getJSON (): string {
    const serializer = new TypedJSON(FFMpegStream)
    return serializer.stringify(this)
  }

  static fromJSON (json: any): FFMpegStream {
    const stream = new FFMpegStream(
      json.id,
      json.sourceId,
      json.name,
      json.url,
      json.duration,
      json.isTemp,
      json.isEnabled,
      json.isActive,
      json.status,
      json.pid,
      json.retryEnabled,
      json.retryDelay,
      json.retryMaxAttempts,
      json.retryCount,
      json.createdAt,
      json.updatedAt,
      json.startedAt,
      json.retryAt,
      json.error,
      json.errorMsg,
      json.child,
      json.data
    )
    stream.programData = json.programData
    return stream
  }
}

// NB: this is still currently partially used for the `db` backed data source (just not saved fully)
export type FFMpegMainVideoInfo = {
  filename?: string
  formatName?: string
  duration?: number
  startTime?: number
  size?: number
  bitrate?: number
  codecName?: string
  codecTag?: string
  codecTagAscii?: string
  profile?: string
  width?: number
  height?: number
  aspectRatio?: string
  pixelFormat?: string
  // frameRate?: string // TODO: ffprobe returns it as a string in format '30/1' convert to an int, like our virtual inputs fps field? (or maybe keep this as a string & add an int `fps` with it?)
  fps?: number,
  title?: string
}
export type FFMpegMainAudioInfo = {
  filename?: string
  duration?: number
  size?: number
  bitrate?: number
  codecName?: string
  channels?: number
  sampleRate?: number
  sampleFormat?: string
  timeBase?: string
  title?: string
}
export type FFMpegMainAVInfo = {
  videoInfo?: FFMpegMainVideoInfo
  audioInfo?: FFMpegMainAudioInfo
}

@jsonObject @toJson
export class FFMpegSourceInfo {
  @jsonMember(Number)
  id: number

  @jsonMapMember(String, AnyT)
  videoInputsInfo?: Map<string, any>

  @jsonMapMember(String, AnyT)
  audioInputsInfo?: Map<string, any>

  constructor (id: number, videoInputsInfo?: Map<string, any>, audioInputsInfo?: Map<string, any>, _createdAt: Date = new Date(), _updatedAt?: Date) {
    this.id = id
    this.videoInputsInfo = videoInputsInfo
    this.audioInputsInfo = audioInputsInfo
  }

  getJSON (): string {
    const serializer = new TypedJSON(FFMpegSourceInfo)
    return serializer.stringify(this)
  }

  static fromJSON (json: any): FFMpegSourceInfo {
    return new FFMpegSourceInfo(json.id, json.videosMediaInfo, json.audiosMediaInfo, json.createdAt, json.updatedAt)
  }

  // helpers to get some of the specific proprties we need
  // NB: assumes a single video/audio input only, chooses a random one currently if multiple (as we can't tell the order in a map)
  getMainAVInfo = () => {
    const mainAVInfo: FFMpegMainAVInfo = {
      videoInfo: this.getMainVideoInputInfo(),
      audioInfo: this.getMainAudioInputInfo() // WARNING: this will only be set if a separate audio file is specified currently, NOT if the audio from the video source is included (& the video info won't include the audio info with the current parsing used)
    }
    return mainAVInfo
  }

  getMainVideoInputInfo = () => {
    if (this.videoInputsInfo && this.videoInputsInfo.size > 0) {
      // console.log('FFMpegSourceInfo - getMainVideoInputInfo - videoInputsInfo: ', this.videoInputsInfo)
      const keys = Array.from(this.videoInputsInfo.keys())
      const key = keys.length > 0 ? keys[0] : undefined
      // console.log('FFMpegSourceInfo - getMainVideoInputInfo - keys: ', keys, ' key: ', key)
      // if (key) console.log('FFMpegSourceInfo - getMainVideoInputInfo - get: ', this.videoInputsInfo.get(key), ' size: ', this.videoInputsInfo.get(key).size, ' length: ', this.videoInputsInfo.get(key).length, ' typeof: ', (typeof this.videoInputsInfo.get(key)), ' Object.keys().length: ', Object.keys(this.videoInputsInfo.get(key)).length)
      // if (key && this.videoInputsInfo.get(key) && this.videoInputsInfo.get(key).size > 0) {
      if (key && this.videoInputsInfo.get(key) && Object.keys(this.videoInputsInfo.get(key)).length > 0) {
        const videoInputInfo = this.videoInputsInfo.get(key)
        console.log('FFMpegSourceInfo - getMainVideoInputInfo - videoInputInfo: ', videoInputInfo)
        const stream = videoInputInfo.streams && videoInputInfo.streams.length > 0 ? videoInputInfo.streams[0] : undefined
        const format = videoInputInfo.format
        let fps: number | undefined
        if (stream && stream.avg_frame_rate && stream.avg_frame_rate.includes('/')) {
          const frSplit = stream.avg_frame_rate.split('/')
          if (frSplit.length === 2) {
            fps = parseInt(frSplit[0]) / parseInt(frSplit[1])
          }
        }
        // console.log('FFMpegSourceInfo - getMainVideoInputInfo - videoInputInfo: ', videoInputInfo)
        const mainVideoInfo: FFMpegMainVideoInfo = {
          filename: format.filename,
          formatName: format.format_name,
          duration: format.duration ? parseFloat(format.duration) : undefined,
          startTime: format.start_time ? parseFloat(format.start_time) : undefined,
          size: format.size ? parseInt(format.size) : undefined,
          bitrate: format.bit_rate ? parseInt(format.bit_rate) : undefined,
          codecName: stream.codec_name,
          codecTag: stream.codec_tag,
          codecTagAscii: stream.codec_tag_string,
          profile: stream.profile,
          width: stream.width,
          height: stream.height,
          aspectRatio: stream.display_aspect_ratio,
          pixelFormat: stream.pix_fmt,
          // frameRate: stream.avg_frame_rate,
          fps: fps,
          title: format.tags?.title ?? undefined
        }
        return mainVideoInfo
      }
    }
    return undefined
  }

  // WARNING: this will only be set if a separate audio file is specified currently, NOT if the audio from the video source is included (& the video info won't include the audio info with the current parsing used)
  getMainAudioInputInfo = () => {
    if (this.audioInputsInfo && this.audioInputsInfo.size > 0) {
      const keys = Array.from(this.audioInputsInfo.keys())
      const key = keys.length > 0 ? keys[0] : undefined
      // if (key && this.audioInputsInfo.get(key) && this.audioInputsInfo.get(key).size > 0) {
      if (key && this.audioInputsInfo.get(key) && Object.keys(this.audioInputsInfo.get(key)).length > 0) {
        const audioInputInfo = this.audioInputsInfo.get(key)
        // console.log('FFMpegSourceInfo - getMainAudioInputInfo - audioInputInfo: ', audioInputInfo)
        const stream = audioInputInfo.streams && audioInputInfo.streams.length > 0 ? audioInputInfo.streams[0] : undefined
        const format = audioInputInfo.format
        const mainAudioInfo: FFMpegMainAudioInfo = {
          filename: format.filename,
          duration: format.duration ? parseFloat(format.duration) : undefined,
          size: format.size ? parseInt(format.size) : undefined,
          bitrate: format.bit_rate ? parseInt(format.bit_rate) : undefined,
          codecName: stream.codec_name,
          channels: stream.channels,
          sampleRate: stream.sample_rate ? parseInt(stream.sample_rate) : undefined,
          sampleFormat: stream.sample_fmt,
          timeBase: stream.codec_time_base,
          title: format.tags.title
        }
        return mainAudioInfo
      }
    }
    return undefined
  }
}

// NB: this is still currently (& only) used for looking up ffmpeg processes (that may not have an associated FFMpegStream if started externally or before a restart & the internal data got out of sync somehow)
// NB: now using this to represent raw ffmpeg process instances separate from any known instances, should be able to tie internally tracked FFMpegStream/OutputStreamDB references via the pid (process id)
// NB: originally was named `FFMpegInstance`
export class FFMpegProcess {
  pid: number
  command: string
  cpu: number
  child?: ChildProcess
  data?: any
  constructor (pid: number, command: string, cpu: number, child?: ChildProcess, data?: any) {
    this.pid = pid
    this.command = command
    this.cpu = cpu
    this.child = child
    this.data = data
  }

  // TESTING: just returns the fields we want to display/output for normal use cases (not all the extra data etc.)
  getOuputData (hidePassword: boolean = false) {
    let _command = this.command
    const outputUrl = this.parseOutputUrl(hidePassword)
    if (hidePassword) {
      const outputUrlWithPass = this.parseOutputUrl(false)
      if (outputUrl && outputUrlWithPass) {
        _command = _command.replace(outputUrlWithPass, outputUrl)
      }
    }
    return {
      pid: this.pid,
      cpu: this.cpu,
      command: _command,
      commandData: {
        outputUrl: outputUrl,
        outputSrtServer: this.parseOutputSRTServer(),
        outputSRTPort: this.parseOutputSRTPort(),
        streamId: this.parseStreamId(),
        programId: this.parseProgramId()
      }
    }
  }

  getJSON () : string {
    return JSON.stringify(this.getOuputData())
  }

  parseOutputUrl (hidePassword: boolean = false) : string | undefined {
    // assumes the last entry in the ffmpeg arg is the output (it should be in all current use cases from this script & normal ffmpeg usage that I've seen so far)
    const cmdSplit = this.command.split(' ')
    let url = cmdSplit.length > 0 ? cmdSplit[cmdSplit.length - 1] : undefined
    // TESTING: optionally hide/mask the passphrase arg with a fixed number of asterisk's
    const passPhraseKey = 'passphrase='
    // url = url + '&someArg=123&otherArg=abc' // DEBUG ONLY
    if (url && url.includes(passPhraseKey) && hidePassword) {
      // console.log('FFMpegProcess - parseOutputUrl - url:', url)
      let indexStart = url.indexOf(passPhraseKey)
      if (indexStart) {
        indexStart = indexStart + passPhraseKey.length
        let indexEnd = url.indexOf('&', indexStart)
        // console.log('FFMpegProcess - parseOutputUrl - indexStart(AFTER):', indexStart, ' indexEnd:', indexEnd)
        indexEnd = indexEnd < 0 ? url.length : indexEnd
        // const passLength = indexEnd - indexStart
        // console.log('FFMpegProcess - parseOutputUrl - indexStart(AFTER):', indexStart, ' indexEnd(AFTER):', indexEnd, ' passLength:', passLength)
        // const passphrase = url.substring(indexStart) // NB: use this if you want to extract just the passphrase
        const urlEnd = url.substring(indexEnd)
        url = url.substring(0, indexStart) + '****' + urlEnd
        // console.log('FFMpegProcess - parseOutputUrl - url(FINAL):\'' + url + '\'')
      }
    }
    return url
  }

  parseOutputSRTServer () : string | undefined {
    const outputUrl = this.parseOutputUrl()
    if (outputUrl && outputUrl.includes('srt://')) {
      const urlNoProtocol = outputUrl.slice('srt://'.length) // remove the `srt://` prefix
      const urlNoArgs = urlNoProtocol.indexOf('?') >= 0 ? urlNoProtocol.substring(0, urlNoProtocol.indexOf('?')) : urlNoProtocol // remove any url query args (if any) e.g: `?=pass...`
      const portIndex = urlNoArgs.indexOf(':')
      const server = (portIndex >= 0) ? urlNoArgs.substring(0, portIndex) : undefined
      console.log('FFMpegProcess - parseOutputSRTServer - urlNoProtocol: ', urlNoProtocol, ' urlNoArgs:', urlNoArgs, ' portIndex:', portIndex, ' urlNoArgs.length:', urlNoArgs.length, ' server:', server)
      return server
    }
    return undefined
  }

  parseOutputSRTPort () : number | undefined {
    const outputUrl = this.parseOutputUrl()
    if (outputUrl && outputUrl.includes('srt://')) {
      const urlNoProtocol = outputUrl.slice('srt://'.length) // remove the `srt://` prefix
      const urlNoArgs = urlNoProtocol.indexOf('?') >= 0 ? urlNoProtocol.substring(0, urlNoProtocol.indexOf('?')) : urlNoProtocol // remove any url query args (if any) e.g: `?=pass...`
      const portIndex = urlNoArgs.indexOf(':')
      const port = ((portIndex >= 0) && ((portIndex + 1) < urlNoArgs.length)) ? urlNoArgs.substring((portIndex + 1)) : undefined
      // console.log('FFMpegProcess - parseOutputSRTPort - urlNoProtocol: ', urlNoProtocol, ' urlNoArgs:', urlNoArgs, ' portIndex:', portIndex, ' urlNoArgs.length:', urlNoArgs.length, ' port:', port)
      if (port) {
        return parseInt(port)
      }
    }
    return undefined
  }

  parseStreamId () : number | undefined {
    // NB: requires the recently added `-metadata streamId=<id>` custom arg to be set for the ffmpeg process to be able to parse it
    if (this.command.includes('-metadata streamId=')) {
      const metadataArgIndex = this.command.indexOf('-metadata streamId=')
      const metadataArgStart = metadataArgIndex >= 0 ? this.command.substring(metadataArgIndex + '-metadata streamId='.length) : undefined
      if (metadataArgStart) {
        const nextSpaceIndex = metadataArgStart.indexOf(' ')
        const metadataArgVal = nextSpaceIndex >= 0 ? metadataArgStart.substring(0, nextSpaceIndex) : undefined
        if (metadataArgVal && !isNaN(parseInt(metadataArgVal))) {
          return parseInt(metadataArgVal)
        }
      }
    }
    return undefined
  }

  parseProgramId () : number | undefined {
    // NB: requires the recently added `-metadata programId=<id>` custom arg to be set for the ffmpeg process to be able to parse it
    if (this.command.includes('-metadata programId=')) {
      const metadataArgIndex = this.command.indexOf('-metadata programId=')
      const metadataArgStart = metadataArgIndex >= 0 ? this.command.substring(metadataArgIndex + '-metadata programId='.length) : undefined
      if (metadataArgStart) {
        const nextSpaceIndex = metadataArgStart.indexOf(' ')
        const metadataArgVal = nextSpaceIndex >= 0 ? metadataArgStart.substring(0, nextSpaceIndex) : undefined
        if (metadataArgVal && !isNaN(parseInt(metadataArgVal))) {
          return parseInt(metadataArgVal)
        }
      }
    }
    return undefined
  }
}

// NB: we just declare this to describe the data schema saved to lowdb via the typedjson encoding
// UPDATE: NOT CURRENTLY USED - TODO: DEPRECIATE? (`DbSchema` is used in its place)
// @jsonObject @toJson
// export class FFMpegDB {
//   @jsonArrayMember(FFMpegSource)
//   sources: Array<FFMpegSource>

//   constructor (sources: Array<FFMpegSource>) {
//     this.sources = sources
//   }
// }
