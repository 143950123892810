import React, { Component } from 'react'

import { StreamhubSourcesContext, IStreamhubSourcesContext, StreamhubSourceStatus, StreamhubSourceAction, StreamhubSourcesStatus } from '../../providers/StreamhubSourcesProvider'
import { StreamhubAssetsContext, IStreamhubAssetsContext } from '../../providers/StreamhubAssetsProvider'

import { FFMpegAudioInput, FFMpegSource, FFMpegSourceAudioType, FFMpegSourceVideoType, FFMpegVideoInput } from '../../models/StreamhubModels'
import ArkTestStreamSourceForm, { ArkTestStreamSourceFormMode } from './StreamhubSourceForm'

import ArkButton from 'src/core/components/ArkButton'
import ArkLoaderView from 'src/core/components/ArkLoader'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'

import { Table, Header, Modal, Image, Message } from 'semantic-ui-react'

import styles from '../Streamhub.module.css'

interface IProps {
  onDataChanged?: Function
}
interface IState {
  editStreamSource?: FFMpegSource
  showFormModal: boolean
  formSaved: boolean
  formError?: Error
}

class StreamhubSourcesView extends Component<IProps & IStreamhubSourcesContext & { assetsContext: IStreamhubAssetsContext }, IState> {
  private _isMounted: boolean = false

  constructor (props: IProps & IStreamhubSourcesContext & { assetsContext: IStreamhubAssetsContext }) {
    super(props)
    this.state = {
      showFormModal: false,
      formSaved: false
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.loadData()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  // -------

  render () {
    return (<>
      {this.renderStreamSources()}
    </>)
  }

  // -------

  renderStreamSources = () => {
    return (
      <div className={styles.sources}>
        <div className={styles.contentHeader}>
          <div className={styles.contentHeaderMain}>
            <Header as='h2' inverted>Stream Sources</Header>
            <div className={styles.actions}>
              <ArkButton onClick={() => { this.showAddStreamSourceModal() }}>ADD STREAM SOURCE</ArkButton>
              <div className={styles.divider}></div>
              <ArkButton color='blue' size='mini' className={styles.refreshBtn} onClick={async () => { this.onDataChanged() }}>REFRESH</ArkButton>
            </div>
          </div>
        </div>
        {this.renderStreamSourcesTable()}
        {this.renderStreamSourceFormModal()}
      </div>
    )
  }

  // -------

  renderStreamSourcesTable = () => {
    const loading = this.props.store.sourcesStatus === StreamhubSourcesStatus.loading
    if (loading) return <ArkLoaderView message='Loading' />
    if (this.props.store.sourcesError) {
      return (
        <Message negative>
          <Message.Header>Error</Message.Header>
          <p>{this.props.store.sourcesError.message ?? ''}</p>
        </Message>
      )
    }
    if (!this.props.store.sources || this.props.store.sources.length === 0) {
      return (
        <Message warning>
          <Message.Header>No Sources</Message.Header>
        </Message>
      )
    }
    return (
      <>
        <Table celled inverted className={styles.table}>
          <Table.Body>
            {this.renderStreamSourcesTableRows(this.props.store.sources)}
          </Table.Body>
        </Table>
      </>
    )
  }

  renderStreamSourcesTableRows = (streamSources: Array<FFMpegSource>) => {
    const { selectedSource } = this.props.store
    return streamSources.map((streamSource: FFMpegSource) => {
      // format the video & audio input strings (mainly focusing on 1x audio/video input, but basic support for multiple incase we use them at any point)
      let videoInput: FFMpegVideoInput | undefined
      let isVideoFileInput = false
      let videoInputFilename: string | undefined
      let videoInputDir: string | undefined
      let videoInputStr = '-'
      if (streamSource.videoInputs.length === 1) {
        videoInput = streamSource.videoInputs[0]
        if (videoInput.type === FFMpegSourceVideoType.file && videoInput.filename) {
          videoInputStr = videoInput.filename
          isVideoFileInput = true
          if (videoInput.filename) {
            const lastDirIndex = videoInput.filename.lastIndexOf('/')
            if (lastDirIndex >= 0 && (lastDirIndex + 1) < videoInput.filename.length) {
              videoInputFilename = videoInput.filename.substring(lastDirIndex + 1)
              videoInputDir = videoInput.filename.substring(0, lastDirIndex + 1)
            } else {
              videoInputFilename = videoInput.filename
            }
          }
        } else {
          videoInputStr = JSON.stringify(videoInput, null, 0)
        }
      } else if (streamSource.videoInputs.length > 1) {
        videoInputStr = streamSource.videoInputs.length + 'x: ' + JSON.stringify(streamSource.videoInputs, null, 0)
      }
      // console.log('StreamhubSourcesView - renderStreamSourcesTableRows - videoInput:', videoInput)

      let audioInput: FFMpegAudioInput | undefined
      let isAudioFileInput = false
      let audioInputStr = ''
      if (streamSource.audioInputs.length === 1) {
        audioInput = streamSource.audioInputs[0]
        if (audioInput.type === FFMpegSourceAudioType.file && audioInput.filename) {
          audioInputStr = audioInput.filename
          isAudioFileInput = true
        } else {
          audioInputStr = JSON.stringify(audioInput, null, 0)
        }
      } else if (streamSource.audioInputs.length > 1) {
        audioInputStr = streamSource.audioInputs.length + 'x: ' + JSON.stringify(streamSource.audioInputs, null, 0)
      }
      // console.log('StreamhubSourcesView - renderStreamSourcesTableRows - audioInput:', audioInput)

      // format the overlays string
      let overlaysStr = ''
      if (streamSource.overlayTitle || streamSource.overlayShowTimecode) {
        if (streamSource.overlayTitle) {
          overlaysStr += 'title: \'' + streamSource.overlayTitle + '\' '
        }
        if (streamSource.overlayShowTimecode) {
          overlaysStr += 'timecode: visible '
        }
      }
      if (streamSource.overlays.length > 0) {
        if (overlaysStr.length > 0) overlaysStr += '<br />'
        overlaysStr = streamSource.overlays.length + 'x ...'
      }

      return (
        <Table.Row
          key={'stream_source_' + streamSource.id}
          active={selectedSource && selectedSource.id === streamSource.id}
          onClick={() => { this.props.actions.selectSource(streamSource) }}
        >
          <Table.Cell className={styles.sourcePreview}>
            <Image src={this.props.actions.getSourcePreviewImageURL(streamSource, 200)} key={streamSource.updatedAt?.toString() ?? streamSource.createdAt?.toString() ?? streamSource.id /* TODO: only change the key when we want to re-render an image after its changed! */} /* ref: https://stackoverflow.com/a/61023057 */ />
          </Table.Cell>
          <Table.Cell className={styles.sourceDetails}>
            <div>
              {/* <Header as='h4' inverted>Source {streamSource.id}</Header>
              <pre>
                Video Input: {videoInputStr}<br />
                Audio Input: {audioInputStr}<br />
                Overlays: {overlaysStr}
              </pre> */}
              <ArkPanel>
                <ArkPanel.Properties titleWidth={100}>
                  <ArkPanel.PropertySection title={`Source ${streamSource.id}`} titleClassName={styles.panelTitle}>
                    <ArkPanel.PropertyRow title='Video Input:' rowClassName={styles.propertyRow} titleClassName={styles.propertyTitle} valueClassName={styles.propertyValue} value={(
                      isVideoFileInput
                        ? (
                          videoInputFilename !== undefined
                            ? (
                              <div>
                                <div><span className={styles.title}>File:</span><span className={styles.value}>{videoInputFilename}</span></div>
                                {videoInputDir && (<div><span className={styles.title}>Dir:</span><span className={styles.value}>{videoInputDir}</span></div>)}
                              </div>
                            )
                            : videoInputStr
                        )
                        : (
                          videoInput !== undefined
                            ? (
                              <>
                                <span className={styles.title}>Type:</span><span className={styles.value}>{videoInput.type}</span>
                                {videoInput.duration !== undefined && videoInput.duration !== null && (<><span className={styles.title}>Duration:</span><span className={styles.value}>{videoInput.duration}</span></>)}
                                {videoInput.fps !== undefined && videoInput.fps !== null && (<><span className={styles.title}>FPS:</span><span className={styles.value}>{videoInput.fps}</span></>)}
                                {videoInput.resolution !== undefined && videoInput.resolution !== null && (<><span className={styles.title}>Resoution:</span><span className={styles.value}>{videoInput.resolution}</span></>)}
                                {videoInput.inputArgsStr !== undefined && videoInput.inputArgsStr !== null && (<><span className={styles.title}>Input Args:</span><span className={styles.value}>{videoInput.inputArgsStr}</span></>)}
                              </>
                            )
                            : <pre>{videoInputStr}</pre>
                        )
                    )} />
                    {audioInputStr.length > 0 && (
                      <ArkPanel.PropertyRow title='Audio Input:' rowClassName={styles.propertyRow} titleClassName={styles.propertyTitle} valueClassName={styles.propertyValue} value={(
                        isAudioFileInput
                          ? audioInputStr
                          : (
                            audioInput !== undefined
                              ? (
                                <>
                                  <span className={styles.title}>Type:</span><span className={styles.value}>{audioInput.type}</span>
                                  {audioInput.duration !== undefined && audioInput.duration !== null && (
                                    <><span className={styles.title}>Duration:</span><span className={styles.value}>{audioInput.duration}</span></>)}
                                  {audioInput.inputArgsStr !== undefined && audioInput.inputArgsStr !== null && (
                                    <><span className={styles.title}>Input Args:</span><span className={styles.value}>{audioInput.inputArgsStr}</span></>)}
                                </>
                              )
                              : <pre>{audioInputStr}</pre>
                          )
                      )} />
                    )}
                    {(streamSource.overlayTitle || streamSource.overlayShowTimecode || overlaysStr.length > 0) && (
                      <>
                        <ArkPanel.PropertyRow title='Overlays:' rowClassName={styles.propertyRow} titleClassName={styles.propertyTitle} valueClassName={styles.propertyValue + ' ' + styles.overlayValue} value={(<>
                          {(streamSource.overlayTitle || streamSource.overlayShowTimecode
                            ? (
                              <>
                                {streamSource.overlayTitle && (
                                  <><span className={styles.title}>Overlay Title:</span><span className={styles.value}>{streamSource.overlayTitle}</span></>)}
                                {streamSource.overlayShowTimecode && (
                                  <><span className={styles.title}>Overlay Timecode:</span><span className={styles.value}>Visible</span></>)}
                              </>
                            )
                            : (
                              <details>
                                <summary><span className={styles.title}>{overlaysStr}</span></summary>
                                <span className={styles.value}>
                                  <pre>
                                    {streamSource.overlays.map((overlay) => (overlay.cmd + '\n\n'))}
                                  </pre>
                                </span>
                              </details>
                            )
                          )}
                        </>)} />
                      </>
                    )}
                  </ArkPanel.PropertySection>
                </ArkPanel.Properties>
              </ArkPanel>
            </div>
          </Table.Cell>
          <Table.Cell textAlign='right'>
            <ArkButton size='mini' onClick={() => { this.showEditStreamSourceModal(streamSource.id) }}>EDIT</ArkButton>
            <ArkManagerDeleteButton
              itemId={streamSource.id}
              itemName={'Source ' + streamSource.id}
              itemTypeName='Stream Source'
              buttonTitle='X'
              onDelete={this.onDeleteSource}
              onDeleteComplete={this.onDeleteSourceComplete}
              fluid={false}
              size='mini'
              style={{ display: 'inline', marginLeft: 10 }}
              buttonStyle={{ fontSize: '12px', padding: '8px 10px' }}
            />
          </Table.Cell>
        </Table.Row>
      )
    })
  }

  // -------

  loadData = async () => {
    this.props.actions.fetchSources()
    this.props.assetsContext.actions.fetchAssets()
  }

  onDataChanged = () => {
    this.loadData()
    if (this.props.onDataChanged) this.props.onDataChanged()
  }

  // -------

  showAddStreamSourceModal = () => {
    this.setState({ editStreamSource: undefined, showFormModal: true, formSaved: false })
  }

  showEditStreamSourceModal = (streamSourceId: number) => {
    const streamSource = this.getStreamSource(streamSourceId)
    this.setState({ editStreamSource: streamSource, showFormModal: true, formSaved: false })
  }

  hideStreamSourceModal = () => {
    this.setState({ editStreamSource: undefined, showFormModal: false })
  }

  // -------

  // ArkManagerDeleteButton onDelete callback, return true on success, or throw an error to have it displayed by the delete modal
  onDeleteSource = async (streamSourceId: number) => {
    await this.props.actions.deleteSourceWithId(streamSourceId)
    if (this.props.store.sourceAction === StreamhubSourceAction.delete) {
      if (this.props.store.sourceStatus === StreamhubSourceStatus.done) {
        return true
      } else if (this.props.store.sourceStatus === StreamhubSourceStatus.error && this.props.store.sourceError) {
        throw this.props.store.sourceError // throw the error so the ArkManagerDeleteButton handling can catch & display it
      }
    }
    return false
  }

  // ArkManagerDeleteButton onDeleteComplete callback, called once the success result has been dismissed by the user
  onDeleteSourceComplete = () => {
    this.onDataChanged()
  }

  // -------

  getStreamSource = (streamSourceId: number) => {
    if (this.props.store.sources && this.props.store.sources.length > 0) {
      return this.props.store.sources.find((source) => source.id === streamSourceId)
    }
    return undefined
  }

  // -------

  renderStreamSourceFormModal = () => {
    const sourceMediaFiles = this.props.assetsContext.store.assets
    return (
      <Modal
        onClose={() => this.hideStreamSourceModal()}
        /* onOpen={() => this.setState({ showProjectFormModal: true })} */
        open={this.state.showFormModal}
        trigger={null /* <ArkButton>Show Modal</ArkButton> */}
        closeOnEscape={true}
        closeOnDimmerClick={false}
        closeIcon={true}
        size='large'
      >
        <Modal.Content>

          {/* this.state.formSaved && (
            <div>SAVED</div>
          ) */}

          {/*! this.state.formSaved && */ (
            <ArkTestStreamSourceForm
              mode={this.state.editStreamSource ? ArkTestStreamSourceFormMode.Edit : ArkTestStreamSourceFormMode.Add}
              streamSource={this.state.editStreamSource}
              streamSourceImageUrl={this.state.editStreamSource ? this.props.actions.getSourcePreviewImageURL(this.state.editStreamSource, 200) : undefined}
              sourceMediaFiles={sourceMediaFiles}
              error={this.state.formError}
              onCancel={() => { this.hideStreamSourceModal() }}
              onSave={async (data: {[key: string]: any}) => {
                // TESTING: flipped to use the new (experimental) source provider handling
                // NB: the result/error aren't returned/thrown directly, instead we can look them up in the providers state instead, once the async function finishes
                if (this.state.editStreamSource) {
                  await this.props.actions.updateSourceFromJson(
                    this.state.editStreamSource.id,
                    data.videoFilename,
                    data.videoJson,
                    data.audioFilename,
                    data.audioJson,
                    data.overlayTitle,
                    data.overlayShowTimecode,
                    data.overlaysJson)
                } else {
                  await this.props.actions.createSourceFromJson(
                    data.videoFilename,
                    data.videoJson,
                    data.audioFilename,
                    data.audioJson,
                    data.overlayTitle,
                    data.overlayShowTimecode,
                    data.overlaysJson)
                }
                // NB: ignore the source provider results if they aren't for the performed action
                if (this.props.store.sourceAction === StreamhubSourceAction.create || this.props.store.sourceAction === StreamhubSourceAction.update) {
                  if (this.props.store.sourceStatus === StreamhubSourceStatus.done) {
                    this.loadData() // reload sources
                    if (this.props.onDataChanged) this.props.onDataChanged(this.props.store.source)
                    if (this._isMounted) this.setState({ formSaved: true, editStreamSource: this.props.store.source })
                    return true
                  } else if (this.props.store.sourceError) {
                    if (this._isMounted) this.setState({ formError: this.props.store.sourceError, formSaved: false })
                    return false
                  }
                }
                return false
                // NB: we don't auto close/hide the modal form when it saves, leave it up for a success message to show & the user to dismiss manually
              }}
              onDelete={() => {
                // trigger a programs data re-load so the deleted one no longer shows
                // this.loadPrograms()
                if (this.props.onDataChanged) this.props.onDataChanged()
                // NB: we don't auto close/hide the modal form when it deletes, leave it up for a delete success message to show & the user to dismiss manually
              }}
              onClose={() => { this.hideStreamSourceModal() }}
            />
          )}
        </Modal.Content>
      </Modal>
    )
  }
}

export class StreamhubSourcesViewWithContext extends Component<IProps, {}> {
  render () {
    return (
      <StreamhubSourcesContext.Consumer>
        {(sourcesContext) => { // { status }
          if (sourcesContext === null) {
            throw new Error('StreamhubSourceConsumer must be used within a StreamhubSourcesProvider')
          }
          // console.log('StreamhubSourcesViewWithContext - render - StreamhubSourcesContext.Consumer - sourcesContext.store.sources: ', sourcesContext.store.sources)
          return (
            <StreamhubAssetsContext.Consumer>
              {(assetsContext) => { // { status }
                if (assetsContext === null) {
                  throw new Error('StreamhubAssetsConsumer must be used within a StreamhubAssetsProvider')
                }
                // console.log('StreamhubSourcesViewWithContext - render - StreamhubAssetsContext.Consumer - assetsContext.store.assets: ', assetsContext.store.assets)
                return (
                  <StreamhubSourcesView
                    {...this.props}
                    {...sourcesContext}
                    {...{ assetsContext: assetsContext }}
                  />
                )
              }}
            </StreamhubAssetsContext.Consumer>
          )
        }}
      </StreamhubSourcesContext.Consumer>
    )
  }
}
export default StreamhubSourcesViewWithContext
export { StreamhubSourcesView }
