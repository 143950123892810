import React, { useContext } from 'react'

import { withUserContext, IUserMultiContext } from '.'
import { Channel, ChannelProgramOperation, Company, CompanyUser, ProjectGroup, GroupChannelOperation, GroupProgramOperation, GroupUserOperation, Program, Project, ProjectAddGuestUser, ProjectInviteUser, ProjectInviteUserResult, ProjectInviteUserType, ProjectSummary, ProjectUser, UserProjectRole, CompanyGroup } from '../models'

import ServerAPIClient from '../services/ServerAPIClient'
import ServerCompanyAPI from '../services/ServerCompanyAPI'
import ServerProjectAPI, { ServerProjectAvailablePortRanges, ServerProjectWatermarkDoesNotExistError, ServerProjectWatermarkErrorReadingError } from '../services/ServerProjectAPI'
import ServerGroupAPI from '../services/ServerGroupAPI'
import ServerGuestAPI from '../services/ServerGuestAPI'
import ServerVideoEngineAPI, { IServerVideoEnginePortStatusResult, ServerVideoEnginePortStatus, ServerVideoEngineInvalidPortError } from '../services/ServerVideoEngineAPI'
import { IProgramAddData, IProgramUpdateData } from '../models/program'
import { IProjectGroupAddData, IProjectGroupUpdateData } from '../models/group'

export { ServerProjectWatermarkDoesNotExistError, ServerProjectWatermarkErrorReadingError }
export { ServerVideoEngineInvalidPortError }
export type { ServerProjectAvailablePortRanges, IServerVideoEnginePortStatusResult, ServerVideoEnginePortStatus }

export interface IProjectAdminStore {
}

export interface IProjectAdminActions {
  // project groups
  getProjectGroups: (companyId: number, projectId: number) => Promise<Array<ProjectGroup>>
  getProjectGroup: (companyId: number, projectId: number, groupId: number) => Promise<ProjectGroup>
  addProjectGroup: (companyId: number, projectId: number, groupData: IProjectGroupAddData) => Promise<ProjectGroup>
  updateProjectGroup: (companyId: number, projectId: number, groupId: number, groupData: IProjectGroupUpdateData) => Promise<boolean>
  deleteProjectGroup: (companyId: number, projectId: number, groupId: number) => Promise<boolean>
  // project group access/status
  updateProjectGroupAccessEnabled: (companyId: number, projectId: number, groupId: number, accessEnabled: boolean) => Promise<boolean>
  // project group users
  getProjectGroupUsers: (companyId: number, projectId: number, groupId: number) => Promise<Array<ProjectUser> | null>
  updateProjectGroupUsers: (companyId: number, projectId: number, groupId: number, operations: Array<GroupUserOperation>) => Promise<boolean | Map<number, any>>
  // project group channels
  getProjectGroupChannels: (companyId: number, projectId: number, groupId: number) => Promise<Array<Channel> | null>
  updateProjectGroupChannels: (companyId: number, projectId: number, groupId: number, operations: Array<GroupChannelOperation>) => Promise<boolean | Map<number, any>>
  // project group programs
  getProjectGroupPrograms: (companyId: number, projectId: number, groupId: number) => Promise<Array<Program> | null>
  updateProjectGroupPrograms: (companyId: number, projectId: number, groupId: number, operations: Array<GroupProgramOperation>) => Promise<boolean | Map<number, any>>
  // project visible org/company groups
  getVisibleCompanyGroupsForProject: (companyId: number, projectId: number) => Promise<Array<CompanyGroup>>
  getVisibleCompanyGroupUsers: (companyId: number, groupId: number) => Promise<Array<CompanyUser> | null>
  // company users
  getCompanyUsers: (companyId: number) => Promise<Array<CompanyUser> | null>
  reinviteUserToCompany: (companyId: number, userId: number) => Promise<boolean>
  // project users
  getProjectUsers: (companyId: number, projectId: number) => Promise<Array<ProjectUser> | null>
  inviteUsersToProject: (companyId: number, projectId: number, invitedUsers: Array<ProjectInviteUser>) => Promise<{ ok: Array<ProjectInviteUserResult>, error: Array<ProjectInviteUserResult> }>
  inviteUserToProject: (companyId: number, projectId: number, invitedUser: ProjectInviteUser) => Promise<ProjectInviteUserResult>
  removeUserFromProject: (companyId: number, projectId: number, userId: number) => Promise<boolean>
  updateUserProjectRole: (companyId: number, projectId: number, userId: number, projectRole: UserProjectRole) => Promise<boolean>
  updateUserProjectAccessEnabled: (companyId: number, projectId: number, userId: number, accessEnabled: boolean) => Promise<boolean>
  // project shared guest users
  addSharedGuestUserToProject: (companyId: number, projectId: number, guestUser: ProjectAddGuestUser) => Promise<ProjectUser>
  updateSharedGuestUser: (companyId: number, projectId: number, userId: number, name?: string, desc?: string) => Promise<ProjectUser>
  removeSharedGuestUserFromProject: (companyId: number, projectId: number, userId: number) => Promise<boolean>
  // project channels
  getUserCompanyProjectChannels: (companyId: number, projectId: number) => Promise<Array<Channel> | null>
  getUserCompanyProjectChannel: (companyId: number, projectId: number, channelId: number) => Promise<Channel | null>
  getAllCompanyProjectChannels: (companyId: number, projectId: number) => Promise<Array<Channel> | null>
  addCompanyProjectChannel: (companyId: number, projectId: number, channelName: string, colour?: string, enableAutoSolo?: boolean, playbackLayoutId?: number) => Promise<Channel | null>
  updateCompanyProjectChannel: (companyId: number, projectId: number, channel: Channel) => Promise<boolean>
  deleteCompanyProjectChannel: (companyId: number, projectId: number, channelId: number) => Promise<boolean>
  // project programs
  getAllCompanyProjectPrograms: (companyId: number, projectId: number) => Promise<Array<Program> | null>
  addCompanyProjectProgram: (companyId: number, projectId: number, programData: IProgramAddData) => Promise<Program>
  updateCompanyProjectProgram: (companyId: number, projectId: number, programId: number, programData: IProgramUpdateData) => Promise<Program>
  deleteCompanyProjectProgram: (companyId: number, projectId: number, programId: number) => Promise<boolean>
  recreateCompanyProjectProgram: (companyId: number, projectId: number, programId: number) => Promise<boolean>
  // project channel programs
  getAllCompanyProjectChannelPrograms: (companyId: number, projectId: number, channelId: number) => Promise<Array<Program> | null>
  getUserCompanyProjectChannelPrograms: (companyId: number, projectId: number, channelId: number) => Promise<Array<Program> | null>
  updateProjectChannelPrograms: (companyId: number, projectId: number, channelId: number, operations: Array<ChannelProgramOperation>) => Promise<boolean | Map<number, any>>
  // project program ports
  getProjectAvailablePorts: (companyId: number, projectId: number) => Promise<ServerProjectAvailablePortRanges | null>
  checkProjectProgramPortStatus: (companyId: number, projectId: number, videoEngineId: number, port: number) => Promise<IServerVideoEnginePortStatusResult>
  // project info & summary
  getProjectInfo: (companyId: number, projectId: number) => Promise<Project>
  getProjectSummary: (companyId: number, projectId: number) => Promise<ProjectSummary>
  // project settings
  updateProjectInfo: (companyId: number, projectId: number, values: {[key: string]: any}, reloadProjectData?: boolean) => Promise<Project>
  syncProjectTranscoderSettings: (companyId: number, projectId: number, force?: boolean) => Promise<boolean>
  // project watermark
  getProjectWatermarkExists: (companyId: number, projectId: number) => Promise<boolean>
  getProjectWatermarkImage: (companyId: number, projectId: number, original?: boolean) => Promise<string>
  getProjectWatermarkImageBlob: (companyId: number, projectId: number, original?: boolean) => Promise<Blob>
  uploadProjectWatermark: (companyId: number, projectId: number, originalImgBlob: Blob, editedImgBlob: Blob, originalFilename?: string, editedFilename?: string) => Promise<boolean>
  deleteProjectWatermark: (companyId: number, projectId: number) => Promise<boolean>
  // company info/settings
  getCompanyInfo: (companyId: number) => Promise<Company | null>
}

export interface IProjectAdminContext {
  actions: IProjectAdminActions
  store: IProjectAdminStore
}

export interface IProjectAdminMultiContext {
  projectAdminContext: IProjectAdminContext
}

export const ProjectAdminContext = React.createContext<IProjectAdminContext>({} as IProjectAdminContext)

export const useProjectAdmin = () => useContext(ProjectAdminContext)

export interface ProjectAdminProviderProps extends IUserMultiContext {
  apiClient: ServerAPIClient
  companyApi?: ServerCompanyAPI
  projectApi?: ServerProjectAPI
  groupApi?: ServerGroupAPI
  guestApi?: ServerGuestAPI
  videoEngineAPI?: ServerVideoEngineAPI // for program-admin/manager+ access to available video-engine ports & checking port availability
}
export interface ProjectAdminProviderState extends IProjectAdminStore {
  companyApi: ServerCompanyAPI
  projectApi: ServerProjectAPI
  groupApi: ServerGroupAPI
  guestApi: ServerGuestAPI
  videoEngineAPI: ServerVideoEngineAPI
}

class ProjectAdminProvider extends React.Component<ProjectAdminProviderProps, ProjectAdminProviderState> {
  constructor (props: ProjectAdminProviderProps) {
    super(props)
    this.state = {
      companyApi: props.companyApi ?? new ServerCompanyAPI(this.props.apiClient),
      projectApi: props.projectApi ?? new ServerProjectAPI(this.props.apiClient),
      groupApi: props.groupApi ?? new ServerGroupAPI(this.props.apiClient),
      guestApi: props.guestApi ?? new ServerGuestAPI(this.props.apiClient),
      videoEngineAPI: props.videoEngineAPI ?? new ServerVideoEngineAPI(this.props.apiClient)
    }
  }

  componentDidMount () {
  }

  // -------

  // project groups
  getProjectGroups = async (companyId: number, projectId: number): Promise<Array<ProjectGroup>> =>
    this.state.groupApi.getProjectGroups(companyId, projectId)

  getProjectGroup = async (companyId: number, projectId: number, groupId: number): Promise<ProjectGroup> =>
    this.state.groupApi.getProjectGroup(companyId, projectId, groupId)

  addProjectGroup = async (companyId: number, projectId: number, groupData: IProjectGroupAddData): Promise<ProjectGroup> => // , groupType: GroupType = GroupType.project // name: string, desc?: string, mirrorGroupId?: number, parentGroupId?: number, isParentGroup?: boolean
    this.state.groupApi.addProjectGroup(companyId, projectId, groupData)

  updateProjectGroup = async (companyId: number, projectId: number, groupId: number, groupData: IProjectGroupUpdateData): Promise<boolean> =>
    this.state.groupApi.updateProjectGroup(companyId, projectId, groupId, groupData)

  deleteProjectGroup = async (companyId: number, projectId: number, groupId: number): Promise<boolean> =>
    this.state.groupApi.deleteProjectGroup(companyId, projectId, groupId)

  // -------

  updateProjectGroupAccessEnabled = async (companyId: number, projectId: number, groupId: number, accessEnabled: boolean): Promise<boolean> => {
    await this.state.groupApi.updateProjectGroupAccessEnabled(companyId, projectId, groupId, accessEnabled)
    return true // NB: will throw an error if the api call fails
  }

  // -------

  // project group users
  getProjectGroupUsers = async (companyId: number, projectId: number, groupId: number): Promise<Array<ProjectUser> | null> =>
    this.state.groupApi.getProjectGroupUsers(companyId, projectId, groupId)

  updateProjectGroupUsers = async (companyId: number, projectId: number, groupId: number, operations: Array<GroupUserOperation>): Promise<boolean | Map<number, any>> => {
    const result = await this.state.groupApi.updateProjectGroupUsers(companyId, projectId, groupId, operations)
    // trigger a user data update so changes are available in the viewer straight away
    // NB: only trigger the update if this user was in the group>user changes applied
    if (result && operations.length > 0 && this.props.userContext.store.user?.id && operations.find((op) => op.user_id === this.props.userContext.store.user?.id)) {
      // TODO: this currently forces the whole UI to update, & reset the project groups admin page this is triggered from, find a way to not do that! <<<
      await this.props.userContext.actions.reloadUserData() // TODO: ideally only reload of related user data (NB: was originally calling Server loadUserSelections in the pre-provider code)
    }
    return result
  }

  // -------

  // project group channels
  getProjectGroupChannels = async (companyId: number, projectId: number, groupId: number): Promise<Array<Channel> | null> =>
    this.state.groupApi.getProjectGroupChannels(companyId, projectId, groupId)

  updateProjectGroupChannels = async (companyId: number, projectId: number, groupId: number, operations: Array<GroupChannelOperation>): Promise<boolean | Map<number, any>> => {
    const result = await this.state.groupApi.updateProjectGroupChannels(companyId, projectId, groupId, operations)
    // trigger a user data update so changes are available in the viewer straight away
    // TODO: only trigger the update if this user is assigned to the group being edited? (would need the list of group ids the user is in to be able to do that...)
    if (result) { // && operations.length > 0 && this.props.userContext.store.user?.id && operations.find((op) => op.user_id === this.props.userContext.store.user?.id)) {
      console.log('ProjectAdminProvider - updateProjectGroupChannels - UPDATE (LOGGED IN) USER DATA...')
      await this.props.userContext.actions.reloadUserData() // TODO: ideally only reload of related user data (NB: was originally calling Server loadUserSelections in the pre-provider code)
    }
    return result
  }

  // -------

  // project group programs
  getProjectGroupPrograms = async (companyId: number, projectId: number, groupId: number): Promise<Array<Program> | null> =>
    this.state.groupApi.getProjectGroupPrograms(companyId, projectId, groupId)

  updateProjectGroupPrograms = async (companyId: number, projectId: number, groupId: number, operations: Array<GroupProgramOperation>): Promise<boolean | Map<number, any>> => {
    const result = await this.state.groupApi.updateProjectGroupPrograms(companyId, projectId, groupId, operations)
    // TODO: update local user data if the user is in the group being updated?
    // trigger a user data update so changes are available in the viewer straight away
    // TODO: only trigger the update if this user is assigned to the group being edited?
    if (result === true || (result instanceof Map && result.size !== operations.length)) {
      console.log('ProjectAdminProvider - updateProjectGroupPrograms - UPDATE (LOGGED IN) USER DATA...')
      await this.props.userContext.actions.reloadUserData() // TODO: ideally only reload of related user data (NB: was originally calling Server loadUserSelections in the pre-provider code)
    }
    return result
  }

  // -------

  // project visible org/company groups
  getVisibleCompanyGroupsForProject = async (companyId: number, projectId: number): Promise<Array<CompanyGroup>> =>
    this.state.groupApi.getVisibleCompanyGroupsForProject(companyId, projectId)

  getVisibleCompanyGroupUsers = async (companyId: number, groupId: number): Promise<Array<CompanyUser> | null> =>
    this.state.groupApi.getCompanyGroupUsers(companyId, groupId)

  // -------

  // company users
  getCompanyUsers = async (companyId: number): Promise<Array<CompanyUser> | null> =>
    this.state.companyApi.getCompanyUsers(companyId)

  reinviteUserToCompany = async (companyId: number, userId: number): Promise<boolean> =>
    this.state.companyApi.reinviteUserToCompany(companyId, userId)

  // -------

  // project users
  getProjectUsers = async (companyId: number, projectId: number): Promise<Array<ProjectUser> | null> =>
    this.state.projectApi.getCompanyProjectUsers(companyId, projectId)

  // NB: not currently used, the single user invite function `inviteUserToProject` seems to be used instead for now
  // TODO: if using this in the future, extend it like the single user `inviteUserToProject` function below to trigger extra updates if the logged in user is in the list of users being invited
  inviteUsersToProject = async (companyId: number, projectId: number, invitedUsers: Array<ProjectInviteUser>): Promise<{ ok: Array<ProjectInviteUserResult>, error: Array<ProjectInviteUserResult> }> =>
    this.state.projectApi.inviteUsersToProject(companyId, projectId, invitedUsers)

  inviteUserToProject = async (companyId: number, projectId: number, invitedUser: ProjectInviteUser): Promise<ProjectInviteUserResult> => {
    const inviteResult = await this.state.projectApi.inviteUserToProject(companyId, projectId, invitedUser)
    console.log('ProjectAdminProvider - inviteUserToProject - inviteResult: ', inviteResult)
    // if the logged in user is the one being invited, trigger a user data update so the new project user data is available in the viewer straight away
    if (inviteResult.result) {
      // check if the logged in user is the invited user
      if (invitedUser.type === ProjectInviteUserType.user && invitedUser.userId === this.props.userContext.store.user?.id) {
        console.log('ProjectAdminProvider - inviteUserToProject - UPDATE (LOGGED IN) USER DATA...')
        // trigger a user data update so the new project user is available in the viewer straight away
        await this.props.userContext.actions.reloadUserData() // TODO: ideally only reload of related user data (NB: was originally calling Server loadUserSelections in the pre-provider code)
      }
    }
    return inviteResult
  }

  removeUserFromProject = async (companyId: number, projectId: number, userId: number): Promise<boolean> => {
    const removeResult = await this.state.projectApi.removeUserFromProject(companyId, projectId, userId)
    console.log('ProjectAdminProvider - removeUserFromProject - removeResult: ', removeResult)
    // if the logged in user is the one being removed, trigger a user data update so the new project user data is available in the viewer straight away
    if (removeResult) {
      // check if the logged in user is the removed user
      if (userId === this.props.userContext.store.user?.id) {
        console.log('ProjectAdminProvider - removeUserFromProject - UPDATE (LOGGED IN) USER DATA...')
        // trigger a user data update so the removed project user is removed from the viewer straight away
        await this.props.userContext.actions.reloadUserData() // TODO: ideally only reload of related user data (NB: was originally calling Server loadUserSelections in the pre-provider code)
      }
    }
    return removeResult
  }

  updateUserProjectRole = async (companyId: number, projectId: number, userId: number, projectRole: UserProjectRole): Promise<boolean> =>
    this.state.projectApi.updateUserProjectRole(companyId, projectId, userId, projectRole)

  updateUserProjectAccessEnabled = async (companyId: number, projectId: number, userId: number, accessEnabled: boolean): Promise<boolean> => {
    const updateResult = await this.state.projectApi.updateUserProjectAccessEnabled(companyId, projectId, userId, accessEnabled)
    // if the logged in user is the one being updated, trigger a user data update so the new project user data is available in the viewer straight away
    if (updateResult) {
      // check if the logged in user is the removed user
      if (userId === this.props.userContext.store.user?.id) {
        console.log('ProjectAdminProvider - updateUserProjectAccessEnabled - UPDATE (LOGGED IN) USER DATA...')
        // trigger a user data update so the removed project user is removed from the viewer straight away
        await this.props.userContext.actions.reloadUserData() // TODO: ideally only reload of related user data (NB: was originally calling Server loadUserSelections in the pre-provider code)
      }
    }
    return updateResult
  }

  // project shared guest users
  addSharedGuestUserToProject = async (companyId: number, projectId: number, guestUser: ProjectAddGuestUser): Promise<ProjectUser> =>
    this.state.guestApi.addSharedGuestUserToProject(companyId, projectId, guestUser)

  updateSharedGuestUser = async (companyId: number, projectId: number, userId: number, name?: string, desc?: string): Promise<ProjectUser> =>
    this.state.guestApi.updateSharedGuestUser(companyId, projectId, userId, name, desc)

  removeSharedGuestUserFromProject = async (companyId: number, projectId: number, userId: number): Promise<boolean> =>
    this.state.guestApi.removeSharedGuestUserFromProject(companyId, projectId, userId)

  // project channels
  getUserCompanyProjectChannels = async (companyId: number, projectId: number): Promise<Array<Channel> | null> =>
    this.state.projectApi.getUserCompanyProjectChannels(companyId, projectId)

  getUserCompanyProjectChannel = async (companyId: number, projectId: number, channelId: number): Promise<Channel | null> =>
    this.state.projectApi.getUserCompanyProjectChannel(companyId, projectId, channelId)

  getAllCompanyProjectChannels = async (companyId: number, projectId: number): Promise<Array<Channel> | null> =>
    this.state.projectApi.getAllCompanyProjectChannels(companyId, projectId)

  addCompanyProjectChannel = async (companyId: number, projectId: number, channelName: string, colour?: string, enableAutoSolo?: boolean, playbackLayoutId?: number): Promise<Channel | null> =>
    this.state.projectApi.addCompanyProjectChannel(companyId, projectId, channelName, colour, enableAutoSolo, playbackLayoutId)

  updateCompanyProjectChannel = async (companyId: number, projectId: number, channel: Channel): Promise<boolean> =>
    this.state.projectApi.updateCompanyProjectChannel(companyId, projectId, channel)

  deleteCompanyProjectChannel = async (companyId: number, projectId: number, channelId: number): Promise<boolean> =>
    this.state.projectApi.deleteCompanyProjectChannel(companyId, projectId, channelId)

  // project programs
  getAllCompanyProjectPrograms = async (companyId: number, projectId: number): Promise<Array<Program> | null> =>
    this.state.projectApi.getAllCompanyProjectPrograms(companyId, projectId)

  addCompanyProjectProgram = async (companyId: number, projectId: number, programData: IProgramAddData): Promise<Program> => {
    // NB: throws an exception if the api call fails
    const program = await this.state.projectApi.addCompanyProjectProgram(companyId, projectId, programData)
    // trigger a user data update so the program changes are available throughout the rest of the app (mainly the viewer)
    await this.props.userContext.actions.reloadUserData()
    return program
  }

  updateCompanyProjectProgram = async (companyId: number, projectId: number, programId: number, programData: IProgramUpdateData): Promise<Program> => {
    // NB: throws an exception if the api call fails
    const program = await this.state.projectApi.updateCompanyProjectProgram(companyId, projectId, programId, programData)
    // trigger a user data update so the program changes are available throughout the rest of the app (mainly the viewer)
    await this.props.userContext.actions.reloadUserData()
    return program
  }

  deleteCompanyProjectProgram = async (companyId: number, projectId: number, programId: number): Promise<boolean> => {
    const result = await this.state.projectApi.deleteCompanyProjectProgram(companyId, projectId, programId)
    if (result) {
      // trigger a user data update so the program changes are available throughout the rest of the app (mainly the viewer)
      await this.props.userContext.actions.reloadUserData()
    }
    return result
  }

  recreateCompanyProjectProgram = async (companyId: number, projectId: number, programId: number): Promise<boolean> => {
    const result = await this.state.projectApi.recreateCompanyProjectProgram(companyId, projectId, programId)
    if (result) {
      // trigger a user data update so the program changes are available throughout the rest of the app (mainly the viewer)
      await this.props.userContext.actions.reloadUserData()
    }
    return result
  }

  // project channel programs
  getAllCompanyProjectChannelPrograms = async (companyId: number, projectId: number, channelId: number): Promise<Array<Program> | null> =>
    this.state.projectApi.getAllCompanyProjectChannelPrograms(companyId, projectId, channelId)

  getUserCompanyProjectChannelPrograms = async (companyId: number, projectId: number, channelId: number): Promise<Array<Program> | null> =>
    this.state.projectApi.getUserCompanyProjectChannelPrograms(companyId, projectId, channelId)

  updateProjectChannelPrograms = async (companyId: number, projectId: number, channelId: number, operations: Array<ChannelProgramOperation>): Promise<boolean | Map<number, any>> => {
    const result = await this.state.projectApi.updateProjectChannelPrograms(companyId, projectId, channelId, operations)
    // TODO: also trigger a user data update to get the changes (so they're available in the viewer straight away)
    // TODO: if the edited channel is also the current selection in the viewer, reload it, so the changes show when they flip back to the viewer
    if (result === true || (result instanceof Map && result.size !== operations.length)) {
      await this.props.userContext.actions.reloadUserData() // TODO: ideally only reload of related user data (NB: was originally calling Server loadUserSelections in the pre-provider code)
    }
    return result
  }

  // project ports
  getProjectAvailablePorts = async (companyId: number, projectId: number): Promise<ServerProjectAvailablePortRanges | null> => {
    return this.state.projectApi.getCompanyProjectAvailablePorts(companyId, projectId)
  }

  checkProjectProgramPortStatus = async (companyId: number, projectId: number, videoEngineId: number, port: number): Promise<IServerVideoEnginePortStatusResult> => {
    return this.state.videoEngineAPI.checkVideoEnginePortStatus(companyId, projectId, videoEngineId, port)
  }

  // -------

  // project info & summary
  getProjectInfo = async (companyId: number, projectId: number): Promise<Project> => {
    return this.state.projectApi.getCompanyProjectInfo(companyId, projectId)
  }

  getProjectSummary = async (companyId: number, projectId: number): Promise<ProjectSummary> => {
    return this.state.projectApi.getCompanyProjectSummary(companyId, projectId)
  }

  // -------

  // project settings
  updateProjectInfo = async (companyId: number, projectId: number, values: {[key: string]: any}, reloadProjectData: boolean = false): Promise<Project> => {
    console.log('ProjectAdminProvider - updateProjectInfo - companyId: ', companyId, ' projectId: ', projectId, ' values: ', values, ' reloadProjectData: ', reloadProjectData)
    // TODO: filter what project fields can be updated via this call vs the updateCompanyProject handling in other higher level providers?
    // NB: we DON'T trigger a reloadUserData call for project level settings, as currently they don't trigger any major project data changes
    // TODO: although the fields we update here won't be updated at the user data level, but currently the only fields we update via this are
    // TODO: ..transcoder focused & we load those from a specific api call, not from the general user projects data loading at init
    // TODO: maybe add an optional arg that triggers a user data update on success? so the calling code controls when to trigger an update
    const result = await this.state.projectApi.updateCompanyProjectInfo(companyId, projectId, values)
    console.log('ProjectAdminProvider - updateProjectInfo - result: ', result)
    if (result && reloadProjectData) {
      // trigger a user data update so changes are available (like force 2fa status changes)
      // TODO: only trigger the update if certain fields have changed? (like 2fa status?)
      // TODO: ideally only update/reload the project for the selected/current project not all user data (we don't have an easy way to only update certain user selection data so triggering a full update for now)
      await this.props.userContext.actions.reloadUserData()
    }
    return result
  }

  syncProjectTranscoderSettings = async (companyId: number, projectId: number, force?: boolean): Promise<boolean> => {
    const result = await this.state.projectApi.syncCompanyProjectTranscoderSettings(companyId, projectId, force)
    if (result) {
      // trigger a user data update so changes are available (to update the company projects sync status if its changed)
      // TODO: only trigger the update if certain transcoder fields have changed that cause the company projects sync flag to change?
      // TODO: ideally only update/reload this single project for the selected/current company not all user data (we don't have an easy way to only update certain user selection data so triggering a full update for now)
      await this.props.userContext.actions.reloadUserData()
    }
    return result
  }

  // -------

  // project watermark
  getProjectWatermarkExists = async (companyId: number, projectId: number): Promise<boolean> => {
    return await this.state.projectApi.getProjectWatermarkExists(companyId, projectId)
  }

  getProjectWatermarkImage = async (companyId: number, projectId: number, original: boolean = false): Promise<string> => {
    return await this.state.projectApi.getProjectWatermarkImage(companyId, projectId, original)
  }

  getProjectWatermarkImageBlob = async (companyId: number, projectId: number, original: boolean = false): Promise<Blob> => {
    return await this.state.projectApi.getProjectWatermarkImageBlob(companyId, projectId, original)
  }

  uploadProjectWatermark = async (companyId: number, projectId: number, originalImgBlob: Blob, editedImgBlob: Blob, originalFilename?: string, editedFilename?: string): Promise<boolean> => {
    return await this.state.projectApi.uploadProjectWatermark(companyId, projectId, originalImgBlob, editedImgBlob, originalFilename, editedFilename)
  }

  deleteProjectWatermark = async (companyId: number, projectId: number): Promise<boolean> => {
    return await this.state.projectApi.deleteProjectWatermark(companyId, projectId)
  }

  // -------

  getCompanyInfo = async (companyId: number): Promise<Company | null> => {
    return await this.state.companyApi.getCompanyInfo(companyId)
  }

  // -------

  actions: IProjectAdminActions = {
    // project groups
    getProjectGroups: this.getProjectGroups,
    getProjectGroup: this.getProjectGroup,
    addProjectGroup: this.addProjectGroup,
    updateProjectGroup: this.updateProjectGroup,
    deleteProjectGroup: this.deleteProjectGroup,
    // project group access/status
    updateProjectGroupAccessEnabled: this.updateProjectGroupAccessEnabled,
    // project group users
    getProjectGroupUsers: this.getProjectGroupUsers,
    updateProjectGroupUsers: this.updateProjectGroupUsers,
    // project group channels
    getProjectGroupChannels: this.getProjectGroupChannels,
    updateProjectGroupChannels: this.updateProjectGroupChannels,
    // project group programs
    getProjectGroupPrograms: this.getProjectGroupPrograms,
    updateProjectGroupPrograms: this.updateProjectGroupPrograms,
    // project visible org/company groups
    getVisibleCompanyGroupsForProject: this.getVisibleCompanyGroupsForProject,
    getVisibleCompanyGroupUsers: this.getVisibleCompanyGroupUsers,
    // company users
    getCompanyUsers: this.getCompanyUsers,
    reinviteUserToCompany: this.reinviteUserToCompany,
    // project users
    getProjectUsers: this.getProjectUsers,
    inviteUsersToProject: this.inviteUsersToProject,
    inviteUserToProject: this.inviteUserToProject,
    removeUserFromProject: this.removeUserFromProject,
    updateUserProjectRole: this.updateUserProjectRole,
    updateUserProjectAccessEnabled: this.updateUserProjectAccessEnabled,
    // project shared guest users
    addSharedGuestUserToProject: this.addSharedGuestUserToProject,
    updateSharedGuestUser: this.updateSharedGuestUser,
    removeSharedGuestUserFromProject: this.removeSharedGuestUserFromProject,
    // project channels
    getUserCompanyProjectChannels: this.getUserCompanyProjectChannels,
    getUserCompanyProjectChannel: this.getUserCompanyProjectChannel,
    getAllCompanyProjectChannels: this.getAllCompanyProjectChannels,
    addCompanyProjectChannel: this.addCompanyProjectChannel,
    updateCompanyProjectChannel: this.updateCompanyProjectChannel,
    deleteCompanyProjectChannel: this.deleteCompanyProjectChannel,
    // project programs
    getAllCompanyProjectPrograms: this.getAllCompanyProjectPrograms,
    addCompanyProjectProgram: this.addCompanyProjectProgram,
    updateCompanyProjectProgram: this.updateCompanyProjectProgram,
    deleteCompanyProjectProgram: this.deleteCompanyProjectProgram,
    recreateCompanyProjectProgram: this.recreateCompanyProjectProgram,
    // project channel programs
    getAllCompanyProjectChannelPrograms: this.getAllCompanyProjectChannelPrograms,
    getUserCompanyProjectChannelPrograms: this.getUserCompanyProjectChannelPrograms,
    updateProjectChannelPrograms: this.updateProjectChannelPrograms,
    // project (video engine) port ranges
    getProjectAvailablePorts: this.getProjectAvailablePorts,
    checkProjectProgramPortStatus: this.checkProjectProgramPortStatus,
    // project info & summary
    getProjectInfo: this.getProjectInfo,
    getProjectSummary: this.getProjectSummary,
    // project settings
    updateProjectInfo: this.updateProjectInfo,
    syncProjectTranscoderSettings: this.syncProjectTranscoderSettings,
    // project watermark
    getProjectWatermarkExists: this.getProjectWatermarkExists,
    getProjectWatermarkImage: this.getProjectWatermarkImage,
    getProjectWatermarkImageBlob: this.getProjectWatermarkImageBlob,
    uploadProjectWatermark: this.uploadProjectWatermark,
    deleteProjectWatermark: this.deleteProjectWatermark,
    // company info/settings
    getCompanyInfo: this.getCompanyInfo
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: IProjectAdminStore = {
  //  ...
  // }

  render () {
    return (
      <ProjectAdminContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for IProjectAdminStore */ }}
      >
        {this.props.children}
      </ProjectAdminContext.Provider>
    )
  }
}

const withProjectAdminContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withProjectAdminContextHOC = (props: any) => (
    <ProjectAdminContext.Consumer>
      {(projectAdminContext) => {
        if (projectAdminContext === null) {
          throw new Error('ProjectAdminConsumer must be used within a ProjectAdminProvider')
        }
        // console.log('withProjectAdminContext - render - ProjectAdminContext.Consumer - projectAdminContext.store: ', projectAdminContext.store)
        return (<Component {...props} {...{ projectAdminContext: projectAdminContext }} />)
      }}
    </ProjectAdminContext.Consumer>
  )
  return withProjectAdminContextHOC
}

const ProjectAdminProviderWithContext = withUserContext(ProjectAdminProvider)

export { ProjectAdminProviderWithContext as ProjectAdminProvider }
export { withProjectAdminContext }
