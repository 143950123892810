import React from 'react'

import StreamhubAPIClient from '../services/StreamhubAPIClient'
import StreamhubSocketClient, { StreamhubSocketStatus } from '../services/StreamhubSocketClient' // TODO: only temp added here, move to own provider?

import { withAuthContext, IAuthMultiContext, IServerMultiContext, INavMultiContext, withServerContext, withNavContext, withServerConfigContext, IServerConfigMultiContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'
import { parseJwt } from 'src/core/utilities/token'

export { StreamhubSocketStatus }

export interface StreamhubServer {
  key: string
  url: string
  title?: string
  socketUrl?: string
}

export enum StreamhubServerDataStatus {
  initial, loading, done, error
}

export interface IStreamhubServerStore {
  currentServer?: StreamhubServer
  apiClient?: StreamhubAPIClient
  socketClient?: StreamhubSocketClient
  socketStatus?: StreamhubSocketStatus
  updatingServerAuthToken: boolean
}

export interface IStreamhubServerActions {
  getServers: () => Array<StreamhubServer>
  getServerWithURL: (url: string) => StreamhubServer | undefined
  selectServer: (server?: StreamhubServer) => void
  selectServerWithKey: (url: string) => void
  selectServerWithURL: (url: string) => void
  updateServerAuthToken: (dbg: boolean) => Promise<boolean>
}

export interface IStreamhubServerContext {
  actions: IStreamhubServerActions;
  store: IStreamhubServerStore;
}

export const StreamhubServerContext = React.createContext<IStreamhubServerContext>({} as IStreamhubServerContext)

export interface StreamhubServerProviderProps extends IServerMultiContext, INavMultiContext, IAuthMultiContext, IServerConfigMultiContext {
  servers: Array<StreamhubServer>
  authToken?: string
  authDeviceUUID?: string
}
export interface StreamhubServerProviderState extends IStreamhubServerStore {
  updatingServerAuthToken: boolean
}

class StreamhubServerProviderBase extends React.Component<StreamhubServerProviderProps, StreamhubServerProviderState> {
  _isMounted: boolean = false

  constructor (props: StreamhubServerProviderProps) {
    super(props)
    this.state = {
      updatingServerAuthToken: false
    }
  }

  componentDidMount () {
    this._isMounted = true
    // check if a server is specified in the url
    const matchPathResult = this.props.navContext.actions.matchCurrentPath(ROUTES.ADMIN_STREAMHUB, false, true)
    if (matchPathResult && (matchPathResult.params as any).serverKey) {
      const serverKey = (matchPathResult.params as any).serverKey
      console.log('StreamhubServerProvider - componentDidMount - serverKey: ', serverKey)
      this.selectServerWithKey(serverKey)
      return
    }
    // if only a single streamhub server is available, auto select & load/show it
    const servers = this.getServers()
    if (servers.length === 1) {
      // this.selectServer(servers[0])
      this.props.navContext.actions.goto(ROUTES.ADMIN_STREAMHUB.replace(':serverKey', servers[0].key))
    }
  }

  componentWillUnmount () {
    this._isMounted = false
    console.log('StreamhubServerProvider - componentWillUnmount')
    // if a server with an active socket is currently running, disconnect & clean-up the socket
    if (this.state.socketClient) {
      this.state.socketClient.stopSocket()
    }
  }

  componentDidUpdate (prevProps: StreamhubServerProviderProps, _prevState: StreamhubServerProviderState) {
    if (this.props.navContext.store.currentPath !== prevProps.navContext.store.currentPath) {
      console.log('StreamhubServerProvider - componentDidUpdate - currentPath CHANGED FROM: ', prevProps.navContext.store.currentPath, ' TO: ', this.props.navContext.store.currentPath)
      console.log('StreamhubServerProvider - componentDidUpdate - this.state.currentServer: ', this.state.currentServer)

      // check if a server is specified in the url
      const matchPathResult = this.props.navContext.actions.matchCurrentPath(ROUTES.ADMIN_STREAMHUB, true, true)
      if (matchPathResult) {
        const serverKey = (matchPathResult.params as any)?.serverKey
        console.log('StreamhubServerProvider - componentDidMount - serverKey: ', serverKey)
        // this.selectServerWithKey(serverKey)
        if (!this.state.currentServer || this.state.currentServer.key !== serverKey) {
          if (serverKey) {
            this.selectServerWithKey(serverKey)
          } else {
            this.selectServer(undefined)
          }
        }
      }
    }
  }

  // -------

  getServers = () => this.props.servers

  getServerWithKey = (serverKey: string) => this.props.servers.find((server) => server.key === serverKey)

  getServerWithURL = (url: string) => this.props.servers.find((server) => server.url === url)

  // -------

  selectServer = (server?: StreamhubServer) => {
    if (server) {
      const apiClient = new StreamhubAPIClient(server.url, this.props.authToken, this.props.authDeviceUUID, this.props.serverContext.store.apiServerType)
      let socketClient: StreamhubSocketClient | undefined
      if (server.socketUrl) {
        console.log('StreamhubServerProvider - selectServer - server.socketUrl: ', server.socketUrl)
        socketClient = new StreamhubSocketClient(server.socketUrl, this.props.authToken, this.props.authDeviceUUID, this.props.serverContext.store.apiServerType)
        socketClient.startSocket()
        socketClient.onStatusChange = (socketStatus: StreamhubSocketStatus) => {
          console.log('StreamhubServerProvider - selectServer - socketClient - onStatusChange - socketStatus: ', socketStatus)
          if (this._isMounted) this.setState({ socketStatus })
        }
      }
      this.setState({ currentServer: server, apiClient, socketClient, socketStatus: socketClient?.socketStatus })
    } else {
      if (this.state.socketClient) {
        this.state.socketClient.stopSocket()
      }
      this.setState({ currentServer: undefined, apiClient: undefined, socketClient: undefined, socketStatus: undefined })
    }
  }

  selectServerWithKey = (serverKey: string) => {
    const server = this.getServerWithKey(serverKey)
    if (server) this.selectServer(server)
  }

  selectServerWithURL = (url: string) => {
    const server = this.getServerWithURL(url)
    if (server) this.selectServer(server)
  }

  updateServerAuthToken = async (_dbg: boolean) => {
    console.log('StreamhubServerProvider - updateServerAuthToken - _dbg:', _dbg)
    if (this.state.currentServer && this.state.apiClient) {
      const currentAuthToken = this.state.apiClient.authToken
      let newAuthToken = this.props.authContext.actions.getAuthToken()
      console.log('StreamhubServerProvider - updateServerAuthToken - currentAuthToken:', currentAuthToken, ' newAuthToken:', newAuthToken)
      // DEBUG ONLY: invalidate the current auth token so we can easily test how the APIClient & SockerClient handle the changes before/after
      if (_dbg) {
        console.log('StreamhubServerProvider - updateServerAuthToken - DEBUG...')
        const apiClient = this.state.apiClient
        const socketClient = this.state.socketClient
        newAuthToken = apiClient.authToken + '_' // DEBUG ONLY: invalidate the auth token
        apiClient.authToken = newAuthToken // DEBUG ONLY: invalidate the auth token
        if (socketClient) socketClient.updateAuthToken(newAuthToken) // DEBUG ONLY: invalidate the auth token
        this.setState({ apiClient, socketClient })
        return true
      }
      if (currentAuthToken === newAuthToken) {
        this.setState({ updatingServerAuthToken: true })
        // TESTING: if the auth token hasn't changed attempt a basic repro api call that would trigger an update if one is needed/available
        // TODO: not fully tested yet, just trialing this for now..
        // TODO: can/should we parse/decode the token & check if its likely expired before attempting the api call?
        if (currentAuthToken) {
          // WARNING: this is decoding without verifying the signature currently, so don't rely on it for anything critical (checking expiry timestamp should be fine, as no benefit to faking that, real api calls are needed & those will verify the tokens at that time)
          try {
            const decoedToken = parseJwt(currentAuthToken)
            const tokenExpires = new Date(decoedToken.exp * 1000)
            console.log('StreamhubServerProvider - updateServerAuthToken - decoedToken:', decoedToken, ' tokenExpires:', tokenExpires)
            // TODO: check if the decoded token has an expiry date past or near to the current time (server vs local time differences may also effect this?)
          } catch (error: any) {
            console.error('StreamhubServerProvider - updateServerAuthToken - parseJwt - error:', error)
            // NB: currently just allowing the following code to run if this step errors
          }
        }
        console.log('StreamhubServerProvider - updateServerAuthToken - AUTH TOKEN NO DIFF - CHECK IF API CALL TRIGGERS ONE...')
        // NB: this can cause a forced login prompt if the main auth has actually expired - do we want that to potentially happen automatically or should it more just fail the refresh & remain (that would mean extending the base api auth handling to support that though)
        await this.props.serverConfigContext.actions.loadServerConfig()
        newAuthToken = this.props.authContext.actions.getAuthToken()
        console.log('StreamhubServerProvider - updateServerAuthToken - currentAuthToken:', currentAuthToken, ' newAuthToken(AFTER):', newAuthToken)
      }
      if (currentAuthToken !== newAuthToken) {
        console.log('StreamhubServerProvider - updateServerAuthToken - AUTH TOKEN DIFFERENT - UPDATE - currentAuthToken:', currentAuthToken, ' newAuthToken:', newAuthToken)
        const apiClient = this.state.apiClient
        const socketClient = this.state.socketClient
        apiClient.authToken = newAuthToken ?? null
        if (socketClient) socketClient.updateAuthToken(newAuthToken ?? null)
        this.setState({ apiClient, socketClient, updatingServerAuthToken: false })
        return true
      // } else {
      //   await new Promise((resolve) => setTimeout(resolve, 2000)) // DEBUG ONLY
      }
    }
    if (this.state.updatingServerAuthToken) this.setState({ updatingServerAuthToken: false })
    return false
  }

  // -------

  actions: IStreamhubServerActions = {
    getServers: this.getServers,
    getServerWithURL: this.getServerWithURL,
    selectServer: this.selectServer,
    selectServerWithKey: this.selectServerWithKey,
    selectServerWithURL: this.selectServerWithURL,
    updateServerAuthToken: this.updateServerAuthToken
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: IStreamhubServerStore = {
  //   updatingServerAuthToken: this.state.updatingServerAuthToken
  // }

  render () {
    return (
      <StreamhubServerContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for IStreamhubServerStore */ }}
      >
        {this.props.children}
      </StreamhubServerContext.Provider>
    )
  }
}

const StreamhubServerProvider = withServerConfigContext(withNavContext(withServerContext(withAuthContext(StreamhubServerProviderBase))))
export { StreamhubServerProvider }
