/**
 * StreamhubStreamFilterForm
 */

import React from 'react'
import * as yup from 'yup'

import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkButton from 'src/core/components/ArkButton'
import ArkIcon from 'src/core/components/ArkIcon'

import styles from './StreamhubStreamFilterForm.module.css'

export type StreamhubStreamFilterFormOnFilterChange = (fieldKey: string, value?: string) => void

export interface StreamhubStreamFilterValues {
  name?: string
  url?: string
  server?: string
  org?: string
  project?: string
  program?: string
}

interface StreamhubStreamFilterFormProps {
  autoComplete?: boolean
  className?: string
  filterValues: StreamhubStreamFilterValues // NB: requiring the calling code to track & pass in the current filter values so we can support clearing it (from anywhere)
  formSchema?: yup.AnySchema // ObjectSchema
  onFilterChange?: StreamhubStreamFilterFormOnFilterChange
  onFilterClear?: Function
}

const StreamhubStreamFilterForm = (props: StreamhubStreamFilterFormProps) => {
  const { autoComplete, className, filterValues, formSchema } = props

  const onFilterChange = (_event: React.FormEvent<HTMLFormElement>, data: ArkFormProps) => {
    console.log('StreamhubStreamFilterForm - onFilterChange - data.name:', data.name, ' data.value:', data.value)
    if (props.onFilterChange) props.onFilterChange(data.name, (data.value && data.value.length > 0 ? data.value : undefined))
  }

  const onClearFilters = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, _data: object) => {
    console.log('StreamhubStreamFilterForm - onClearFilters')
    if (props.onFilterClear) props.onFilterClear()
  }

  const inputField = (key: string, value?: string, placeholder?: string) => {
    return {
      type: ArkFormFieldType.Input,
      key: key,
      value: value ?? '',
      placeholder: placeholder,
      fieldProps: {
        /* fluid: true, */
        width: 13,
        onChange: onFilterChange,
        autoComplete: autoComplete === false ? 'off' : undefined,
        icon: (
          <ArkButton
            className={styles.clearFilter}
            onClick={() => {
              console.log('onFilterClear')
              if (props.onFilterChange) props.onFilterChange(key, undefined)
            }}
          >
            <ArkIcon name='close' size={28} color='var(--bd-lighter)' />
          </ArkButton>
        )
      }
    }
  }

  const formFields: Array<ArkFormField> = []
  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'filters',
    fields: [
      { type: ArkFormFieldType.Field, key: 'searchIcon', content: (<ArkIcon name='search' />) },
      inputField('name', filterValues.name, 'name'),
      inputField('url', filterValues.url, 'url'),
      inputField('server', filterValues.server, 'server'),
      inputField('org', filterValues.org, 'org'),
      inputField('project', filterValues.project, 'project'),
      inputField('program', filterValues.program, 'program'),
      {
        type: ArkFormFieldType.Button,
        key: 'clearFilters',
        label: 'X',
        fieldProps: { onClick: onClearFilters, size: 'medium' }
      }
    ],
    slimline: true,
    fieldProps: { inline: true },
    className: styles.filters
  })

  // const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
  //   console.log('StreamhubStreamFilterForm - onFormSubmit - fieldValues: ', fieldValues)
  // }

  const renderForm = () => (
    <ArkForm
      className={styles.form + (className ? ' ' + className : '')}
      formKey="managerFilterForm"
      inverted
      // formError={error}
      formFields={formFields}
      formSchema={formSchema}
      // onFormSubmit={onFormSubmit}
      showLabels={true}
    />
  )

  return (
    renderForm()
  )
}

export default StreamhubStreamFilterForm
