import React, { Component } from 'react'

import { FFMpegProcess } from '../../models/StreamhubModels'
import { StreamhubProcessesContext, IStreamhubProcessesContext, StreamhubProcessDataStatus } from '../../providers/StreamhubProcessesProvider'

import ArkButton from 'src/core/components/ArkButton'
import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'

import { Header, Message, Table } from 'semantic-ui-react'

import styles from '../Streamhub.module.css'

interface IProps {
  onDataChanged?: Function
}
interface IState {
}

class StreamhubProcessesView extends Component<IProps & IStreamhubProcessesContext, IState> {
  private _isMounted: boolean = false

  constructor (props: IProps & IStreamhubProcessesContext) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.loadData()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    return this.renderView()
  }

  renderView () {
    return (
      <div className={styles.processes}>
        <div className={styles.contentHeader}>
          <div className={styles.contentHeaderMain}>
            <Header as='h2' inverted>Processes</Header>
            <div className={styles.actions}>
              <ArkButton color='blue' size='mini' className={styles.refreshBtn} onClick={async () => { this.onDataChanged() }}>REFRESH</ArkButton>
            </div>
          </div>
        </div>
        {this.renderFFMpegProcessesTable()}
        {this.props.store.processes && this.props.store.processes.length > 0 && (
          <>
            {/* <ArkButton inverted onClick={() => { this.killAllProcesses() }}>KILL ALL PROCESSES</ArkButton> */}
            <ArkManagerDeleteButton
              className={styles.buttonLeftPad}
              itemId={process.pid}
              itemName={'PROCESSES'}
              itemTypeName='ALL'
              buttonTitle='KILL ALL PROCESSES'
              deleteWord='kill'
              deletedWord='killed'
              onDelete={this.onKillAllProcesses}
              onDeleteComplete={this.onKillAllProcessesComplete}
              fluid={false}
              size='mini'
              // disabled={stream.isActive}
              style={{ display: 'inline' }}
              buttonStyle={{ fontSize: '12px', padding: '8px 10px', marginLeft: 6 }}
            />
          </>
        )}
      </div>
    )
  }

  // -------

  renderFFMpegProcessesTable = () => {
    const loading = this.props.store.processesStatus === StreamhubProcessDataStatus.loading
    if (loading) return <ArkLoaderView message='Loading' />
    if (this.props.store.processesError) {
      return (
        <Message negative>
          <Message.Header>Error</Message.Header>
          <p>{this.props.store.processesError.message ?? ''}</p>
        </Message>
      )
    }
    if (!this.props.store.processes || this.props.store.processes.length === 0) {
      return (
        <Message warning>
          <Message.Header>No Active Processes</Message.Header>
        </Message>
      )
    }
    return (
      <>
        <Table celled inverted className={styles.table}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>PID</Table.HeaderCell>
              <Table.HeaderCell>CPU</Table.HeaderCell>
              <Table.HeaderCell>CMD Data</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderFFMpegProcessesTableRows(this.props.store.processes)}
          </Table.Body>
        </Table>
      </>
    )
  }

  renderFFMpegProcessesTableRows = (processes: Array<FFMpegProcess>) => {
    return processes.map((process: FFMpegProcess) => {
      const commandData = process.getOuputData().commandData
      return (
        <Table.Row key={'process_' + process.pid}>
          <Table.Cell>{process.pid}</Table.Cell>
          <Table.Cell>{process.cpu ?? '-'}</Table.Cell>
          <Table.Cell>
            <div>
              {/* <Header as='h4' inverted>Process {process.pid}</Header> */}
              {/* <pre>{JSON.stringify(commandData, null, 4)}</pre> */}
              {Object.keys(commandData).map((key) => {
                // console.log('StreamhubProcessesView - renderFFMpegProcessesTableRows - key:', key, ' commandData:', commandData)
                const commandDataField = (commandData as any)[key]
                return (
                  <div key={'process_' + process.pid + '_' + key}>
                    <span className={styles.processTitle}>{key}:</span>
                    <span className={styles.processValue}>{commandDataField?.toString() ?? '-'}</span>
                  </div>
                )
              })}
              <div className={styles.processCmd}>
                <details>
                  <summary><span className={styles.processTitle}>full cmd:</span></summary>
                  <span className={styles.processCmdValue}>{process.command}</span>
                </details>
              </div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <ArkManagerDeleteButton
              className={styles.buttonLeftPad}
              itemId={process.pid}
              itemName={'Process ' + process.pid}
              itemTypeName='Process'
              buttonTitle='X'
              deleteWord='kill'
              deletedWord='killed'
              onDelete={this.onKillProcess}
              onDeleteComplete={this.onKillProcessComplete}
              fluid={false}
              size='mini'
              // disabled={stream.isActive}
              style={{ display: 'inline' }}
              buttonStyle={{ fontSize: '12px', padding: '8px 10px', marginLeft: 6 }}
            />
          </Table.Cell>
        </Table.Row>
      )
    })
  }

  // -------

  loadData = async () => {
    this.props.actions.fetchProcesses()
  }

  onDataChanged = () => {
    this.loadData()
    if (this.props.onDataChanged) this.props.onDataChanged()
  }

  // -------

  // ArkManagerDeleteButton onDelete callback, return true on success, or throw an error to have it displayed by the delete modal
  onKillProcess = async (processId: number) => {
    try {
      const stopResult = await this.props.actions.killProcess(processId)
      if (stopResult) {
        // TODO: reload relevant data...
        return true
      }
    } catch (error) {
      console.error('StreamhubProcessesView - onKillProcess - error: ', error)
      throw error // NB: re-throwing it so the calling `ArkManagerDeleteButton` handler can catch & show it in the confirm popup
    }
    return false
  }

  // ArkManagerDeleteButton onDeleteComplete callback, called once the success result has been dismissed by the user
  onKillProcessComplete = () => {
    this.onDataChanged()
  }

  // -------

  onKillAllProcesses = async () => {
    try {
      const stopResult = await this.props.actions.killAllProcesses()
      if (stopResult) {
        // TODO: reload relevant data...
        return true
      }
    } catch (error) {
      console.error('StreamhubProcessesView - onKillAllProcesses - error: ', error)
      throw error // NB: re-throwing it so the calling `ArkManagerDeleteButton` handler can catch & show it in the confirm popup
    }
    return false
  }

  // ArkManagerDeleteButton onDeleteComplete callback, called once the success result has been dismissed by the user
  onKillAllProcessesComplete = () => {
    this.onDataChanged()
  }
}

export class StreamhubProcessesViewWithContext extends Component<IProps, {}> {
  render () {
    return (
      <StreamhubProcessesContext.Consumer>
        {(processesContext) => {
          if (processesContext === null) {
            throw new Error('StreamhubProcessesContext must be used within a StreamhubProcessesProvider')
          }
          // console.log('StreamhubProcessesViewWithContext - render - StreamhubAssetsContext.Consumer')
          return (
            <StreamhubProcessesView
              {...this.props}
              {...processesContext}
            />
          )
        }}
      </StreamhubProcessesContext.Consumer>
    )
  }
}
export default StreamhubProcessesViewWithContext
export { StreamhubProcessesView }
