import React from 'react'
import {
  withAuthContext, IAuthMultiContext,
  withUserContext, IUserMultiContext
} from './'
import ServerAPIClient from '../services/ServerAPIClient'
import ServerAuthAPI from '../services/ServerAuthAPI'

export enum User2FAStatus {
  initial, loading, done, error
}

export interface IUser2FAStore {
}

export interface IUser2FAActions {
  generate2FA: (phoneNumber: string) => Promise<{ tfaToken: string, secretOTPAuthUrl: string, secretBase32: string, phoneNumber: string, phoneVerified: boolean, phoneVerifyCodeSent: boolean }>
  enable2FA: (otpCode: string) => Promise<boolean>
  disable2FAGenerateTFAToken: () => Promise<string | null>
  disable2FA: (otpCode: string, tfaDisableToken: string) => Promise<boolean>
  userPhoneNumber: () => string | undefined // helper to get the current User.phoneNumber value if its set
}

export interface IUser2FAContext {
  actions: IUser2FAActions;
  store: IUser2FAStore;
}

export interface IUser2FAMultiContext {
  user2FAContext: IUser2FAContext
}

export const User2FAContext = React.createContext<IUser2FAContext>({} as IUser2FAContext)

export interface User2FAProviderProps extends IAuthMultiContext, IUserMultiContext {
  apiClient: ServerAPIClient
  authApi: ServerAuthAPI
}
export interface User2FAProviderState extends IUser2FAStore {
}

class User2FAProvider extends React.Component<User2FAProviderProps, User2FAProviderState> {
  constructor (props: User2FAProviderProps) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
  }

  // -------

  generate2FA = async (phoneNumber: string) =>
    this.props.authApi.generate2FA(phoneNumber)

  enable2FA = async (otpCode: string) =>
    this.props.authApi.enable2FA(otpCode)

  disable2FAGenerateTFAToken = async () : Promise<string | null> =>
    this.props.authApi.disable2FAGenerateTFAToken()

  disable2FA = async (otpCode: string, tfaDisableToken: string) =>
    this.props.authApi.disable2FA(otpCode, tfaDisableToken)

  // -------

  userPhoneNumber = () : string | undefined =>
    this.props.userContext.store.user?.phoneNumber

  // -------

  actions: IUser2FAActions = {
    generate2FA: this.generate2FA,
    enable2FA: this.enable2FA,
    disable2FAGenerateTFAToken: this.disable2FAGenerateTFAToken,
    disable2FA: this.disable2FA,
    userPhoneNumber: this.userPhoneNumber
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: IUser2FAStore = {
  //  ...
  // }

  render () {
    return (
      <User2FAContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for IUser2FAStore */ }}
      >
        {this.props.children}
      </User2FAContext.Provider>
    )
  }
}

const withUser2FAContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withUser2FAContextHOC = (props: any) => (
    <User2FAContext.Consumer>
      {(user2FAContext) => {
        if (user2FAContext === null) {
          throw new Error('User2FAConsumer must be used within a User2FAProvider')
        }
        // console.log('withUser2FAContext - render - User2FAContext.Consumer - user2FAContext.store: ', user2FAContext.store)
        return (<Component {...props} {...{ user2FAContext: user2FAContext }} />)
      }}
    </User2FAContext.Consumer>
  )
  return withUser2FAContextHOC
}

const User2FAProviderWithContext = withAuthContext(withUserContext(User2FAProvider))

export { User2FAProviderWithContext as User2FAProvider }
export { withUser2FAContext }
