import React, { useContext, useEffect, useRef, useState } from 'react'
// import * as yup from 'yup'
import { object, string, /* AnySchema, */ number, bool, NumberSchema } from 'yup' // yup typed import usage - ref: https://stackoverflow.com/a/74792549
// import { OptionalObjectSchema } from 'yup/lib/object' // ref: https://stackoverflow.com/a/74792549

import { ProjectAdminContext, ServerContext, UserContext } from 'src/core/providers'
import { Program, Project, UserCompanyRole, UserProject } from 'src/core/models'
import { FFMpegSource, FFMpegStream, IFFMpegStreamProgramData } from '../../models/StreamhubModels'

import ArkButton from '../../../../../core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from '../../../../../core/components/ArkForm/ArkForm'

import { OBJECT_COMPANY_NAME, OBJECT_PROGRAM_NAME, OBJECT_PROJECT_NAME } from 'src/constants/strings'

import { Message, Header } from 'semantic-ui-react'

import styles from './StreamhubStreamForm.module.css'

export enum ArkTestStreamFormMode {
  Add = 'add',
  Edit = 'edit',
}

export enum ArkTestStreamOutputType {
  Program = 'program',
  Manual = 'manual',
}

// TESTING: base yup schema extended depending on use case - ref: https://stackoverflow.com/a/71308616
const formSchemaBase = object().shape({
  name: string().optional(),
  duration: number().typeError('Enter the Duration in seconds').optional(),
  source: number().typeError('Select a Stream Source').required(), // NB: using `number().typeError(<msg>)` to override the `..must be a number type, but the final value was NaN (cast from the value "")` default handling when this is a dropdown (required never triggers so we don't add a message to that)
  retryEnabled: bool().optional(),
  retryDelay: number().typeError('Enter the Retry Delay in seconds').optional(),
  retryMaxAttempts: number().typeError('Enter the Max Retry Attempts in seconds').optional()
})
// manual url specific fields
const formSchemaManualUrl = formSchemaBase.shape({
  url: string().required('Output URL is required')
})
// program url specific fields
const formSchemaProgramUrl = formSchemaBase.shape({
  url: string().optional(), // NB: make the url optional for program urls, as its auto filled & covered by other fields
  company: number().typeError('Select a Company').required(),
  // TESTING: required only if other field is set/selected - ref: https://www.antpace.com/blog/react-js-yup-only-require-an-input-if-another-is-not-empty/
  project: number().when('company', {
    is: (value: any) => value !== undefined && value > 0,
    then: (schema: NumberSchema) => schema.typeError('Select a Project').required()
  }),
  program: number().when('project', {
    is: (value: any) => value !== undefined && value > 0,
    then: (schema: NumberSchema) => schema.typeError('Select a Program').required()
  })
})

export type ArkFormSaveCallback = (data: {[key: string]: any}) => Promise<boolean>
export type StreamhubStreamFormSourceImgUrl = (source: FFMpegSource, width?: number) => string

interface IProps {
  mode: ArkTestStreamFormMode
  stream?: FFMpegStream
  streamSources: Array<FFMpegSource>
  onGetStreamSourcePreviewImageURL: StreamhubStreamFormSourceImgUrl // NB: instead of wrapping this in the source provider
  onCancel?: Function
  onSave?: ArkFormSaveCallback
  onDelete?: Function
  onClose?: Function
  error?: Error
}

const StreamhubStreamForm = (props: IProps) => {
  const { stream, streamSources, error } = props

  const { actions: userActions, store: userStore } = useContext(UserContext)
  const { selectCompany, deselectCurrentCompany } = userActions
  const { selectedCompany, selectedProject, userCompanies, userProjects, adminProjects } = userStore

  const { store: serverStore } = useContext(ServerContext)
  const { apiServerType } = serverStore

  const { actions: projectAdminActions } = useContext(ProjectAdminContext)

  const [isMounted, setIsMounted] = useState<boolean>(false)

  const [outputType, setOutputType] = useState<ArkTestStreamOutputType>(ArkTestStreamOutputType.Manual)

  const [streamURL, setStreamURL] = useState<string>(stream?.url ?? '')

  const [projectPrograms, setProjectPrograms] = useState<Array<Program> | undefined>()
  const [loadingPrograms, setLoadingPrograms] = useState<boolean>(false)
  const [loadingProgramsError, setLoadingProgramsError] = useState<Error | undefined>(undefined)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isSaved, setIsSaved] = useState<boolean>(false)
  // const [error, setError] = useState<Error | undefined>(undefined)

  const [server, setServer] = useState<string | undefined>(apiServerType)
  const [companyId, setCompanyId] = useState<number | undefined>(undefined) // NB: don't pre-set this, it gets auto selected to the current (site-wide) value if opening in add stream mode (instead of edit) // selectedCompany?.id)
  const [projectId, setProjectId] = useState<number | undefined>(undefined) // NB: don't pre-set this, so the user has to select it & so trigger the programs to load for it // selectedProject?.id)
  const [programId, setProgramId] = useState<number | undefined>(undefined)
  const [companyName, setCompanyName] = useState<string | undefined>(selectedCompany?.name)
  const [projectName, setProjectName] = useState<string | undefined>(selectedProject?.name)
  const [programName, setProgramName] = useState<string | undefined>(undefined)
  const [programDataNameChanges, setProgramDataNameChanges] = useState<Array<string> | undefined>(undefined) // names of fields that have changed since the last save

  const programDataFromCurrentServerRef = useRef<boolean>(true)

  // -------

  const loadPrograms = async (_companyId: number, _projectId: number, _isLoading: boolean = false) => {
    if (loadingPrograms) {
      console.log('StreamhubStreamForm - loadPrograms - ALREADY LOADING - HALT/IGNORE...')
      return false
    }
    try {
      setLoadingPrograms(true)
      setProjectPrograms(undefined)
      setLoadingProgramsError(undefined)
      await new Promise((resolve) => setTimeout(resolve, 500))
      // throw new Error('Test Error')
      const programs = await projectAdminActions.getAllCompanyProjectPrograms(_companyId, _projectId)
      console.log('StreamhubStreamForm - loadPrograms - isMounted:', isMounted, ' programs:', programs)
      if (isMounted || _isLoading) {
        setLoadingPrograms(false)
        setProjectPrograms(programs ?? undefined)
      }
      return programs ?? false // NB: also return the programs array so we can use it straight away in the `loadStreamProgramData` handling (instead of having to wait for the state to trigger an update & splitting its handling)
    } catch (error) {
      console.error('StreamhubStreamForm - loadPrograms - error: ', error)
      if (isMounted) {
        setLoadingPrograms(false)
        setLoadingProgramsError(error)
      }
    }
    return false
  }

  // -------

  // if editing a stream & it has `programData` set, load those values instead of using the current site-wide ones
  const loadStreamProgramData = async () => {
    console.log('StreamhubStreamForm - loadStreamProgramData - stream?.programData:', stream?.programData)

    // DONE: 1) open a non program based stream to edit it - don't have the (site-wide) company & project pre-selected? (but don't break/change the site-wide selections)
    // DONE: 2) open a new stream with a site-wide company & project selected, don't have the project field pre-selected otherwise it stops you loading the programs for it without multiple clicks on/off first (but don't break/change the site-wide selections)

    // adding a new stream, nothing to load - default to program url select mode
    if (!stream) {
      // console.log('StreamhubStreamForm - loadStreamProgramData - no stream (new/add) - setOutputType(Program)...')
      setOutputType(ArkTestStreamOutputType.Program)
      setCompanyId(selectedCompany?.id)
      return
    }
    // no program data set for this existing stream - flip to manual url input mode
    if (!stream.programData) {
      console.log('StreamhubStreamForm - loadStreamProgramData - edit stream - no programData - setOutputType(Manual)...')
      setOutputType(ArkTestStreamOutputType.Manual)
      return
    }
    const programData = stream.programData
    console.log('StreamhubStreamForm - loadStreamProgramData - programData:', programData)

    if (outputType !== ArkTestStreamOutputType.Program) setOutputType(ArkTestStreamOutputType.Program)

    setServer(programData.server)

    // handle if this stream was added from a different api server (the values/ids won't apply on this server)
    if (programData.server !== apiServerType) {
      console.log('StreamhubStreamForm - loadStreamProgramData - WARNING: STREAM PROGRAM DATA FROM DIFFERENT SERVER')
      // load the cached program details to display in a preview when on a different server (if the cache values were set, & they could be out-dated but at least hint to what company its for)
      setCompanyName(programData.companyName)
      setProjectName(programData.projectName)
      setProgramName(programData.programName)
      return
    }

    // local ref of the vars used below, as the `setX` state calls won't complete before we used them on the next stage, so we use these instead
    let _companyId = companyId
    let _projectId = projectId
    let _programId = programId

    const _programDataNameChanges:Array<string> = []

    // load the company if its not currently selected/loaded (site-wide)
    if (programData.companyId !== companyId) {
      console.log('StreamhubStreamForm - loadStreamProgramData - setCompanyId...')
      console.log('StreamhubStreamForm - loadStreamProgramData - selectCompany - START')
      _companyId = programData.companyId
      setCompanyId(programData.companyId)
      setCompanyName(programData.companyName)
      await selectCompany(programData.companyId, false)
      console.log('StreamhubStreamForm - loadStreamProgramData - selectCompany - DONE')
    } else {
      _companyId = programData.companyId
      setCompanyName(programData.companyName)
    }
    // TESTING: check if the company name has changed & update the local var if so (would still require a submit to update the server)
    const _company = userCompanies?.find(c => c.id === _companyId)
    console.log('StreamhubStreamForm - loadStreamProgramData - _companyId:', _companyId, ' _company:', _company)
    if (_company && _company.name !== programData.companyName) {
      console.log('StreamhubStreamForm - loadStreamProgramData - COMPANY NAME CHANGED SINCE LAST STREAM SAVE FROM: ', programData.companyName, ' TO: ', _company.name)
      setCompanyName(_company.name)
      _programDataNameChanges.push('company')
      setProgramDataNameChanges([..._programDataNameChanges]) // update with a copy straight away (so it shows while we wait for the program api to load)
    }

    if (_companyId && programData.projectId !== projectId) {
      console.log('StreamhubStreamForm - loadStreamProgramData - setProjectId...')
      _projectId = programData.projectId
      setProjectId(programData.projectId)
      setProjectName(programData.projectName)
    } else {
      _projectId = programData.projectId
      setProjectName(programData.projectName)
    }
    // TESTING: check if the project name has changed & update the local var if so (would still require a submit to update the server)
    const allProjects: Array<UserProject | Project> = [...(userProjects || []), ...(adminProjects || [])] // NB: may contain dupes as we don't filter them out when adding the admin projects, but thats fine for this usage
    const _project = allProjects?.find(p => p.id === _projectId)
    if (_project && _project.name !== programData.projectName) {
      console.log('StreamhubStreamForm - loadStreamProgramData - PROJECT NAME CHANGED SINCE LAST STREAM SAVE FROM: ', programData.projectName, ' TO: ', _project.name)
      setProjectName(_project.name)
      _programDataNameChanges.push('project')
      setProgramDataNameChanges([..._programDataNameChanges]) // update with a copy straight away (so it shows while we wait for the program api to load)
    }

    console.log('StreamhubStreamForm - loadStreamProgramData - loadPrograms - START')
    const _projectPrograms = await loadPrograms(_companyId, _projectId, true)
    console.log('StreamhubStreamForm - loadStreamProgramData - loadPrograms - DONE')

    if (_companyId && _projectId && programData.programId) { // === programId) {
      console.log('StreamhubStreamForm - loadStreamProgramData - setProgramId...')
      _programId = programData.programId
      setProgramId(programData.programId)
      setProgramName(programData.programName)
    } else {
      _programId = programData.programId
      setProgramName(programData.programName)
    }
    // TESTING: check if the program name has changed & update the local var if so (would still require a submit to update the server)
    const projectProgram = _projectPrograms ? _projectPrograms?.find(p => p.id === _programId) : undefined
    console.log('StreamhubStreamForm - loadStreamProgramData - _programId:', _programId, ' projectProgram:', projectProgram)
    if (projectProgram && projectProgram.name !== programData.programName) {
      console.log('StreamhubStreamForm - loadStreamProgramData - PROGRAM NAME CHANGED SINCE LAST STREAM SAVE FROM: ', programData.programName, ' TO: ', projectProgram.name)
      setProgramName(projectProgram.name)
      _programDataNameChanges.push('program')
      setProgramDataNameChanges(_programDataNameChanges)
    }

    if (_programDataNameChanges.length > 0) {
      setProgramDataNameChanges(_programDataNameChanges)
    }
  }

  // on component mount & unmount
  useEffect(() => {
    setIsMounted(true)
    loadStreamProgramData()
    return () => {
      setIsMounted(false)
    }
  }, [])

  // stream edit programData loading
  // useEffect(() => {
  // }, [])

  programDataFromCurrentServerRef.current = server === apiServerType // companyId !== undefined && // (outputType === ArkTestStreamOutputType.Program) &&
  // console.log('StreamhubStreamForm - companyId:', companyId, ' server:', server, ' programDataFromCurrentServerRef.current:', programDataFromCurrentServerRef.current)

  if (!streamSources || streamSources.length === 0) {
    return (<div>No Stream Sources Available. Add one before trying to create a stream.</div>)
  }

  const companyOptionEnabled = outputType === ArkTestStreamOutputType.Program && programDataFromCurrentServerRef.current
  const projectOptionEnabled = companyOptionEnabled && companyId !== undefined
  const programOptionEnabled = companyOptionEnabled && companyId !== undefined && projectOptionEnabled && projectId !== undefined // && programId !== undefined

  const manualOutputURLEnabled = outputType === ArkTestStreamOutputType.Manual

  const companyOptions: Array<{ key: string, text: string, value: number, description?: string }> = []
  const projectOptions: Array<{ key: string, text: string, value: number, description?: string }> = []
  const programOptions: Array<{ key: string, text: string, value: number, description?: string }> = []

  if (userCompanies) { // companyOptionEnabled &&
    for (const company of userCompanies) {
      companyOptions.push(
        {
          key: 'comp_' + company.id,
          text: company.name,
          value: company.id,
          description: (company.userCompanyRole === UserCompanyRole.admin ? 'admin' : undefined)
        }
      )
    }
    companyOptions.push({ key: 'comp_deselect', text: 'Deselect ' + OBJECT_COMPANY_NAME, value: 0 })
  }

  if ((userProjects || adminProjects)) { // projectOptionEnabled &&
    const allProjects: Array<UserProject | Project> = [...(userProjects || [])] // add all the user projects to the dropdown
    // admin only projects - if the user selected company has adminProjects set, the user is a company admin so add any additional projects not added from the userProjects list
    if (adminProjects) {
      for (const project of adminProjects) {
        const userProject = userProjects?.find((p) => p.id === project.id)
        if (!userProject) allProjects.push(project)
      }
    }
    for (const project of allProjects) {
      const projectName = project.name
      // if (projectName.trim().length > projectNameMaxChars) projectName = projectName.substring(0, projectNameMaxChars).trim() + '..'
      if (project instanceof UserProject) {
        // user (viewer access) project
        projectOptions.push({
          key: 'project_' + project.id,
          text: projectName,
          value: project.id,
          description: (!project.userAccessEnabled ? 'disabled' : undefined)
        })
      } else {
        // admin only project
        projectOptions.push({
          key: 'project_' + project.id,
          text: projectName,
          value: project.id,
          description: 'admin only'
        })
      }
    }
    projectOptions.push({ key: 'project_deselect', text: 'Deselect ' + OBJECT_PROJECT_NAME, value: 0 })
  }

  if (projectPrograms) { // programOptionEnabled &&
    for (const program of projectPrograms) {
      programOptions.push(
        {
          key: 'program_' + program.id,
          text: program.name,
          value: program.id,
          description: undefined
        }
      )
    }
    programOptions.push({ key: 'program_deselect', text: 'Deselect ' + OBJECT_PROGRAM_NAME, value: 0 })
  }

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    const { source, name, duration, retryEnabled, retryDelay, retryMaxAttempts } = fieldValues // url
    console.log('StreamhubStreamForm - onFormSubmit - source:', source, ' name:', name, ' duration:', duration, ' retryEnabled:', retryEnabled, ' retryDelay:', retryDelay, ' retryMaxAttempts:', retryMaxAttempts)
    if (props.onSave) {
      const sourceId = source && source !== '' ? parseInt(source) : undefined
      const durationInt = duration && duration !== '' ? parseInt(duration) : undefined
      const retryDelayInt = retryDelay && retryDelay !== '' ? parseInt(retryDelay) : undefined
      const retryMaxAttemptsInt = retryMaxAttempts && retryMaxAttempts !== '' ? parseInt(retryMaxAttempts) : undefined
      console.log('StreamhubStreamForm - onFormSubmit - sourceId:', sourceId, ' durationInt:', durationInt, ' retryDelayInt:', retryDelayInt, ' retryMaxAttemptsInt:', retryMaxAttemptsInt)
      let programData: IFFMpegStreamProgramData | undefined
      const _manualOutputURLEnabled = outputType === ArkTestStreamOutputType.Manual // don't submit any program data if in manual url mode (if the url isn't changed the old program data won't be removed api side)
      if (!_manualOutputURLEnabled && server !== undefined && companyId !== undefined && projectId !== undefined && programId !== undefined) {
        programData = { server, companyId, projectId, programId }
        if (companyId && companyName) programData.companyName = companyName
        if (projectId && projectName) programData.projectName = projectName
        if (programId && programName) programData.programName = programName
      }
      console.log('StreamhubStreamForm - onFormSubmit - programData:', programData)

      // NB: the calling code catches/handles the error & passes it back via the `error` prop to display within the form
      setIsSubmitting(true)
      // await new Promise((resolve) => setTimeout(resolve, 500))
      const saved = await props.onSave({
        sourceId,
        name,
        url: streamURL, // url, NB: now using the state var as the formValue can get out of sync since flipping to using `value` instead of just `defaultValue` for that form field (which was needed for other handling)
        duration: durationInt,
        retryEnabled: !!(retryEnabled),
        retryDelay: retryDelayInt,
        retryMaxAttempts: retryMaxAttemptsInt,
        programData
      })
      setIsSubmitting(false)
      if (isMounted) {
        setIsSaved((saved === true))
      }
    }
  }

  const onCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, _data: object) => {
    event.preventDefault()
    if (props.onClose) props.onClose()
  }

  const onFormValueChanged = async (fieldKey: string, _fieldValue: any, _oldFieldValue: any) => {
    console.log('StreamhubStreamForm - onFormValueChanged - fieldKey: ', fieldKey, ' _fieldValue: ', _fieldValue, ' _oldFieldValue: ', _oldFieldValue)
    if (fieldKey === 'outputType') {
      // NB: skipping the check against `_oldFieldValue` as the form field was sometimes getting out of sync with the default value & this was stopping it updating (since flipping to use `value` to power it directly instead of only `defaultValue`)
      if (_fieldValue === ArkTestStreamOutputType.Program) { // && _oldFieldValue !== ArkTestStreamOutputType.Program) {
        console.log('StreamhubStreamForm - onFormValueChanged - ArkTestStreamOutputType.Program...')
        setOutputType(ArkTestStreamOutputType.Program)
      } else if (_fieldValue === ArkTestStreamOutputType.Manual) { // && _oldFieldValue !== ArkTestStreamOutputType.Manual) {
        console.log('StreamhubStreamForm - onFormValueChanged - ArkTestStreamOutputType.Manual...')
        setOutputType(ArkTestStreamOutputType.Manual)
      }
      // else {
      //   console.log('StreamhubStreamForm - onFormValueChanged - NO CHANGE - _fieldValue:', _fieldValue, ' _oldFieldValue:', _oldFieldValue)
      // }
    } else if (fieldKey === 'company') {
      const _companyId = typeof _fieldValue === 'string' ? parseInt(_fieldValue) : _fieldValue as number
      console.log('StreamhubStreamForm - onFormValueChanged - _companyId:', _companyId)
      const prevCompanyId = companyId
      if (_companyId > 0) {
        setCompanyId(_companyId)
        const _companyName = companyOptions.find(cO => cO.value === _companyId)
        setCompanyName(_companyName?.text)
        selectCompany(_companyId, false)
      } else {
        setCompanyId(undefined)
        setCompanyName(undefined)
        deselectCurrentCompany()
      }
      setStreamURL('') // reset the url whenever the company changes
      // if the company changed clear the project & program values as they no longer apply
      if (prevCompanyId !== _companyId) {
        setProjectId(undefined)
        setProjectName(undefined)
        setProgramId(undefined)
        setProgramName(undefined)
        setProjectPrograms(undefined)
      }
      // if the field is flagged as having a name change un-flag it after its changed
      if (programDataNameChanges && programDataNameChanges.includes('company')) {
        const _programDataNameChanges = programDataNameChanges.filter(p => p !== 'company')
        setProgramDataNameChanges(_programDataNameChanges)
      }
    } else if (fieldKey === 'project') {
      const _projectId = typeof _fieldValue === 'string' ? parseInt(_fieldValue) : _fieldValue as number
      console.log('StreamhubStreamForm - onFormValueChanged - _projectId:', _projectId)
      const prevProjectId = projectId
      if (_projectId > 0) {
        setProjectId(_projectId)
        const _projectName = projectOptions.find(pO => pO.value === _projectId)
        setProjectName(_projectName?.text)
      } else {
        setProjectId(undefined)
        setProjectName(undefined)
      }
      setStreamURL('') // reset the url whenever the project changes
      // if the project changed clear the program value as it no longer applies & trigger the relevant project programs to load
      if (prevProjectId !== _projectId) {
        setProgramId(undefined)
        setProgramName(undefined)
        if (companyId && _projectId) {
          await loadPrograms(companyId, _projectId)
        } else {
          setProjectPrograms(undefined)
        }
      }
      // if the field is flagged as having a name change un-flag it after its changed
      if (programDataNameChanges && programDataNameChanges.includes('project')) {
        const _programDataNameChanges = programDataNameChanges.filter(p => p !== 'project')
        setProgramDataNameChanges(_programDataNameChanges)
      }
    } else if (fieldKey === 'program') {
      const _programId = typeof _fieldValue === 'string' ? parseInt(_fieldValue) : _fieldValue as number
      console.log('StreamhubStreamForm - onFormValueChanged - _programId:', _programId)
      if (_programId > 0) {
        setProgramId(_programId)
        const projectProgram = projectPrograms?.find(p => p.id === _programId)
        setProgramName(projectProgram?.name)
        const srtInputData = projectProgram?.getSRTInputData()
        setStreamURL(srtInputData?.fullURL ?? '')
      } else {
        setProgramId(undefined)
        setProgramName(undefined)
        setStreamURL('')
      }
      // if the field is flagged as having a name change un-flag it after its changed
      if (programDataNameChanges && programDataNameChanges.includes('program')) {
        const _programDataNameChanges = programDataNameChanges.filter(p => p !== 'program')
        setProgramDataNameChanges(_programDataNameChanges)
      }
    } else if (fieldKey === 'url') {
      setStreamURL(_fieldValue)
    }
  }

  const onResetProgram = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, _data: object) => {
    console.log('StreamhubStreamForm - onResetProgram')
    setServer(apiServerType)
    setCompanyId(undefined)
    setProjectId(undefined)
    setProgramId(undefined)
    setCompanyName(undefined)
    setProjectName(undefined)
    setProgramName(undefined)
    setStreamURL('')
    programDataFromCurrentServerRef.current = true
  }

  // -------

  // const outputModeDefault = ArkTestStreamOutputType.Program
  const outputModeOptions: Array<{}> = []
  outputModeOptions.push({ key: 'stream_output_type_select', text: 'Program URL', value: ArkTestStreamOutputType.Program })
  outputModeOptions.push({ key: 'stream_output_type_manual', text: 'Manual URL', value: ArkTestStreamOutputType.Manual })

  const streamSourceOptions: Array<{}> = []
  // [{key: 'test', text: 'Test', value: 'test'}]
  for (const streamSource of streamSources) {
    streamSourceOptions.push({
      key: 'source_' + streamSource.id,
      text: 'Source ' + streamSource.id,
      value: '' + streamSource.id,
      image: { avatar: false, src: props.onGetStreamSourcePreviewImageURL(streamSource, 100) }
    })
  }

  const sourceId = stream?.sourceId ? '' + stream?.sourceId : undefined // ''
  const name = stream?.name ?? ''
  // const url = stream?.url ?? ''
  const duration = stream?.duration ?? undefined
  const retryEnabled = stream?.retryEnabled ?? false
  const retryDelay = stream?.retryDelay
  const retryMaxAttempts = stream?.retryMaxAttempts

  const showCurrentServerWarning = !programDataFromCurrentServerRef.current
  // console.log('StreamhubStreamForm - programDataFromCurrentServerRef.current:', programDataFromCurrentServerRef.current, ' showCurrentServerWarning:', showCurrentServerWarning)
  // console.log('StreamhubStreamForm - outputType:', outputType)

  const programDataNameChangedMsg = 'Name has changed since last save. Re-save this form to update it on streamhub.'

  const formFields: Array<ArkFormField> = []
  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'detailsFieldset',
    // label: '',
    fields: [
      { type: ArkFormFieldType.Input, key: 'name', label: 'Name/Alias', required: false, defaultValue: name, fieldProps: {} },
      { type: ArkFormFieldType.Field, key: 'divider1', content: (<hr className={styles.divider} />) },
      {
        type: ArkFormFieldType.Dropdown,
        key: 'outputType',
        label: 'Stream Output Type',
        // required: true,
        // defaultValue: outputModeDefault,
        value: outputType,
        fieldProps: {
          options: outputModeOptions,
          scrolling: true // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
        },
        resetAllFieldErrorOnChange: true // TESTING: clear any existing form field errors when flipping output type
      },
      {
        type: ArkFormFieldType.Fieldset,
        key: 'streamOutputTypeSelectFieldset',
        label: 'Program Output URL',
        className: (outputType !== ArkTestStreamOutputType.Program ? styles.programSelectDisabled : undefined),
        fields: [
          ...(showCurrentServerWarning
            ? [
              {
                type: ArkFormFieldType.Field,
                key: 'serverWarningLabel',
                // wrapperProps: { className: styles.flagsWarningField },
                content: (
                  (<div>
                    <div className={styles.serverWarning}>
                      <h3>WARNING:</h3>
                      <p>This stream was created for a program on a different api server.</p>
                      <p>The program used doesn&apos;t exist on the current <span className={styles.serverName}>{apiServerType}</span> server you&apos;re logged into.</p>
                      <p>So its details can&apos;t be looked up to edit from this server.</p>
                      <p>You can either:</p>
                      <ul>
                        <li>Login to the <span className={styles.serverName}>{server}</span> server to edit it directly for the original company/project.</li>
                        <li>Flip to manual url input mode (above) to edit the url directly (without any program details).</li>
                        <li>Or choose to convert the stream to use data from this <span className={styles.serverName}>{apiServerType}</span> server instead <br />(&amp; overwrite the previous program details), using the <strong>&apos;reset program&apos;</strong> button below.</li>
                      </ul>
                    </div>
                  </div>))
              },
              {
                type: ArkFormFieldType.Field,
                key: 'summaryLabel',
                // wrapperProps: { className: styles.flagsWarningField },
                content: (
                  (<div className='field'>
                    <div className={styles.summary}>
                      <div className={styles.field}><label>Server:</label><span className={styles.val}>{server}</span></div>
                      <div className={styles.field}><label>Company:</label><span className={styles.val}>{companyName ?? '-'}</span></div>
                      <div className={styles.field}><label>Project:</label><span className={styles.val}>{projectName ?? '-'}</span></div>
                      <div className={styles.field}><label>Program:</label><span className={styles.val}>{programName ?? '-'}</span></div>
                    </div>
                  </div>))
              },
              {
                type: ArkFormFieldType.Button,
                key: 'resetProgram',
                label: 'RESET PROGRAM',
                fieldProps: { onClick: onResetProgram }
              }
            ]
            : []),
          ...(!showCurrentServerWarning
            ? [
              ...(!manualOutputURLEnabled
                ? [
                  {
                    type: ArkFormFieldType.Field,
                    key: 'siteWideselectionNotice',
                    wrapperProps: { className: styles.siteWideselectionNotice },
                    content: (
                      (<div>
                        <span className={styles.title}>Please Note:</span>
                        <p>Changing the company or project dropdowns below also changes the site-wide selections for them.</p>
                        <p>When you navigate away from this admin section, please double check which company and project you&apos;re viewing!</p>
                      </div>))
                  }
                ]
                : []
              ),
              {
                type: ArkFormFieldType.Field,
                key: 'serverLabel',
                wrapperProps: { className: styles.serverField },
                content: (
                  (<div className={'field' + ' ' + styles.serverLabel}>
                    <label>Server:</label>
                    <span>{server}</span>
                  </div>))
              },
              {
                type: ArkFormFieldType.Dropdown,
                key: 'company',
                label: 'Company',
                required: !manualOutputURLEnabled, // required when in program select output type/mode
                // defaultValue: companyId,
                value: companyId, // NB: we now handling this field manually
                disabled: !companyOptionEnabled,
                fieldProps: {
                  options: companyOptions,
                  scrolling: true // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
                },
                description: (programDataNameChanges && programDataNameChanges.includes('company')
                  ? <span className={styles.warning}>{programDataNameChangedMsg}</span>
                  : undefined),
                placeholder: 'Select a Company'
              },
              {
                type: ArkFormFieldType.Dropdown,
                key: 'project',
                label: 'Project',
                required: !manualOutputURLEnabled, // required when in program select output type/mode
                // defaultValue: projectId,
                value: projectId, // NB: we now handling this field manually
                disabled: !projectOptionEnabled,
                fieldProps: {
                  options: projectOptions,
                  scrolling: true // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
                },
                description: (programDataNameChanges && programDataNameChanges.includes('project')
                  ? <span className={styles.warning}>{programDataNameChangedMsg}</span>
                  : undefined),
                placeholder: 'Select a Project'
              },
              {
                type: ArkFormFieldType.Dropdown,
                key: 'program',
                label: 'Program',
                required: !manualOutputURLEnabled, // required when in program select output type/mode
                // defaultValue: programId,
                value: programId, // NB: we now handling this field manually
                disabled: !programOptionEnabled && !loadingPrograms,
                placeholder: (loadingPrograms ? 'Loading...' : 'Select a Program'),
                fieldProps: {
                  options: programOptions,
                  loading: loadingPrograms,
                  scrolling: true // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
                },
                description: (programDataNameChanges && programDataNameChanges.includes('program')
                  ? <span className={styles.warning}>{programDataNameChangedMsg}</span>
                  : undefined)
              },
              ...(loadingProgramsError
                ? [
                  {
                    type: ArkFormFieldType.Field,
                    key: 'serverLabel',
                    content: (
                      <>
                        <Message negative>
                          <Message.Header>Error</Message.Header>
                          <Message.Item>{loadingProgramsError.message}</Message.Item>
                        </Message>
                      </>
                    )
                  }
                ]
                : []
              )
            ]
            : [])
          // TODO: check & show the `loadingProgramsError` if its set <<<<<<<<<<<<<
        ],
        // fieldProps: { widths: 2, inline: false, grouped: false },
        collapsible: false,
        collapsed: false
      },
      {
        type: ArkFormFieldType.Fieldset,
        key: 'streamOutputTypeManualFieldset',
        label: 'Manual Output URL',
        fields: [
          {
            type: ArkFormFieldType.Input,
            key: 'url',
            label: 'Manual Output URL (SRT)',
            required: true,
            // defaultValue: url,
            value: streamURL, // NB: we now handling this field manually, so we can set it if/when a program is selected instead of only from manual entry
            disabled: !manualOutputURLEnabled,
            fieldProps: {}
          }
        ],
        // fieldProps: { widths: 2, inline: false, grouped: false },
        collapsible: false,
        collapsed: false
      },
      { type: ArkFormFieldType.Field, key: 'divider2', content: (<hr className={styles.divider} />) },
      { type: ArkFormFieldType.Input, key: 'duration', label: 'Duration (time limit in seconds)', required: false, defaultValue: duration, fieldProps: {} },
      {
        type: ArkFormFieldType.Dropdown,
        key: 'source',
        label: 'Stream Source',
        required: true,
        defaultValue: sourceId,
        fieldProps: {
          options: streamSourceOptions, // TODO: use the ArkFormField `options` instead?
          scrolling: true // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
        },
        placeholder: 'Select a Source'
      }
    ],
    // fieldProps: { widths: 2, inline: false, grouped: false },
    collapsible: false,
    collapsed: false
  })

  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'retryOptionsFieldset',
    label: 'Retry options',
    fields: [
      { type: ArkFormFieldType.Checkbox, key: 'retryEnabled', label: 'Retry Enabled', required: false, defaultValue: retryEnabled },
      {
        type: ArkFormFieldType.Group,
        key: 'retryOptionsGroup',
        fields: [
          { type: ArkFormFieldType.Input, key: 'retryDelay', label: 'Retry Delay (Seconds)', required: false, defaultValue: retryDelay },
          { type: ArkFormFieldType.Input, key: 'retryMaxAttempts', label: 'Max Retry Attempts', required: false, defaultValue: retryMaxAttempts }
        ] //,
        // fieldProps: { widths: 'equal' }
      }
    ],
    // fieldProps: { widths: 2, inline: false, grouped: false },
    collapsible: true,
    collapsed: false
  })

  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { loading: isSubmitting, onClick: onCancel, floated: 'left' } },
      { type: ArkFormFieldType.OKButton, key: 'submit', label: (props.mode === ArkTestStreamFormMode.Edit ? 'SAVE' : 'CREATE'), fieldProps: { loading: isSubmitting, floated: 'right' } }
    ],
    fieldProps: { widths: 'equal' }
  })

  return (
    <>
      <Header as="h2" inverted>
        {(props.mode === ArkTestStreamFormMode.Edit ? 'Edit' : 'Add') + ' Test Stream'}
      </Header>

      {isSaved && (<>
        <Message positive>
          <Message.Header>Stream {props.mode === ArkTestStreamFormMode.Edit ? 'Updated' : 'Created'}</Message.Header>
          <Message.Item>The stream has been {props.mode === ArkTestStreamFormMode.Edit ? 'updated' : 'created'} successfully</Message.Item>
        </Message>
        {stream?.isActive === true && (
          <Message warning>
            <Message.Header>Live Stream</Message.Header>
            <Message.Item>The stream is currently live. Please stop &amp; restart the stream for your changes to be used in the stream output.</Message.Item>
          </Message>
        )}
        <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={onCancel} style={{ marginBottom: '20px' }}>
          OK
        </ArkButton>
      </>)}

      {!isSaved && (<>
        {stream?.isActive === true && (
          <Message warning>
            <Message.Header>Live Stream</Message.Header>
            <Message.Item>The stream is currently live. Please stop &amp; restart the stream if you make any changes here.</Message.Item>
          </Message>
        )}
        <ArkForm
          formKey="stream"
          className="test-stream-form"
          inverted
          // header={(this.props.mode === ArkTestStreamFormMode.Edit ? 'Edit' : 'Add') + ' Test Stream'}
          formError={error}
          formFields={formFields}
          formSchema={outputType === ArkTestStreamOutputType.Manual ? formSchemaManualUrl : formSchemaProgramUrl}
          updateFieldValuesOnExternalChange={true} // TESTING: allow field value changes applied at this level to be tracked/applied within the ArkForm values
          onFormSubmit={onFormSubmit}
          onValueChanged={onFormValueChanged}
          insideModal={true}
        ></ArkForm>
      </>)}
    </>
  )
}

export default StreamhubStreamForm
