/**
 * types
 */

import { ArkAvatarType } from 'src/core/components/ArkAvatar'

/**
 * data
 */

export type NodeGraphChannel = {
  id: number
  colour?: string
  name: string
  programs: number[]
}

export type NodeGraphGroup = {
  id: number
  channels: number[]
  disabled: boolean
  mirrored: boolean
  name: string
  users: number[]
}

export type NodeGraphProgram = {
  id: number
  colour?: string
  name: string
  online: 'online' | 'offline'
  shortName?: string
}

export type NodeGraphUser = {
  id: number
  avatarType: ArkAvatarType
  guest: boolean
  name: string
}

export type NodeGraphData = {
  channels: NodeGraphChannel[]
  groups: NodeGraphGroup[]
  programs: NodeGraphProgram[]
  users: NodeGraphUser[]
}

/**
 * components
 */

export enum EdgeHealth {
  Undefined,
  Green,
  Amber,
  Red
}

export enum NodeIdPrefix {
  Channel = 'channel-',
  Group = 'group-',
  Program = 'program-',
  User = 'user-'
}

export enum NodeSection {
  Channels = 'channels',
  Groups = 'groups',
  Programs = 'programs',
  Users = 'users'
}

export enum NodeType {
  Channel = 'channel',
  Empty = 'empty',
  Group = 'group',
  Program = 'program',
  User = 'user'
}

export type NodeData = {
  blurred?: boolean
  focused?: boolean
  section: NodeSection
  selected?: boolean
}

export interface NodeDataChannel extends NodeData {
  colour?: string
  name: string
  numberOfOfflinePrograms: number
  numberOfPrograms: number
  onViewClick: () => void
}

export interface NodeDataEmpty extends NodeData {
  numberHidden: number
  onHiddenClick: () => void
  title: string
}

export interface NodeDataGroup extends NodeData {
  disabled: boolean
  mirrored: boolean
  name: string
  numberOfChannels: number
  numberOfPrograms: number
  numberOfUsers: number
  onViewClick: () => void
}

export interface NodeDataProgram extends NodeData {
  colour?: string
  name: string
  online: 'online' | 'offline'
  onViewClick: () => void
  shortName?: string
}

export interface NodeDataUser extends NodeData {
  avatarType: ArkAvatarType,
  guest: boolean
  name: string
  onViewClick: () => void
}
