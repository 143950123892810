import React from 'react'
import * as yup from 'yup'

import { Form, FormProps, Header, Message, Grid, DropdownProps, SemanticCOLORS, Accordion, Icon, FormRadioProps, CheckboxProps, FormComponent } from 'semantic-ui-react'
// import { FormField, FormButton, FormCheckbox, FormDropdown, FormGroup, FormInput, FormRadio, FormSelect, FormTextArea } from 'semantic-ui-react'

import ArkFormColourField, { ArkFormColourFieldResult } from './ArkFormColourField'
import ArkFormPhoneNoField, { ArkFormPhoneNoFieldResult } from './ArkFormPhoneNoField'
import ArkFormNumberInputField, { ArkFormNumberInputFieldResult } from './ArkFormNumberInputField'

import styles from './ArkForm.module.css'

export type { CheckboxProps }

// ArkForm
// created a custom wrapper component around the SUI Form component to try & reduce alot of the repeated boiler-plate state & handling code
// it adds additional props that power some common features used in nearly all our form usage, & gives us a central location to set default styling
// & now also offers some more dynamic field handling features to further reduce the amount of boiler-plate code needed on most of our forms

// BASIC USAGE:
// replace SUI <Form...></Form> usage with <ArkForm...></ArkForm> & it should act exactly the same as a regular SUI Form by default
// & can mainly be used like this to standardise some of the styling & basic common form UI we're using on most forms in this web-app
// custom props add additional helper features, like header to show a title, formError to display an error response etc.

// ADVANCED USAGE:
// formFields
//  you can now optionally construct the form programatically by supplying an array of ArkFormField objects's via the formFields prop
//  this allows you to describe each element in the form & have it render, track state & return the response with minimal code
//  doing this means you don't have to supply any child form field components to ArkForm manually & it can instead auto layout the form in a basic way
// formSchema
//  you can also optionally supply a formSchema prop made up of the yup validation object we use in normal forms
//  doing this will let the form perform the yum validation as part of its own submit handling & show the errors in-line within the form
//  if you omit this, its up to you to perform any extra validation steps in the onFormSubmit callback
//  NB: you must use the ArkForm specific onFormSubmit callback for validation to be auto performed
// onFormSubmit
//  you can supply the normal SUI Form onSubmit callback & manually process the form (where you also have to track the state/value of each field manually)
//  but if you instead use the ArkForm custom onFormSubmit callback alongside the formFields & optionally formSchema fields
//  then ArkForm will handle all field state/value tracking & field validations checks, show errors inline if any or return the result if all is ok

// ADVANCED LAYOUT:
// if you specify the form dynamically using the formFields prop, by default it will auto layout the form in a basic way
// there are 2 ways to customise the formFields based form usage:
// auto-layout
//  when no children components are added within the <ArkForm></ArkForm> tags & just formFields is used, auto-layout kicks in
//  ArkFormAutoLayoutType.none
//    by default it will use ArkFormAutoLayoutType.none which just renders each form element directly with no extra wrapping elements per field
//    & that maybe enough when first mocking up & prototyping a new form, depending on the field settings use it will usually display all fields in a column
//  ArkFormAutoLayoutType.grid
//    if you instead set to the autoLayoutType prop to ArkFormAutoLayoutType.grid, it'll use the SUI Grid to wrap the elements
//    a 2nd prop autoLayoutDir can be used to configure the grid in a very basic way, switching from a vertical to horizontal layout
//    autoLayoutDir: ArkFormAutoLayoutDirection.vertical / ArkFormAutoLayoutDirection.horizontal
//    NB: we could extend the auto-layout handling with more features, but its mostly aimed for use in basic or prototype forms
// manual-layout
//  you can also choose to layout the dynamically declared formFields manually instead of using the basic auto-layout support
//  to do this you still list your formFields as you do in the auto-layout way
//  you instead must use special a ArkFormFieldPlaceholder component to represent each field & link them via the fieldKey value
//  to do this, add any child components you want within the <ArkForm></ArkForm> tags, & just make sure to add a ArkFormFieldPlaceholder for each field
//  this allows you to have near total control over the layout of the ArkForm while still tapping into the dynamic formField handling
//  e.g: a form with an email input & button laid out manually would look something like:
//    render() {
//      const formFields = new Array<ArkFormField>()
//      formFields.push({ type: ArkFormFieldType.Input, key: "email", label: "Email Address", required: true })
//      formFields.push({ type: ArkFormFieldType.Button, key: "submit", label: "Continue", fieldProps: { loading: isSubmitting }})
//      return (
//        <ArkForm formKey="emailLookup" className="email-lookup-form" formFields={formFields} formSchema={formSchema} onFormSubmit={this.onFormSubmit}>
//          <ArkFormFieldPlaceholder fieldKey="email" />
//          <div className="test-wrapper">
//            <ArkFormFieldPlaceholder fieldKey="submit" />
//          </div>
//        </ArkForm>
//      )
//    }

// TODOs:
// TODO: implement support & rendering of all the Form element types (each item listed in the ArkFormFieldType enum)
// TODO: add more default field styling options, perhaps with some custom config (so you can set certain styling with a single or a few props, instead of manually setting colors etc.)
// TODO: support the parent supplying a success message to shown within the form, similar to the form wide/level error message display?

// all the SUI Form element types (plus any of our own custom field types)
export enum ArkFormFieldType {
  // SUI fields:
  Field, Button, Checkbox, Dropdown, Group, Input, Radio, Select, TextArea,
  // Custom Ark fields:
  Fieldset, Colour, PhoneNo, NumberInput, OKButton, CancelButton, DeleteButton, FormErrorPlaceholder
}

// NB: matching this to the SUI Form .Dropdown options, & re-using it in our own radio options parsing
export interface ArkFormFieldOption {
  key: string,
  text?: string | React.ReactNode, // NB: `text` OR `children` must be specified, but NOT both (NB: extended to add `React.ReactNode` type here as well, as the SUI Dropdown allows it)
  value?: string | number,
  description?: string | React.ReactNode,
  className?: string
  selectedText?: string, // optional alternative text to show in place of the normal text value when this value is selected
  // TESTING: custom content elements instead of using the text/selectedText string values
  // NB: ONLY currently supported/used in the .Dropdown field
  // NB: if using this, set the options top level element (e.g. div/span) with a `className='text'` to get the correct text colour (or apply your own styling to get it to match)
  children?: React.ReactNode // TODO: is this needed if the underlying `text` prop now also supports ReactNode? (maybe this can still be used to ignore description & certain other props vs using the `text` prop? (although the calling code could just omit them if thats the case?))
  disabled?: boolean
}

export interface ArkFormField {
  type: ArkFormFieldType
  key: string
  label?: React.ReactNode | string // title label shown above most fields (NB: if passing in as a ReactNode you should manually wrap it in `<label>...</label>` as the SUI code doesn't & it break/hide certain fields without like checkboxes/radios/toggles)
  // TODO: consider adding a way to flip the label into a custom rendering mode, don't use the SUI fields label prop & instead render it custom via `renderFormFieldExtras`,
  // TODO: ..which would alow it to render above the hint column, & so not be cropped/shorted by the hint col/element (if the title label is longer than the field but shorter than the field+hint width)
  // TODO: ..should also support the default SUI label 'required' indicator & any similar features we might make use of with it
  placeholder?: string // inline label placeholder text (e.g. shown within an input text field)
  description?: React.ReactNode | string // custom optional field description (extra info) to show under a field (SUI Form doesn't support it natively, so we add our own handling for it)
  defaultValue?: string | number | boolean
  value?: string | number | boolean
  required?: boolean
  disabled?: boolean
  disabledTooltip?: string // ArkFromFieldType.Button only currently (might be able to add to others if needed?)
  className?: string // NB: slowly adding support for this to more fields (useful if you need to style specific elements different to others) - TODO: add support to all/most field types! (only added to fieldsets so far)
  fieldProps?: { [key: string]: any } // NB: if adding a hint also see hintProps & wrapperProps
  fields?: Array<ArkFormField> // ArkFormFieldType.Group only
  collapsible?: boolean // ArkFormFieldType.Group only
  collapsed?: boolean // ArkFormFieldType.Group only
  options?: Array<ArkFormFieldOption> // ArkFormFieldType.Radio, .Dropdown, & .Select only
  toggle?: boolean // ArkFormFieldType.Radio only
  icon?: any // TODO: add this to all field types that support it
  content?: React.ReactNode // ArkFormFieldType.Field
  slimline?: boolean // .Group & .Fieldset only currently (but could add to others), removes excess padding/margin (useful when within certain other fields or components)
  bordered?: boolean // .Fieldset only defaults to true if not specified
  hint?: React.ReactNode | string // all input fields (once implemented for each) - optionally add one of our ArkHint elements to the right of the field (or a custom component that does something similar)
  hintProps?: { [key: string]: any } // added to the direct wrapper element of the hint (if one is added)
  hintInline?: boolean // set to false to stop the wrapped field from expanding to fill its space (equiv to fluid=false in some SUI elements)
  wrapperProps?: { [key: string]: any } // added to the outer wrapper element of the both the field & hint wrappers, if a hint is added, or if no hint is set it gets added alongside the normal fieldProps
  resetAllFieldErrorOnChange?: boolean // TESTING: currently only tested with dropdowns, for use when they change a forms mode/use-case & its schema which in turn means some errors might be invalid so we should clear them all (& it'll be revalidated on next submit trigger)
}

// auto layout
export enum ArkFormAutoLayoutType {
  none, grid
}
export enum ArkFormAutoLayoutDirection {
  vertical, horizontal
}

// extends the default Form onSubmit callback, passing back the fieldValues along with the original callback params
export type ArkFormSubmitCallback = (fieldValues: ArkFormFieldValues, event: React.FormEvent<HTMLFormElement>, data: ArkFormProps) => void
export type ArkFormValueChangedCallback = (fieldKey: string, fieldValue: any, oldFieldValue: any) => void

export type ArkFormEnterKeyShouldSubmitCallback = () => boolean // optional callback to dynamically enable/disable the enter key submit handling at runtime (also requires `enterKeySubmits` to be true as well)

// custom error object that allows for multiple error messages (so we can show a list of errors instead of only a single one, as Error messages are only strings we can't easily support formatting)
export class ArkFormMultiError extends Error {
  public messages: Array<string>
  constructor (messages: Array<string>) {
    super('') // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
    this.messages = messages
  }

  // fallback to get all messages as a basic string, so it can be used with normal `Error` handling
  public get message (): string {
    console.log('ArkFormMultiError - get message - this.messages:', this.messages)
    return this.messages.join(', ')
  }
}

// form state & error tracking
export interface ArkFormFieldValues {
  [key: string]: any // key value pairs for all field values (key is the field name)
}
export interface ArkFormFieldErrors {
  [key: string]: any // validation error messages for each field (key is the field name)
}

export class ArkFormFieldPlaceholder extends React.Component<{ fieldKey: string }, {}> {
  render () { return (<div className="ark-form-field-placeholder">FIELD PLACEHOLDER: {this.props.fieldKey}</div>) }
}

// NB: if adding new props, make sure the <Form {...props}> usage removes them or you'll get errors about unknown props
export interface ArkFormProps extends FormProps {

  formKey: string // unique key to id this form against others on the same page (NB: now required to help with the custom enter key press handling)

  children?: React.ReactNode
  header?: React.ReactNode
  formError?: Error | ArkFormMultiError

  formFields?: Array<ArkFormField>
  formSchema?: yup.AnySchema // | yup.Lazy<any> // ObjectSchema

  // TESTING: default: false, when enabled any prop field value changes detected will be applied over any local form fieldValues (for when form field value state is mainly handling in the calling code, & may be changed from non-form field actions, like async api responses etc.)
  // TODO: maynot support nested fields yet, so careful if/when enabling this on complex nested forms
  updateFieldValuesOnExternalChange?: boolean

  autoLayoutType?: ArkFormAutoLayoutType
  autoLayoutDir?: ArkFormAutoLayoutDirection

  showLabels?: boolean
  insideModal?: boolean // enable this when the form is shown within an ArkModal (or inverted SUI modal) so the bg colour of fieldset titles is set to match
  enterKeySubmits?: boolean // default: true - set to false to disable the enter key listener triggering a form submit button press (requires the onFormSubmit callback to be used via an OKButton or other button with NO onClick override) NB: doesn't currently support toggling on/off at runtime, see the optional `shouldEnterKeySubmit` callback instead
  tabFocusEnabled?: boolean // default: true - set to false to disable tab key focus changing between fields (adds `tabIndex="-1"` to all fields to disable tab focus)

  placeholderInAnyProps?: boolean // default: false - when disabled `ArkFormFieldPlaceholder` is only replaced in `<element>.props.children`, when enabled all props of an element are looped through & the `ArkFormFieldPlaceholder` replaced with the corresponding form field

  onFormSubmit?: ArkFormSubmitCallback // NB: use this custom submit callback instead of the normal Form onSubmit one when using formFields
  onValueChanged?: ArkFormValueChangedCallback // NB: only use this if you need to listed out for certain field changes to act on them before/outside the normal submit callback

  shouldEnterKeySubmit?: ArkFormEnterKeyShouldSubmitCallback // optional callback to dynamically enable/disable the enter key submit handling at runtime (also requires `enterKeySubmits` to be true as well)
}
interface IState {
  fieldValues: ArkFormFieldValues
  fieldErrors: ArkFormFieldErrors
  collapsibleFields: Map<string, boolean>
  tabFocusEnabled: boolean
}

class ArkForm extends React.Component<ArkFormProps, IState> {
  _isMounted: boolean = false
  private _formRef = React.createRef<FormComponent>()

  _fieldErrorsLabelOptions = {
    pointing: 'above',
    basic: true
  }

  constructor (props: ArkFormProps) {
    super(props)

    let fieldValues: ArkFormFieldValues = {}
    const fieldErrors: ArkFormFieldErrors = {}

    // load any default/initial values - TODO: also re-run this if the formFields prop changes?
    const { formFields } = this.props
    if (formFields) {
      fieldValues = this.getFieldValuesFromFormFields(formFields)
      console.log('ArkForm - init - fieldValues:', fieldValues)
    }

    this.state = {
      fieldValues,
      fieldErrors,
      collapsibleFields: new Map<string, boolean>(),
      tabFocusEnabled: this.props.tabFocusEnabled !== false
    }
  }

  componentDidMount () {
    this._isMounted = true
    if (this.props.enterKeySubmits !== false) document.addEventListener('keydown', this.handleKeyPress) // NB: should also remove if the prop value changes (currently unlikely in normal use cases)
  }

  componentWillUnmount () {
    this._isMounted = false
    if (this.props.enterKeySubmits !== false) document.removeEventListener('keydown', this.handleKeyPress)
  }

  // TODO: catch if props change & update? (e.g. passed in field values?)
  // TODO: consider if we should allow `enterKeySubmits` to be changed at runtime & update the listener accordingly? (NB: for now the `shouldEnterKeySubmit` optional callback can be used to achieve the same end result instead)
  componentDidUpdate (prevProps: ArkFormProps, _prevState: IState) {
    if (this.props.updateFieldValuesOnExternalChange) {
      // TODO: does `compareFieldValues` & `updateFieldValue` usage here need to be made recursive for nested fields? if so careful to not break/alter default `updateFieldValue` usage from normal field changes
      // NB: only include fields that use the `value` field/prop directly, skip form fields that use `defaultValue`
      // NB: otherwise `updateFieldValue` gets called twice one with the inital defaultValue change & another here that wipes the value in the local cache
      const includeDefaultValues = false
      const newFieldValues = this.props.formFields ? this.getFieldValuesFromFormFields(this.props.formFields, includeDefaultValues) : undefined
      // console.log('ArkForm - componentDidUpdate - (prev/cached)fieldValues:', this.state.fieldValues, ' newFieldValues:', newFieldValues)
      if (newFieldValues) {
        const fieldValuesDiff = this.compareFieldValues(newFieldValues, this.state.fieldValues)
        if (fieldValuesDiff && fieldValuesDiff.length > 0) {
          // console.log('ArkForm - componentDidUpdate - compareFieldValues - fieldValuesDiff:', fieldValuesDiff)
          for (const fieldKey of fieldValuesDiff) {
            this.updateFieldValue(fieldKey, newFieldValues[fieldKey], false)
          }
          // TESTING: clear all errors if any field changed, regardless if the specific field changed, incase it changed the schema & so invalidates old errors
          // NB: if this causes issues down the line, can we instead detect when the validation schema changed & just reset then perhaps?
          // UPDATE: this only works for certain field types currently, not dropdowns (where its currently needed), as they trigger local value changed handling
          // UPDATE: ..before this would fire & so it wouldn't pick up the change, now handling it at the value changed level instead
          // console.log('ArkForm - componentDidUpdate - fieldValuesDiff - clear errors...')
          // this.setState({ fieldErrors: {} })
        }
      }
    }
    if (this.props.tabFocusEnabled !== prevProps.tabFocusEnabled) {
      if (this.props.tabFocusEnabled === false) {
        this.disableTabFocus()
      } else {
        this.enableTabFocus()
      }
    }
  }

  // -------

  render () {
    // NB: we purposely extract out all our own props from the passed in props var, so we can pass the rest directly to the Form component, so we skip the eslint checks here
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { formKey, children, header, formError, formFields, formSchema, updateFieldValuesOnExternalChange, autoLayoutType, autoLayoutDir, showLabels, insideModal, enterKeySubmits, tabFocusEnabled, inverted, placeholderInAnyProps, onSubmit, onFormSubmit, onValueChanged, shouldEnterKeySubmit, className, ...props } = this.props
    const isInverted = inverted || true // default to inverted being true
    const onSubmitCallback = onSubmit || this.onSubmit // allow the parent component to use its own onSubmit callback, or use the internal one
    return (
      <Form
        inverted={isInverted}
        onSubmit={onSubmitCallback}
        className={styles.form + (insideModal ? ' ' + styles.formInModal : '') + (className ? ' ' + className : '')}
        ref={this._formRef}
        {...props}
      >

        {header && (
          <Header as="h2" /* textAlign="center" */ inverted={isInverted}>
            {header}
          </Header>
        )}

        {formError && this.renderFormError(formError)}

        {this.renderFormFields()}

      </Form>
    )
  }

  // -------

  clearFieldErrorsForField = (fieldKey: string) => {
    if (this.state.fieldErrors[fieldKey] !== undefined) {
      const fieldErrors = this.state.fieldErrors
      delete fieldErrors[fieldKey]
      this.setState({
        fieldErrors: fieldErrors
      })
    }
  }

  // parses the value/defaultValue props from an array of ArkFormField's & returns as a keyed ArkFormFieldValues object ready to use in this form
  // currently just used when the form loads, to load all initial values
  // NB: this is a recursive function, to add support for nested fields within groups & fieldsets
  // NB: we currently spread any nested values into the root of the values object, tree/structured data isn't currently supported, but could likely be in the future if needed
  // NB: because all values are saved in a single layer ALL field keys must be unique across the whole form, even if in groups/fieldsets, they shouldn't dupe with any other field keys in other layers
  getFieldValuesFromFormFields = (formFields: Array<ArkFormField>, includeDefaultValues: boolean = true) => {
    const fieldValues: ArkFormFieldValues = {}
    for (const formField of formFields) {
      // NB: if value is set it takes precedence over the defaultValue field (to match SUI Form usage of both fields)
      if (formField.value !== undefined) {
        fieldValues[formField.key] = formField.value
      } else if (formField.defaultValue !== undefined && includeDefaultValues) {
        fieldValues[formField.key] = formField.defaultValue
      }
      if (formField.fields) {
        const subFieldValues = this.getFieldValuesFromFormFields(formField.fields, includeDefaultValues)
        for (const subFieldKey in subFieldValues) {
          fieldValues[subFieldKey] = subFieldValues[subFieldKey]
        }
      }
    }
    return fieldValues
  }

  compareFieldValues = (newFieldValues: ArkFormFieldValues, oldFieldValues: ArkFormFieldValues) => {
    // console.log('ArkForm - compareFieldValues - newFieldValues:', newFieldValues, ' oldFieldValues:', oldFieldValues)
    const fieldValuesDiff: Array<string> = []
    for (const [fieldKey, newFieldValue] of Object.entries(newFieldValues)) {
      const oldFieldValue = oldFieldValues[fieldKey]
      // console.log('ArkForm - compareFieldValues - fieldKey:', fieldKey, ' newFieldValue:', newFieldValue, ' oldFieldValue:', oldFieldValue)
      if (newFieldValue !== oldFieldValue) {
        fieldValuesDiff.push(fieldKey)
      }
    }
    // console.log('ArkForm - compareFieldValues - fieldValuesDiff:', fieldValuesDiff)
    return fieldValuesDiff
  }

  updateFieldValue = (fieldKey: string, fieldValue: any, triggerValueChanged: boolean = true) => {
    const oldFieldValue = this.state.fieldValues[fieldKey]
    // console.log('ArkForm - updateFieldValue - fieldKey:', fieldKey, ' fieldValue:', fieldValue, ' oldFieldValue:', oldFieldValue)

    this.setState({ fieldValues: { ...this.state.fieldValues, [fieldKey]: fieldValue } })

    // TMP: for now clear the error on a field as soon as its edited
    // TODO: run the validation as the user types?
    this.clearFieldErrorsForField(fieldKey)

    // TESTING: certain field value updates may also change the schema or alter the form fields & want to reset/clear any existing errors if so
    const formField = this.getFormFieldForKey(fieldKey, true)
    // console.log('ArkForm - updateFieldValue - fieldKey:', fieldKey, ' formField:', formField)
    if (formField && formField.resetAllFieldErrorOnChange) {
      // console.log('ArkForm - updateFieldValue - fieldKey:', fieldKey, ' - resetAllFieldErrorOnChange...')
      this.setState({ fieldErrors: {} })
    }

    if (triggerValueChanged && this.props.onValueChanged) this.props.onValueChanged(fieldKey, fieldValue, oldFieldValue)
  }

  // TODO: may not yet handled nested values fully - needs testing with different types to check
  checkForFieldValueChanges = (formFields?: ArkFormField[], prevFormFields?: ArkFormField[]) => {
    // console.log('ArkForm - checkForFieldValueChanges - formFields:', formFields, ' prevFormFields:', prevFormFields)
    // NB: ONLY considering external changes for manually/directly handled field `value` entries, not `defaultValue` based fields
    if (formFields && prevFormFields) {
      // let prevFieldValues: ArkFormFieldValues = {}
      // if (prevFormFields) {
      //   prevFieldValues = this.getFieldValuesFromFormFields(prevFormFields)
      // }
      for (const formField of formFields) {
        const prevFormField = prevFormFields.find((pFF) => pFF.key === formField.key)
        if (formField && prevFormField) {
          if (formField.fields && prevFormField.fields) {
            this.checkForFieldValueChanges(formField.fields, prevFormField.fields)
          }
          if (!formField.defaultValue && !prevFormField.defaultValue && (formField.value !== undefined || prevFormField.value !== undefined)) {
            const currentFieldValue = this.state.fieldValues[formField.key] // TODO: does this need any special handling for nested form fields? or are all values stored flat?
            if (formField.value !== currentFieldValue) { // Object.prototype.hasOwnProperty.call(this.state.fieldValues, formField.key) // NB: seems like fields with an undefined default aren't set, so we can't confirm its previous value, so just assuming its undefined for now
              console.log('ArkForm - checkForFieldValueChanges - FIELD VALUE CHANGED - formField.key:', formField.key, ' - FROM:', currentFieldValue, ' TO:', formField.value)
              this.updateFieldValue(formField.key, formField.value) // NB: will currently also fire the `onValueChanged` should we disable that when its updated externally like this (as the calling/parent code should be aware of the change already)? or is it ok as-is?
            }
          }
        }
      }
    }
  }

  // checkForFieldValueChange = (formField: ArkFormField, prevFormField: ArkFormField) => {
  // }

  // compareFieldValues = (fieldValues: ArkFormFieldValues, prevFieldValues: ArkFormFieldValues) => {
  // }

  // TESTING: helper to find certain field types in the nested form fields data, returns as a flattened array of matching fields
  getFieldsFromFormFields = (formFields: Array<ArkFormField>, fieldTypes: Array<ArkFormFieldType>) => {
    const filteredFormFields: Array<ArkFormField> = []
    for (const formField of formFields) {
      // NB: if value is set it takes precedence over the defaultValue field (to match SUI Form usage of both fields)
      if (fieldTypes.includes(formField.type)) {
        filteredFormFields.push(formField)
      }
      if (formField.fields) {
        const subFilteredFormFields = this.getFieldsFromFormFields(formField.fields, fieldTypes)
        filteredFormFields.push(...subFilteredFormFields)
      }
    }
    return filteredFormFields
  }

  // -------

  onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    this.updateFieldValue(event.target.name, event.target.value)
  }

  // NB: this only fires if the parent component does not specify its own onSubmit callback handler prop
  onSubmit = async (event: React.FormEvent<HTMLFormElement>, data: FormProps) => {
    console.log('ArkForm - onSubmit - this.state.fieldValues:', this.state.fieldValues)
    // console.log('ArkForm - onSubmit - this.props.formSchema:', this.props.formSchema)
    event.preventDefault()

    // NB: this onSubmit callback won't fire if form.checkValidity() is false? (I think thats the case, so should only need to perform additional custom validation checks if any)
    // const form = event.currentTarget

    // TESTING: yum field validation if specified
    if (this.props.formSchema) {
      let validationErrors: {[key: string]: any} | null = null
      // const isValid =
      await this.props.formSchema.validate(this.state.fieldValues, { abortEarly: false })
        .catch(function (err) {
          validationErrors = err.inner
        })

      if (validationErrors && Object.keys(validationErrors).length > 0) {
        const fieldErrors: ArkFormFieldErrors = {}
        for (const errorKey of Object.keys(validationErrors)) {
          const validationError: any = validationErrors[errorKey]
          const validationErrorPath: string = validationError.path
          const validationErrorErrors: any = validationError.errors
          fieldErrors[validationErrorPath] = validationErrorErrors
        }
        this.setState({ fieldErrors: fieldErrors })
        // halt if the form has validation errors
      } else { // no errors - ok to submit/process the form...
        this.setState({ fieldErrors: {} })

        // call our custom submit callback passing along the cached fieldValues in the response
        if (this.props.onFormSubmit) this.props.onFormSubmit(this.state.fieldValues, event, { ...data, ...this.props })
      }
    } else {
      // call our custom submit callback passing along the cached fieldValues in the response
      if (this.props.onFormSubmit) this.props.onFormSubmit(this.state.fieldValues, event, { ...data, ...this.props })
    }
  }

  // -------

  enableTabFocus = () => {
    console.log('ArkForm - enableTabFocus')
    if (this.state.tabFocusEnabled) return
    this.setState({ tabFocusEnabled: true })
  }

  disableTabFocus = () => {
    console.log('ArkForm - disableTabFocus')
    if (!this.state.tabFocusEnabled) return
    this.setState({ tabFocusEnabled: false })
  }

  // -------

  getFormFieldForKey = (fieldKey: string, searchNested: boolean = false, searchFormFields?: Array<ArkFormField>) : ArkFormField | null => {
    const { formFields: _formFields } = this.props
    const formFields = searchFormFields ?? _formFields
    if (formFields) {
      for (const formField of formFields) {
        if (formField.key === fieldKey) { return formField }
      }
      if (searchNested) {
        for (const formField of formFields) {
          if (formField.fields) {
            return this.getFormFieldForKey(fieldKey, true, formField.fields)
          }
        }
      }
    }
    return null
  }

  // -------

  renderFormFields = () => {
    const { children, formFields } = this.props
    // if any child elements are passed into this component, flip to manual layout mode instead of auto
    // where the user must use ArkFormFieldPlaceholder's for each form field for them to render
    if (children) {
      return this.renderFormFieldsManualLayout()
    }
    return this.renderFormFieldsAutoLayout(formFields)
  }

  renderFormFieldsAutoLayout = (formFields?: Array<ArkFormField>) => {
    const { autoLayoutType, autoLayoutDir } = this.props
    if (formFields) {
      const elements: Array<React.ReactNode> = []
      for (const formField of formFields) {
        const field = this.renderFormField(formField)
        if (field) {
          elements.push(field)
        }
      }
      if (autoLayoutType && autoLayoutType === ArkFormAutoLayoutType.grid) {
        return this.renderFormFieldsAutoLayoutGrid(elements, autoLayoutDir)
      }
      return (<>{elements}</>) // ArkFormAutoLayoutType.none
    }
    return null
  }

  renderFormFieldsAutoLayoutGrid = (elements: Array<React.ReactNode>, autoLayoutDir?: ArkFormAutoLayoutDirection) => {
    if (autoLayoutDir && autoLayoutDir === ArkFormAutoLayoutDirection.horizontal) {
      return (
        <Grid columns='equal' verticalAlign='bottom'>
          <Grid.Row>
            {elements.map((element, index) => (<Grid.Column key={(this.props.formKey ?? '') + '_formfield_' + index}>{element}</Grid.Column>))}
          </Grid.Row>
        </Grid>
      )
    }
    // ArkFormAutoLayoutDirection.vertical (default)
    return (
      <Grid>
        {elements.map((element, index) => (<Grid.Row key={(this.props.formKey ?? '') + '_formfield_' + index}><Grid.Column>{element}</Grid.Column></Grid.Row>))}
      </Grid>
    )
  }

  renderFormFieldsManualLayout = () => {
    // replace any ArkFormFieldPlaceholder elements in the children tree with their actual form field components
    const newChildren = this._renderFormFieldsManualLayoutChildren(this.props.children)
    return (
      <>{newChildren}</>
    )
  }

  // partially based off ref: https://stackoverflow.com/a/55486160
  _renderFormFieldsManualLayoutChildren = (children: React.ReactNode): React.ReactNode => {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) {
        return child
      }
      let newChild = child
      // console.log('ArkForm - _renderFormFieldsManualLayoutChildren - this.props.placeholderInAnyProps:', this.props.placeholderInAnyProps)
      if (!this.props.placeholderInAnyProps) {
        if (newChild.props.children) {
          newChild = React.cloneElement(newChild, {
            children: this._renderFormFieldsManualLayoutChildren(newChild.props.children)
          } as any) // TODO: any way to avoid the cast to `any` to stop the `No overlad matches this call...Argument of type { children: .. } is not assignable...` warning?
        }
      } else { // this.props.placeholderInAnyProps === true
        // OLD: ArkPanel specific
        // TESTING: special support for ArkPanel.PropertyRow entries which can use a `value` prop instead of `children` that can sometimes include ArkForm elements we want to replace
        // TODO: (UPDATE: fixed below) can ArkPanel.PropertyRow be made to use children instead of the value var? & so not need this special handling, or does that make sense in how it (& its accompanying panel components) are structured/used?
        // } else // ...
        // if (newChild.type === ArkPanel.PropertyRow && newChild.props?.value) { // TODO: check if its a ReactNode before using it
        //   console.log('ArkForm - _renderFormFieldsManualLayoutChildren - newChild.props?.value - isValidElement:', React.isValidElement(newChild.props?.value))
        //   newChild = React.cloneElement(newChild, {
        //     value: this._renderFormFieldsManualLayoutChildren(newChild.props?.value)
        //   })
        // }
        // NEW: supports any/all component props that might contain an `ArkFormFieldPlaceholder` (instead of only the `children` prop)
        // TESTING: support ArkFormFieldPlaceholder in any prop instead of only `children`
        if (newChild.props) {
          for (const key in newChild.props) {
            // console.log('ArkForm - _renderFormFieldsManualLayoutChildren - newChild.props - key:', key, ' isValidElement:', React.isValidElement(newChild.props[key]))
            // if (key === 'children' && !React.isValidElement(newChild.props[key])) console.log('ArkForm - _renderFormFieldsManualLayoutChildren - WARNING: NON REACT ELEMENT \'children\' PROP <<<<')
            // TODO: how can we check if any prop is actually a valid `React.ReactNode`, `isValidElement` doesn't seem to work for all 'children' entries, so blindly allowing any 'children' through for now (should be ok, but does it mean we're missing other props that might also be valid?)?
            if (React.isValidElement(newChild.props[key]) || key === 'children') {
              // if (newChild.type === ArkPanel.PropertyRow) console.log('ArkForm - _renderFormFieldsManualLayoutChildren - newChild.props - isValidElement - key:', key, '=', newChild.props[key])
              newChild = React.cloneElement(newChild, {
                // NB: note the `[key]` usage so the 'computed key' is used instead of the literal string 'key' as the object key name - ref: https://stackoverflow.com/a/19837961
                [key]: this._renderFormFieldsManualLayoutChildren(newChild.props[key])
              } as any) // TODO: same as the hider up cloneElement call, ideally avoid the cast to `any` to avoid the warning
              // console.log('ArkForm - _renderFormFieldsManualLayoutChildren - newChild(AFTER):', newChild)
            }
          }
        }
      }

      if (newChild.type === ArkFormFieldPlaceholder) {
        const formField = this.getFormFieldForKey(newChild.props.fieldKey)
        // console.log('ArkForm - _renderFormFieldsManualLayoutChildren - formField:', formField)
        if (formField) {
          // return (<div>REPLACED {newChild.props.fieldKey}</div>)
          return this.renderFormField(formField)
        }
      }
      return newChild
    })
  }

  // -------

  renderFormError = (error?: Error | ArkFormMultiError, title?: string, key?: string, skipPlaceholderCheck: boolean = false) => {
    if (!error) return null
    // FormErrorPlaceholder support - check if the error placeholder field is being used anywhere in the form & halt if so
    // NB: skips the check when being called to render from the error placeholder itself
    if (!skipPlaceholderCheck) {
      const { formFields } = this.props
      const hasErrorPlaceholder = this.fieldsContainFormErrorPlaceholder(formFields ?? [])
      if (hasErrorPlaceholder) return null
    }
    return (
      <Message negative key={key} className={styles.formError}>
        <Message.Header>{title ?? 'Error'}</Message.Header>
        {(error instanceof ArkFormMultiError) && (
          <Message.List>
            {error.messages.map((message, index) => (
              <Message.Item key={index}>{message}</Message.Item>
            ))}
          </Message.List>
        )}
        {!(error instanceof ArkFormMultiError) && (<Message.Item>{error.message}</Message.Item>)}
      </Message>
    )
  }

  fieldsContainFormErrorPlaceholder = (formFields: Array<ArkFormField>) => {
    const hasErrorPlaceholder = formFields.find((field) => field.type === ArkFormFieldType.FormErrorPlaceholder) !== undefined
    if (hasErrorPlaceholder) return true
    for (const formField of formFields) {
      if (formField.fields) {
        if (this.fieldsContainFormErrorPlaceholder(formField.fields)) return true
      }
    }
    return false
  }

  // -------

  renderFormField = (formField: ArkFormField) => {
    // const { type, key, label, fieldProps, ...props } = formField
    switch (formField.type) {
      case ArkFormFieldType.Field: return this.renderFormFieldField(formField)
      case ArkFormFieldType.Button:
      case ArkFormFieldType.OKButton:
      case ArkFormFieldType.CancelButton:
      case ArkFormFieldType.DeleteButton:
        return this.renderFormFieldButton(formField)
      case ArkFormFieldType.Checkbox: return this.renderFormFieldCheckbox(formField)
      case ArkFormFieldType.Dropdown: return this.renderFormFieldDropdown(formField)
      case ArkFormFieldType.Group: return this.renderFormFieldGroup(formField)
      case ArkFormFieldType.Input: return this.renderFormFieldInput(formField)
      case ArkFormFieldType.Radio: return this.renderFormFieldRadio(formField)
      case ArkFormFieldType.Select: return this.renderFormFieldSelect(formField)
      case ArkFormFieldType.TextArea: return this.renderFormFieldTextArea(formField)
      case ArkFormFieldType.Fieldset: return this.renderFormFieldFieldset(formField)
      case ArkFormFieldType.Colour: return this.renderFormFieldColour(formField)
      case ArkFormFieldType.PhoneNo: return this.renderFormFieldPhoneNo(formField)
      case ArkFormFieldType.NumberInput: return this.renderFormFieldNumberInput(formField)
      case ArkFormFieldType.FormErrorPlaceholder: return this.renderFormFieldFormErrorPlaceholder(formField)
    }
    // return null
  }

  // -------

  renderFormFieldField = (formField: ArkFormField) => {
    const field = (
      <Form.Field
        key={formField.key}
        className={`${formField.className ? ' ' + formField.className : ''}`}
        label={formField.label}
        content={formField.content}
        disabled={formField.disabled}
        {...formField.fieldProps}
        {...(formField.hint === undefined && formField.description === undefined ? formField.wrapperProps : null)}
        tabIndex={this.state.tabFocusEnabled ? undefined : -1}
      >
      </Form.Field>
    )
    if (formField.description || formField.hint) {
      return this.renderFormFieldExtras(formField, field)
    }
    return field
  }

  renderFormFieldButton = (formField: ArkFormField) => {
    let basic = false
    let fluid = false
    let color: SemanticCOLORS | undefined
    if (formField.type === ArkFormFieldType.OKButton) {
      basic = true
      color = 'blue'
    } else if (formField.type === ArkFormFieldType.CancelButton) {
      basic = true
      color = 'orange'
    } else if (formField.type === ArkFormFieldType.DeleteButton) {
      basic = true
      color = 'red'
      fluid = true
    }
    // stop onClick event propigation for buttons that implement the onClick callback (anything that isn't an ok/submit button)
    // NB: without this, the form submit can sometimes still fire, so adding it here instead of manually to every 'cancel' etc. type form button we use
    // NB: other option is to add a `type: 'button'` entry to fieldProps when declaring the button, so it doesn't add as a default form 'submit' button
    const fieldProps = formField.fieldProps
    if (formField.type !== ArkFormFieldType.OKButton) {
      if (fieldProps && fieldProps.onClick) {
        const onClick = fieldProps.onClick
        fieldProps.onClick = (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault()
          onClick(event)
        }
      }
    }
    return (<Form.Button
      key={formField.key}
      className={styles.button + (color === undefined && fieldProps?.color === undefined ? ' ' + styles.defaultColor : '') + ' btn_' + (this.props.formKey ?? '') + '_' + formField.key + (formField.className ? ' ' + formField.className : '')} // NB: now setting a unique className inc. the form & field keys, so we can query it in the DOM for the custom enter key handling
      basic={basic}
      color={color}
      fluid={fluid}
      size="large"
      disabled={formField.disabled}
      data-tooltip={formField.disabled ? formField.disabledTooltip : undefined}
      {...fieldProps}
      tabIndex={this.state.tabFocusEnabled ? undefined : -1}
    >
      {formField.label}
    </Form.Button>)
  }

  // TODO: consider optional support for multiple checkboxes? see the SUI React Form examples for some hints on how this can be done
  renderFormFieldCheckbox = (formField: ArkFormField) => {
    const field = (
      <Form.Checkbox
        key={formField.key}
        id={formField.key}
        className={`${styles.checkbox}${formField.className ? ' ' + formField.className : ''}`}
        label={this.props.showLabels !== false ? formField.label : undefined}
        placeholder={this.props.showLabels !== false || formField.placeholder ? formField.placeholder : (typeof formField.label === 'string' ? formField.label as string : '')}
        checked={!!(this.state.fieldValues[formField.key] !== undefined && this.state.fieldValues[formField.key] === true)}
        disabled={formField.disabled}
        required={formField.required ?? false}
        onChange={(event: React.SyntheticEvent<HTMLElement, Event>, data: CheckboxProps) => {
          this.updateFieldValue(formField.key, data.checked)
        }}
        error={(this.state.fieldErrors && this.state.fieldErrors[formField.key])
          ? {
            content: this.state.fieldErrors[formField.key].join(', '),
            ...this._fieldErrorsLabelOptions
          }
          : null}
        {...formField.fieldProps}
        {...(formField.hint === undefined && formField.description === undefined ? formField.wrapperProps : null)}
        tabIndex={this.state.tabFocusEnabled ? undefined : -1}
      />
    )
    if (formField.description || formField.hint) {
      return this.renderFormFieldExtras(formField, field)
    }
    return field
  }

  renderFormFieldDropdown = (formField: ArkFormField) => {
    // console.log('ArkForm - renderFormFieldDropdown - formField:', formField)
    const fieldProps = formField.fieldProps ? { ...formField.fieldProps } : undefined
    let fieldPropsClassName: string | undefined
    if (fieldProps?.className !== undefined) { // TODO: convert this (& anything using this) to use the top level ArkFormField.className arg/field (as we're adding support for it to most field types)
      fieldPropsClassName = fieldProps?.className
      delete fieldProps.className
    }
    // console.log('ArkForm - renderFormFieldDropdown - formField.key:', formField.key, ' fieldPropsClassName:', fieldPropsClassName)
    const currentFieldValue = this.state.fieldValues[formField.key]
    // TESTING: catch external direct value updates (when using `value` instead of `defaultValue` to manage the field directly & updating it after form init)
    // NB: without this, if a manually controlled `value` based required field is updated after the form is setup, we won't know about it internally & it'll throw an error (will also effect all other validation?)
    // NB: might need to port this handling to other fields... (if it works reliably in the initial use case - currently in the `ProjectBroadcastView` it its `videoDevice` dropdown field)
    // NB: seems to technically work but throws the following error: `Warning: Cannot update during an existing state transition (such as within `render`). Render methods should be a pure function of props and state.`
    // UPDATE: see the newly added `componentDidUpdate` handling & its call to `checkForFieldValueChanges` for a cross field alternative to this which seems to work properly without errors
    // if (!formField.defaultValue && (formField.value !== currentFieldValue)) {
    //   console.log('ArkForm - renderFormFieldDropdown - formField.value CHANGED(?) - was:', currentFieldValue, ' now:', formField.value)
    //   this.updateFieldValue(formField.key, formField.value) // NB: will currently also fire the `onValueChanged` should we disable that when its updated externally like this (as the calling/parent code should be aware of the change already)?
    // }
    const currentFieldOption = formField.options?.find(o => o.value === currentFieldValue) // lookup the current value for this field if one is set (used in the `text` field to optionally customise the selected text displayed for an option)
    const options = formField.options?.map((option) => { // remove the optional `selectedText` field before passing it to the SUI form field (otherwise it seems to get rendered to the DOM & trigger an error)
      if (option.selectedText) return { key: option.key, text: option.text, value: option.value, description: option.description, children: option.children, disabled: option.disabled }
      return option
    })
    // console.log('ArkForm - renderFormFieldDropdown - formField.key:', formField.key, ' currentFieldValue:', currentFieldValue, ' currentFieldOption:', currentFieldOption)
    // NB: ONLY using the `trigger` prop if options have custom `children` elements instead of using the normal options text & selectedText fields
    // NB: updated to also check if option.text is not a string (& not undefined etc.) as well (now it has been extended to optionally support ReactNode types)
    const optionsHaveCustomContent = formField.options?.find(option => (option.children !== undefined || (option.text !== undefined && typeof option.text !== 'string'))) !== undefined
    // console.log('ArkForm - renderFormFieldDropdown - formField.key:', formField.key, ' optionsHaveCustomContent:', optionsHaveCustomContent)
    let text: string | undefined
    let trigger: React.ReactNode | undefined
    if (fieldProps?.trigger !== undefined) { // NB: added to support custom trigger (selected) elements
      trigger = fieldProps.trigger
      delete fieldProps.trigger
    } else if (optionsHaveCustomContent) {
      trigger = currentFieldOption
        ? (currentFieldOption.children ?? currentFieldOption.selectedText ?? currentFieldOption.text ?? 'N/A')
        : undefined
      if (typeof trigger === 'string') {
        trigger = <div className='text'>{trigger}</div>
      }
    } else {
      text = currentFieldOption && currentFieldOption.selectedText ? currentFieldOption.selectedText : undefined // TESTING: if the selected option has a `selectedText` option display that instead of its normal `text` value (e.g. if we want to abbreviate or alter whats shown when its selected)
    }
    // console.log('ArkForm - renderFormFieldDropdown - formField.key:', formField.key, ' text:', text, ' formField.placeholder:', formField.placeholder, ' formField.label:', formField.label, ' placeholder:', (this.props.showLabels !== false || formField.placeholder ? formField.placeholder : (typeof formField.label === 'string' ? formField.label as string : '')))
    const selectionMode = !trigger ? !optionsHaveCustomContent : undefined // default to selection mode (the normal handling we've used) unless 1 or more options specify custom children content instead of the normal text string values (UPDATED: to also skip if a custom trigger (selected) element is set)
    const hasValue = currentFieldValue !== undefined // NB: we now add a className to indicate when no value is set so we can hide (via css) the selection shown in place of the placeholder when the dropdown is open (SUIR seems to force that to happen even when `selectOnBlur` is disabled as we do)
    // console.log('ArkForm - renderFormFieldDropdown - formField.key:', formField.key, ' hasValue:', hasValue, ' formField.value:', formField.value, ' formField.defaultValue:', formField.defaultValue, ' currentFieldValue:', currentFieldValue)
    // console.log('ArkForm - renderFormFieldDropdown - formField.key:', formField.key, ' selectionMode:', selectionMode, ' trigger:', trigger)
    const field = (<Form.Dropdown
      className={`${styles.dropdownField}${fieldPropsClassName ? ' ' + fieldPropsClassName : ''}${formField.className ? ' ' + formField.className : ''}${hasValue ? ' ' + styles.hasValue : ''}`}
      fluid
      selection={selectionMode}
      required={formField.required ?? false}
      key={formField.key}
      id={formField.key}
      name={formField.key}
      label={this.props.showLabels !== false ? formField.label : undefined}
      placeholder={this.props.showLabels !== false || formField.placeholder ? formField.placeholder : (typeof formField.label === 'string' ? formField.label as string : '')}
      options={options}
      value={formField.value}
      defaultValue={formField.defaultValue}
      disabled={formField.disabled}
      text={text}
      trigger={trigger}
      onChange={(event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        console.log('ArkForm - renderFormFieldDropdown - onChange - data:', data)
        this.updateFieldValue(data.name, data.value)
      }}
      error={(this.state.fieldErrors && this.state.fieldErrors[formField.key])
        ? {
          content: this.state.fieldErrors[formField.key].join(', '),
          ...this._fieldErrorsLabelOptions
        }
        : null}
      selectOnBlur={false} // stop it auto selecting the item when you click outside an open dropdown when its current value isn't set
      {...fieldProps}
      {...(formField.hint === undefined && formField.description === undefined ? formField.wrapperProps : null)}
      tabIndex={this.state.tabFocusEnabled ? undefined : -1}
    />)
    if (formField.description || formField.hint) {
      return this.renderFormFieldExtras(formField, field)
    }
    return field
  }

  renderFormFieldGroup = (formField: ArkFormField) => {
    // NB: use the new ArkForm Fieldset to wrap the group if you want collapsible support
    const field = (<Form.Group
      className={`${styles.group + (formField.slimline === true ? ' ' + styles.groupSlimline : '')}${formField.className ? ' ' + formField.className : ''}`}
      key={formField.key}
      {...formField.fieldProps}
    >
      {this.renderFormFieldsAutoLayout(formField.fields)}
    </Form.Group>)
    return field
  }

  renderFormFieldInput = (formField: ArkFormField) => {
    const field = (<Form.Input
      fluid
      required={formField.required ?? false}
      className={`${formField.className ? ' ' + formField.className : ''}`}
      key={formField.key}
      id={formField.key}
      name={formField.key}
      icon={formField.icon}
      iconPosition={formField.icon !== undefined ? 'left' : undefined}
      label={this.props.showLabels !== false ? formField.label : undefined}
      placeholder={this.props.showLabels !== false || formField.placeholder ? formField.placeholder : formField.label}
      value={formField.value}
      defaultValue={formField.defaultValue}
      disabled={formField.disabled}
      onChange={this.onChange}
      error={(this.state.fieldErrors && this.state.fieldErrors[formField.key])
        ? {
          content: this.state.fieldErrors[formField.key].join(', '),
          ...this._fieldErrorsLabelOptions
        }
        : null}
      {...formField.fieldProps}
      {...(formField.hint === undefined && formField.description === undefined ? formField.wrapperProps : null)}
      tabIndex={this.state.tabFocusEnabled ? undefined : -1}
    />
    )
    if (formField.description || formField.hint) {
      return this.renderFormFieldExtras(formField, field)
    }
    return field
  }

  renderFormFieldRadio = (formField: ArkFormField) => {
    if (formField.toggle && !formField.options) {
      const field = (
        <Form.Radio
          className={`${styles.radioToggle}${formField.className ? ' ' + formField.className : ''}`}
          required={formField.required ?? false}
          key={formField.key}
          // id={formField.key} // NB: enabling this seems to stop the checked status from toggling off? skipping it on this field for now
          name={formField.key}
          label={this.props.showLabels !== false ? formField.label : undefined}
          placeholder={this.props.showLabels !== false || formField.placeholder ? formField.placeholder : (typeof formField.label === 'string' ? formField.label as string : '')}
          disabled={formField.disabled}
          toggle={true}
          checked={formField.toggle === true && typeof formField.value === 'boolean' ? formField.value : undefined}
          defaultChecked={formField.toggle === true && typeof formField.defaultValue === 'boolean' ? formField.defaultValue : undefined}
          onChange={(event: React.FormEvent<HTMLInputElement>, data: FormRadioProps) => {
            this.updateFieldValue(formField.key, data.checked)
          }}
          {...formField.fieldProps}
          tabIndex={this.state.tabFocusEnabled ? undefined : -1}
        />
      )
      if (formField.description || formField.hint) {
        return this.renderFormFieldExtras(formField, field)
      }
      return field
    }
    if (!formField.options) return null
    const children = []
    const formKeyPrefix = this.props.formKey ? this.props.formKey + '_' : ''
    for (const option of formField.options) {
      children.push(
        <Form.Radio
          key={formKeyPrefix + formField.key + '_' + option.key}
          id={formKeyPrefix + formField.key + '_' + option.key}
          name={formField.key + '_' + option.key}
          label={option.text}
          value={option.value}
          checked={!!(this.state.fieldValues[formField.key] !== undefined && this.state.fieldValues[formField.key] === option.value)}
          disabled={formField.disabled}
          toggle={formField.toggle ?? false}
          onChange={(event: React.FormEvent<HTMLInputElement>, data: FormRadioProps) => {
            this.updateFieldValue(formField.key, data.value)
          }}
          tabIndex={this.state.tabFocusEnabled ? undefined : -1}
        />
      )
    }
    const field = (
      <Form.Group
        key={formField.key}
        className={`${styles.radioGroup}${formField.className ? ' ' + formField.className : ''}`}
        {...formField.fieldProps}
      >
        {this.props.showLabels !== false && formField.label && (<div className='field'><label>{formField.label}:</label></div>)}
        {children}
      </Form.Group>
    )
    if (formField.description || formField.hint) {
      return this.renderFormFieldExtras(formField, field)
    }
    return field
  }

  renderFormFieldSelect = (formField: ArkFormField) => {
    // TODO: how is this different from Form.Dropdown?
    const field = (
      <Form.Select
        fluid selection
        required={formField.required ?? false}
        key={formField.key}
        id={formField.key}
        name={formField.key}
        label={this.props.showLabels !== false ? formField.label : undefined}
        placeholder={this.props.showLabels !== false || formField.placeholder ? formField.placeholder : (typeof formField.label === 'string' ? formField.label as string : '')}
        options={formField.options ?? []}
        value={formField.value}
        defaultValue={formField.defaultValue}
        disabled={formField.disabled}
        // options={groupOptions} // NB: supply options via the fieldProps arg for the time being - TODO: see new options array used for the Radio fields support, use that here too?
        onChange={(event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
          this.updateFieldValue(data.name, data.value)
        }}
        error={(this.state.fieldErrors && this.state.fieldErrors[formField.key])
          ? {
            content: this.state.fieldErrors[formField.key].join(', '),
            ...this._fieldErrorsLabelOptions
          }
          : null}
        {...formField.fieldProps}
        {...(formField.hint === undefined && formField.description === undefined ? formField.wrapperProps : null)}
        tabIndex={this.state.tabFocusEnabled ? undefined : -1}
      />
    )
    if (formField.description || formField.hint) {
      return this.renderFormFieldExtras(formField, field)
    }
    return field
  }

  renderFormFieldTextArea = (formField: ArkFormField) => {
    return (<Form.TextArea
      // fluid
      required={formField.required ?? false}
      key={formField.key}
      id={formField.key}
      name={formField.key}
      // icon="mail"
      // iconPosition="left"
      label={this.props.showLabels !== false ? formField.label : undefined}
      placeholder={this.props.showLabels !== false || formField.placeholder ? formField.placeholder : formField.label}
      value={typeof formField.value !== 'boolean' ? formField.value as string : undefined}
      defaultValue={formField.defaultValue}
      disabled={formField.disabled}
      onChange={this.onChange}
      error={(this.state.fieldErrors && this.state.fieldErrors[formField.key])
        ? {
          content: this.state.fieldErrors[formField.key].join(', '),
          ...this._fieldErrorsLabelOptions
        }
        : null}
      {...formField.fieldProps}
      tabIndex={this.state.tabFocusEnabled ? undefined : -1}
    />)
  }

  // NB: this acts like the existing SUI Form.Group, but allows vertical stacking alongside our custom collapse support (Form.Group seems to force horizontal layouts only?)
  renderFormFieldFieldset = (formField: ArkFormField, fieldsetKey?: string) => {
    const isCollapsible = (formField.collapsible === true)
    const onClick = () => {
      const isOpen = this.state.collapsibleFields.get(formField.key) ?? (formField.collapsed !== undefined ? !formField.collapsed : undefined) ?? false
      // ref: https://stackoverflow.com/a/49532713
      this.setState((prevState: IState, _props: ArkFormProps) => {
        prevState.collapsibleFields.set(formField.key, !isOpen)
        return {
          collapsibleFields: prevState.collapsibleFields
        }
      })
    }
    let isOpen = true
    if (isCollapsible) {
      isOpen = this.state.collapsibleFields.get(formField.key) ?? (formField.collapsed !== undefined ? !formField.collapsed : undefined) ?? false
    }
    // TESTING: check if the last child field is a group, if so flag it so the styling (padding) can be tweaked
    // NB: updated to also support the new 'slimline' group, which removes the bottom margin from the group, so we don't need to do it here
    let lastChildIsGroup = false
    if (formField.fields && formField.fields.length > 0) {
      const lastChildField = formField.fields[(formField.fields.length - 1)]
      lastChildIsGroup = (lastChildField.type === ArkFormFieldType.Group && lastChildField.slimline !== true)
    }
    const showBorder = formField.bordered ?? true
    return (
      <div
        key={(fieldsetKey ?? formField.key) + '_fieldset'}
        className={
          styles.fieldset +
          (showBorder ? ' ' + styles.fieldsetBorder : '') +
          (formField.slimline === true ? ' ' + styles.fieldsetSlimline : '') +
          (isCollapsible ? ' ' + styles.fieldsetCollapsible : '') +
          (!isOpen ? ' ' + styles.fieldsetClosed : '') +
          (lastChildIsGroup ? ' ' + styles.fieldsetWithFieldsGroup : '') +
          (formField.className ? ' ' + formField.className : '')
        }
        {...formField.fieldProps}
      >

        {isCollapsible && (
          <Accordion as={Form.Field} fluid inverted>
            <Accordion.Title className={styles.fieldsetTitle + ' fieldsetTitle'} active={isOpen} index={0} onClick={formField.collapsible === true ? onClick : undefined}>
              {formField.collapsible && (<Icon name='dropdown' />)}{formField.label}
            </Accordion.Title>
            <Accordion.Content active={isOpen}>
              {this.renderFormFieldsAutoLayout(formField.fields)}
            </Accordion.Content>
          </Accordion>
        )}

        {!isCollapsible && (
          <div className='ui field'>
            {formField.label && (<div className={styles.fieldsetTitle + ' fieldsetTitle'}>{formField.label}</div>)}
            {this.renderFormFieldsAutoLayout(formField.fields)}
          </div>
        )}

        {/* <Divider /> */}
      </div>
    )
  }

  renderFormFieldFormErrorPlaceholder = (formField: ArkFormField) => {
    const { formError } = this.props
    return this.renderFormError(formError, undefined, formField.key, true)
  }

  renderFormFieldColour = (formField: ArkFormField) => {
    // TODO: add support for other field props..
    // TODO: how to handle if this field is set as required?
    // TODO: add disabled support?
    // TODO: add 'no value' support (allow a colour to be cleared or set to null/undefined if its not a required field)?
    const field = (<ArkFormColourField
      key={formField.key}
      label={formField.label}
      required={formField.required ?? false}
      // value={formField.value} // TODO: add support to lock/override the defaultValue with a specific one like SUI Form fields seem to support?
      defaultValue={(typeof formField.defaultValue === 'string' ? formField.defaultValue : undefined)}
      className={`${formField.className ? ' ' + formField.className : ''}`}
      onChangeComplete={(result: ArkFormColourFieldResult) => {
        this.updateFieldValue(formField.key, result.colour)
        // TMP: for now clear the error on a field as soon as its edited
        this.clearFieldErrorsForField(formField.key)
      }}
      {...(formField.hint === undefined && formField.description === undefined ? formField.wrapperProps : null)}
      // TODO: tabIndex={this.state.tabFocusEnabled ? undefined : -1}
    />)
    if (formField.description || formField.hint) {
      return this.renderFormFieldExtras(formField, field)
    }
    return field
  }

  renderFormFieldPhoneNo = (formField: ArkFormField) => {
    console.log('ArkForm - renderFormFieldPhoneNo - this.state.fieldErrors[formField.key]: ', this.state.fieldErrors[formField.key])
    // TODO: how to know if no input has been given, don't accept only a country code! (some are longer than the min length)
    return (<ArkFormPhoneNoField
      key={formField.key}
      label={formField.label}
      required={formField.required ?? false}
      large={!!(formField.fieldProps?.large)}
      dropdownPosition={formField.fieldProps?.dropdownPosition}
      // value={formField.value} // TODO: add support to lock/override the defaultValue with a specific one like SUI Form fields seem to support?
      defaultValue={(typeof formField.defaultValue === 'string' ? formField.defaultValue : undefined)}
      onChange={(result: ArkFormPhoneNoFieldResult) => {
        console.log('ArkForm - renderFormFieldPhoneNo - onChange - formField.key: ', formField.key, ' result: ', result)
        this.updateFieldValue(formField.key, result.value)
      }}
      // onChangeComplete={(result: ArkFormColourFieldResult) => {
      //   console.log('ArkForm - renderFormFieldColour - onChangeComplete - formField.key: ', formField.key, ' result: ', result)
      //   // // NB: same calls as the main ArkForm onChange handler (tweaked for the ArkFormColourField props format)
      //   this.setState({ fieldValues: { ...this.state.fieldValues, [formField.key]: result.colour } })
      //   // // TMP: for now clear the error on a field as soon as its edited
      //   this.clearFieldErrorsForField(formField.key)
      // }}
      error={(this.state.fieldErrors && this.state.fieldErrors[formField.key])
        ? {
          content: this.state.fieldErrors[formField.key].join(', '),
          ...this._fieldErrorsLabelOptions
        }
        : null}
      // TODO: tabIndex={this.state.tabFocusEnabled ? undefined : -1}
    />)
  }

  // NB: not yet supporting all the props the component supports, just the basics for now
  // NB: to set a custom width for the number input field, set the `width` prop in the `fieldProps` object e.g: `fieldProps: { width: '120px' }`
  renderFormFieldNumberInput = (formField: ArkFormField) => {
    // const currentFieldValue = this.state.fieldValues[formField.key]
    // console.log('ArkForm - renderFormFieldNumberInput - formField:', formField) //, ' currentFieldValue:', currentFieldValue)
    return (
      <ArkFormNumberInputField
        key={formField.key}
        label={formField.label}
        required={formField.required ?? false}
        className={`${formField.className ? ' ' + formField.className : ''}`}
        defaultValue={(typeof formField.defaultValue === 'number' ? formField.defaultValue : undefined)}
        minValue={0}
        maxValue={100}
        maxLength={4}
        allowEmptyValue={true}
        // size='mini'
        onChange={(result: ArkFormNumberInputFieldResult) => {
          // console.log('ArkForm - renderFormFieldNumberInput - onChange - formField.key: ', formField.key, ' result: ', result)
          this.updateFieldValue(formField.key, result.value)
        }}
        {...formField.fieldProps}
        // TODO: tabIndex={this.state.tabFocusEnabled ? undefined : -1}
      />
    )
  }

  // -------

  // TESTING: adds support for custom extra field features including 'description' and/or 'hint' features to a given form field
  renderFormFieldExtras = (formField: ArkFormField, renderedField: React.ReactNode) => {
    const hasHint = formField.hint !== undefined
    const hasDesc = formField.description !== undefined
    if (!hasHint && !hasDesc) return renderedField // don't wrap if no extra props/features to add to this field, reuturn it un-altered
    const _renderedField = <div className={styles.fieldElement}>{renderedField}</div>
    // hint specific className (extract it from the general hintProps field if set)
    const hintProps = formField.hintProps ? { ...formField.hintProps } : undefined
    let hintPropsClassName: string | undefined
    if (hintProps?.className !== undefined) {
      hintPropsClassName = hintProps.className
      delete hintProps.className
    }
    // wrapper specific className (extract it from the general wrapperProps field if set)
    const wrapperProps = formField.wrapperProps ? { ...formField.wrapperProps } : undefined
    let wrapperPropsClassName: string | undefined
    if (wrapperProps?.className !== undefined) {
      wrapperPropsClassName = wrapperProps.className
      delete wrapperProps.className
    }
    // console.log('ArkForm - renderFormFieldExtras - formField.key:', formField.key, ' wrapperProps:', wrapperProps, ' wrapperPropsClassName:', wrapperPropsClassName)
    return (
      <div key={formField.key + '_wrapper'} className={styles.fieldWrapper + (wrapperPropsClassName ? ' ' + wrapperPropsClassName : '')} {...wrapperProps}>
        {hasHint && (
          <div className={styles.fieldHintWrapper + (formField.hintInline ? ' ' + styles.fieldHintInline : '')}>
            {_renderedField}
            <div className={styles.fieldHint + (hintPropsClassName ? ' ' + hintPropsClassName : '')} {...hintProps}>{formField.hint}</div>
          </div>
        )}
        {!hasHint && (_renderedField)}
        {hasDesc && (<div className={styles.fieldDesc}>{formField.description}</div>)}
      </div>
    )
  }

  // -------

  // TESTING: catch the 'enter' key press & trigger a form submit (if the enterKeySubmits prop isn't disabled)
  // WARNING: this is currently hacky as hell :-/
  // NB: tried various different ways to get this working the 'react' & 'SUI' way(s), but kept hitting issues
  // NB: ..so have resorted to some native JS to both get & programatically 'click' the forms submit button
  // NB: ..this also requried the form key prop to be made required & all form buttons have a unique className added that contains the form key
  // NB: ..we then use that className to find the button in the DOM & the programatically trigger a click on it
  // NB: ..which seemed to be the only way to get the full SUI Form validation (& its UI warnings etc.) to properly work :(
  // main scenarios tried & failed to get working (but may have missed a way to use/setup differently):
  // - triggering our custom onFormSubmit callback with a dummy event field, but that skips the form validation & so required warnings etc.
  // - trigger a submit via a form ref using dispatchEvent, but couldn't the form ref type seems invalid (maybe the actual DOM form is a child of it though, could be worth more inspection, would that work with validation if so?)
  // - trigger our internal submit callback the buttons are normally wired to, but full form validation wasn't kicking in
  // - trigger a submit via a button ref - couldn't find a way to set a ref on buttons (once filtered to assume its the submit button)
  //   ..also tried wrapping buttons in a (soon to be depreciated) SUI Ref component but hit errors
  // - search the _formRef.current (nested) children using querySelector or getElementsByClassName to try & get the submit button(s) for a manual submission, but how do we query SUI the react styled nested data structure, doesn't seem to be a dom object?
  // - probably a few other attempts as well in between these that I'm forgetting...
  // TODO: in the future this will likely need re-working, possibly when we either move from SUI v2 to v3 (once its released), it has changes that might allow us to ref a specific button
  // TODO: ..or if we move over to either native/direct form usage or some other form lib (like formik etc.) down the line
  // TODO: will this intefere with textarea fields & anything that you'd expect to use an enter key with usually??
  handleKeyPress = (event: KeyboardEvent) => {
    // console.log('ArkForm - handleKeyPress - formKey: \'' + this.props.formKey + '\' - event: ', event)
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      // console.log('ArkForm - handleKeyPress - keydown - ENTER KEY PRESSED')
      event.preventDefault()
      // event.stopPropagation()

      if (this.props.shouldEnterKeySubmit !== undefined && this.props.shouldEnterKeySubmit() === false) {
        console.log('ArkForm - handleKeyPress - formKey: \'' + this.props.formKey + '\' - shouldEnterKeySubmit === false - ignore/skip...')
        return
      }

      // NB: although we don't currently use the _formRef, we leave it in, incase we can find a way to query the nested child buttons via it instead of the whole pages DOM...
      if (this._formRef) {
        // TESTING: find the submit button field from all the form fields data (halt if we can't figure it out)
        // NB: we make a best guess & bail if we can't find either an OKButton or another button type with no onClick handler so we know the native onFormSubmit callback is used
        const { formFields } = this.props
        if (formFields) {
          const okButtons = this.getFieldsFromFormFields(formFields, [ArkFormFieldType.OKButton]) // should only be 1, but handle multiple just incase (we just use the first one further down if multiple)
          // console.log('ArkForm - handleKeyPress - keydown - okButtons: ', okButtons)
          let submitButton = okButtons.length > 0 ? okButtons[0] : undefined
          if (!submitButton) {
            const allButtons = this.getFieldsFromFormFields(formFields, [ArkFormFieldType.OKButton, ArkFormFieldType.Button, ArkFormFieldType.CancelButton, ArkFormFieldType.DeleteButton])
            // console.log('ArkForm - handleKeyPress - keydown - allButtons: ', allButtons)
            // if no OKButton look for any button without an onClick prop & treat that as the form submit button
            // TODO: this WON'T catch a form that only uses buttons with custom onClick handling!
            // TODO: ..add a prop to the button field to be able to indicate its the submit button? (and/or indicate it should react to the 'enter' keydown??)
            const filteredOtherButtons = allButtons.filter((btn) => {
              if (btn.fieldProps !== undefined && btn.fieldProps?.onClick === undefined) {
                return true
              }
              return false
            })
            // console.log('ArkForm - handleKeyPress - keydown - filteredOtherButtons: ', filteredOtherButtons)
            if (filteredOtherButtons.length > 0) {
              submitButton = filteredOtherButtons[0]
            }
          }
          // console.log('ArkForm - handleKeyPress - keydown - submitButton: ', submitButton)

          if (submitButton) {
            // get the button DOM element & trigger a mouse click even on it programatically
            let submitEventDispatched = false
            const submitBtnClassName = '.btn_' + (this.props.formKey ?? '') + '_' + submitButton.key
            const submitBtnOuterElements = document.querySelectorAll('.form ' + submitBtnClassName) // TODO: should restrict this to JUST this form not any form on the page (although we now require a formKey & add that to each button's as a unique className so not critical)
            // console.log('ArkForm - handleKeyPress - keydown - submitBtnOuterElements: ', submitBtnOuterElements)
            if (submitBtnOuterElements && submitBtnOuterElements.length > 0) {
              const submitBtnOuterElement = submitBtnOuterElements[0]
              // console.log('ArkForm - handleKeyPress - keydown - submitBtnOuterElement: ', submitBtnOuterElement.children)
              if (submitBtnOuterElement.children && submitBtnOuterElement.children.length > 0) {
                const submitBtnElement = submitBtnOuterElement.children[0]
                // console.log('ArkForm - handleKeyPress - keydown - submitBtnElement: ', submitBtnElement)
                console.log('ArkForm - handleKeyPress - formKey: \'' + this.props.formKey + '\' - keydown - ENTER BUTTON PRESSED - SIMULATE CLICK...')
                // simulate a button click
                submitBtnElement.dispatchEvent(
                  new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1
                  })
                )
                submitEventDispatched = true
              }
            }
            if (!submitEventDispatched) {
              console.warn('ArkForm - handleKeyPress - keydown - NO SUBMIT DOM BUTTON FOUND - BUTTON PRESS IGNORED')
            }
          } else {
            console.warn('ArkForm - handleKeyPress - keydown - NO SUBMIT BUTTON FOUND - BUTTON PRESS IGNORED')
          }
        }
      }
    }
  }
}

export default ArkForm
