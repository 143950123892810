/**
 * ChannelNode
 */

import React from 'react'
import { Node } from 'react-flow-renderer'

import { pluralize } from 'src/core/utilities/strings'

import { NodeDataChannel } from '../types'
import BaseNode from './BaseNode'

import styles from './ChannelNode.module.css'

const ChannelNode = (props: Node<NodeDataChannel>) => {
  const { data } = props
  if (!data) return null
  const { colour, name, numberOfOfflinePrograms, numberOfPrograms } = data

  const popupBody = (
    <div>
      <span>{`${numberOfPrograms} ${pluralize('program', numberOfPrograms)}`}</span>
      {!!numberOfOfflinePrograms && <span className={styles.popupOffline}>{`(${numberOfOfflinePrograms} offline)`}</span>}
    </div>
  )

  return (
    <BaseNode
      iconName='channel-filled'
      popupBody={popupBody}
      popupName={name}
      popupPosition='right center'
      popupTitle='Channel'
      {...props}
    >
      <div className={styles.container}>
        <div className={styles.name}>{name}</div>
        <div className={styles.cap} style={{ backgroundColor: colour }} />
      </div>
    </BaseNode>
  )
}

export default ChannelNode
