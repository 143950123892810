import StreamhubAPIClient from './StreamhubAPIClient'

export type StreamhubAsset = { [key: string] : any }
export type StreamhubAssets = { video: Array<StreamhubAsset>, audio: Array<StreamhubAsset> }
export type StreamhubAssetFileType = 'video' | 'audio'

export default class StreamhubAssetsAPI {
  apiClient: StreamhubAPIClient

  constructor (apiClient: StreamhubAPIClient) {
    this.apiClient = apiClient
  }

  fetchAssets = async (): Promise<StreamhubAssets> => { // getSourceMediaFiles = async () => {
    try {
      const response = await this.apiClient.apiGet('/source/files?loadMeta=1', {})
      if (response.data && response.data.files) {
        const videoFilenames: Array<{ [key: string] : any }> = response.data.files.video ?? []
        const audioFilenames: Array<{ [key: string] : any }> = response.data.files.audio ?? []
        return { video: videoFilenames, audio: audioFilenames }
      }
    } catch (error) {
      console.error('StreamhubAssetsAPI - fetchAssets - error: ', error)
      throw error
    }
    throw Error('Failed to load assets') // fallback error
  }

  fetchAssetFileInfo = async (fileType: StreamhubAssetFileType, filename: string) => {
    try {
      // NB: note the use of PUT instead of GET on this endpoint, just so we can pass the args in the body instead of url
      const response = await this.apiClient.apiPut('/source/files/info', { filetype: fileType, filename: filename })
      if (response.data && response.data.file) {
        return response.data.file
      }
    } catch (error) {
      console.error('StreamhubAssetsAPI - fetchAssetFileInfo - error: ', error)
      throw error
    }
    throw Error('Failed to load asset file info') // fallback error
  }

  uploadAssetFile = async (fileType: StreamhubAssetFileType, fileFormData: FormData) => {
    try {
      const response = await this.apiClient.apiPost('/source/files/' + fileType, fileFormData)
      if (response.status === 200) return true // TODO: anything more to check or return?
    } catch (error) {
      console.error('StreamhubAssetsAPI - uploadAssetFile - error: ', error)
      throw error
    }
    throw Error('Failed to upload asset file') // fallback error
  }

  deleteAssetFile = async (filetype: StreamhubAssetFileType, filename: string) => {
    try {
      const response = await this.apiClient.apiDelete('/source/files/' + filetype + '/' + filename)
      if (response.status === 200) return true // TODO: anything more to check or return?
    } catch (error) {
      console.error('StreamhubAssetsAPI - deleteAssetFile - error: ', error)
      if (error.response && error.response.data && error.response.data.error) {
        throw Error(error.response.data.error)
      }
      throw error
    }
    throw Error('Failed to delete asset file') // fallback error
  }
}
