/**
 * UserNode
 */

import React from 'react'
import { Node } from 'react-flow-renderer'

import ArkAvatar from 'src/core/components/ArkAvatar'
import { NodeDataUser } from '../types'
import BaseNode from './BaseNode'

// import styles from './UserNode.module.css'

const UserNode = (props: Node<NodeDataUser>) => {
  const { data } = props
  if (!data) return null
  const { avatarType, guest, name } = data

  return (
    <BaseNode
      popupName={guest ? `${name} (guest)` : name}
      popupTitle='User'
      {...props}
    >
      <ArkAvatar
        name={name}
        overlayIconSize={28}
        type={avatarType}
      />
    </BaseNode>
  )
}

export default UserNode
