import React, { Component } from 'react'

import { StreamhubStreamsContext, IStreamhubStreamsContext, StreamhubStreamAction, StreamhubStreamStatus, StreamhubStreamsStatus } from '../../providers/StreamhubStreamsProvider'
import { StreamhubSourcesContext, IStreamhubSourcesContext } from '../../providers/StreamhubSourcesProvider'

import { FFMpegSource, FFMpegStream, FFMpegStreamStatus } from '../../models/StreamhubModels'

import StreamhubStreamForm, { ArkTestStreamFormMode } from './StreamhubStreamForm'
import StreamhubStreamFilterForm, { StreamhubStreamFilterValues } from './StreamhubStreamFilterForm'

import ArkButton from 'src/core/components/ArkButton'
import ArkConfirmModal from 'src/core/components/ArkConfirmModal'
import ArkLoaderView from 'src/core/components/ArkLoader'
import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'

import { formatDate, formatDateTime } from 'src/core/utilities/date'

import { Table, Modal, Image, Header, Message } from 'semantic-ui-react'

import styles from '../Streamhub.module.css'

interface IProps {
  onDataChanged?: Function
}
interface IState {
  editStream?: FFMpegStream // TODO: move this up to the streams provider? (use the current store.stream var(s) or add something dedicated? or just leave it here?)
  showFormModal: boolean
  formSaved: boolean
  formError?: Error
  showStopAllStreamsModal: boolean
  showRestartAllStreamsModal: boolean
  filterValues: StreamhubStreamFilterValues
}

class StreamhubStreamsView extends Component<IProps & IStreamhubStreamsContext & { sourcesContext: IStreamhubSourcesContext }, IState> {
  private _isMounted: boolean = false

  constructor (props: IProps & IStreamhubStreamsContext & { sourcesContext: IStreamhubSourcesContext }) {
    super(props)
    this.state = {
      showFormModal: false,
      formSaved: false,
      showStopAllStreamsModal: false,
      showRestartAllStreamsModal: false,
      filterValues: {}
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.loadData()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  // -------

  render () {
    return (<>
      {this.renderStreams()}
    </>)
  }

  // -------

  renderStreams = () => {
    const loading = this.props.store.streamsStatus === StreamhubStreamsStatus.loading
    const isFilterActive = this.isFilterActive()
    const allStreams = this.props.store.streams
    const streams = !loading && allStreams && isFilterActive ? this.filterStreams(allStreams) : allStreams
    return (
      <div className={styles.streams}>
        <div className={styles.contentHeader}>
          <div className={styles.contentHeaderMain}>
            <Header as='h2' inverted>Streams</Header>
            <div className={styles.listSummary}>
              {this.renderStreamsSummary(streams, allStreams, isFilterActive)}
            </div>
            <div className={styles.actions}>
              {this.renderStreamActions(streams, allStreams, isFilterActive)}
            </div>
          </div>
          <div className={styles.contentHeaderFilter}>
            {this.renderStreamsFilterForm()}
          </div>
        </div>

        {this.renderAllStreamsTable(streams)}

        <div style={{ marginBottom: 20 }}></div>

        {this.renderStreamFormModal()}
        {this.renderConfirmStopAllStreamsModal(streams, isFilterActive)}
        {this.renderConfirmRestartAllStreamsModal(streams, isFilterActive)}
      </div>
    )
  }

  // -------

  renderAllStreamsTable = (streams?: Array<FFMpegStream>) => {
    const loading = this.props.store.streamsStatus === StreamhubStreamsStatus.loading
    if (loading) return <ArkLoaderView message='Loading' />
    const showHeader = true
    if (this.props.store.streamsError) {
      return (
        <Message negative>
          <Message.Header>Error</Message.Header>
          <p>{this.props.store.streamsError.message ?? ''}</p>
        </Message>
      )
    }
    const isFilterActive = this.isFilterActive()
    if (!streams || streams.length === 0) {
      return (
        <Message warning>
          <Message.Header>{isFilterActive ? <>No Matching Streams</> : <>No Streams</>}</Message.Header>
        </Message>
      )
    }
    return (
      <>
        <Table celled inverted className={styles.table + ' ' + styles.streamsTable}>
          {showHeader && (
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                {/* <Table.HeaderCell>Id</Table.HeaderCell> */}
                <Table.HeaderCell>Stream Details</Table.HeaderCell>
                {/* <Table.HeaderCell>Source</Table.HeaderCell> */}
                {/* <Table.HeaderCell>Process</Table.HeaderCell> */}
                {/* <Table.HeaderCell>Duration</Table.HeaderCell> */}
                <Table.HeaderCell>Status</Table.HeaderCell>
                {/* <Table.HeaderCell>Started At</Table.HeaderCell> */}
                <Table.HeaderCell>URL</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}
          <Table.Body>
            {this.renderAllStreamsTableRows(streams)}
          </Table.Body>
        </Table>
        {/* {activeStreams.length > 0 && (
          <>
            <ArkButton inverted onClick={() => { this.restartAllStreams() }}>RESTART ALL STREAMS</ArkButton>
            <ArkButton inverted onClick={() => { this.stopAllStreams() }}>STOP ALL STREAMS</ArkButton>
          </>
        )} */}
      </>
    )
  }

  renderAllStreamsTableRows = (streams: Array<FFMpegStream>) => {
    const { selectedStream /*, streamUpdates */ } = this.props.store
    return streams.map((stream: FFMpegStream) => {
      const streamSource = this.props.sourcesContext.store.sources?.find((streamSource) => streamSource.id === stream.sourceId)
      const streamStatus = stream.status // streamUpdates?.get(stream.id)
      const streamUpdating = streamStatus === FFMpegStreamStatus.starting || streamStatus === FFMpegStreamStatus.stopping || streamStatus === FFMpegStreamStatus.restarting
      const streamUpdateError = streamStatus === FFMpegStreamStatus.error
      const streamRetrying = streamUpdateError && stream.retryEnabled && stream.retryCount < stream.retryMaxAttempts
      const streamRetriesFailed = streamUpdateError && stream.retryEnabled && stream.retryCount >= stream.retryMaxAttempts
      let streamUrl = stream.url
      // TESTING: hide any url args (mainly to hide the long passphrase, but generally just to shorten it to the key url path)
      if (streamUrl?.includes('?')) {
        const queryIndex = streamUrl.indexOf('?')
        if (queryIndex >= 0) {
          streamUrl = streamUrl.substring(0, queryIndex)
        }
      }
      // if (stream.id === 1) { console.log('renderAllStreamsTableRows - stream: ', stream) }
      const programData = stream.programData
      return (
        <Table.Row key={'stream_' + stream.id} active={selectedStream && selectedStream.id === stream.id} className={styles.streamRow} onClick={() => { this.props.actions.selectStream(stream) }}>
          <Table.Cell className={styles.streamIdAndImg} collapsing>
            <div className={styles.idRow + ' ' + styles.idStream}>Stream ID: {stream.id}</div>
            {streamSource && (<Image src={this.props.sourcesContext.actions.getSourcePreviewImageURL(streamSource, 100)} />)}
            <div className={styles.idRow + ' ' + styles.idSource}>Source: {stream.sourceId}</div>
          </Table.Cell>
          {/* <Table.Cell>{stream.id}</Table.Cell> */}
          <Table.Cell className={styles.streamDetailsCell}>
            <div className={styles.streamDetails}>
              <div className={styles.streamName}>{stream.name ? stream.name : ''}</div>
              {programData && (
                <>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>server:</div><div className={styles.detailsValue}>{programData.server}</div>
                  </div>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>org:</div><div className={styles.detailsValue}>{programData.companyName} ({programData.companyId})</div>
                  </div>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>project:</div><div className={styles.detailsValue}>{programData.projectName} ({programData.projectId})</div>
                  </div>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>program:</div><div className={styles.detailsValue}>{programData.programName} ({programData.programId})</div>
                  </div>
                  <div className={styles.detailsRow}>
                    <div className={styles.detailsTitle}>program updated:</div><div className={styles.detailsValue}>{programData.updatedAt ? formatDate(programData.updatedAt) : ''}</div>
                  </div>
                </>
              )}
            </div>
          </Table.Cell>
          {/* <Table.Cell>{stream.sourceId}</Table.Cell> */}
          {/* <Table.Cell>{stream.pid ? stream.pid : '-'}</Table.Cell> */}
          {/* <Table.Cell>{stream.duration ? stream.duration : '-'}</Table.Cell> */}{/* NB: not really using `duration` any more these days to hiding the field to free up column space */}
          <Table.Cell>
            <div className={styles.statusRow}>
              <div className={styles.statusTitle}>enabled:</div><div className={styles.statusValue}>{stream.isEnabled ? 'ENABLED' : 'DISABLED'}</div>
            </div>
            <div className={styles.statusRow}>
              <div className={styles.statusTitle}>active:</div><div className={styles.statusValue}>{streamUpdateError ? 'ERROR' : (stream.isActive ? 'ACTIVE' : 'STOPPED')}</div>
            </div>
            <div className={styles.statusRow}>
              <div className={styles.statusTitle}>status:</div><div className={styles.statusValue}>{stream.status !== undefined ? FFMpegStreamStatus[stream.status] : 'N/A'}</div>
            </div>
            {(stream.status === FFMpegStreamStatus.running) && (
              <>
                <div className={styles.statusRow}>
                  <div className={styles.statusTitle}>started:</div>
                  <div className={styles.statusValue}>
                    {stream.isActive && stream.startedAt ? formatDateTime(stream.startedAt) : (!streamRetrying && !streamRetriesFailed ? '-' : '')}
                  </div>
                </div>
                <div className={styles.statusRow}>
                  <div className={styles.statusTitle}>process:</div>
                  <div className={styles.statusValue}>{stream.pid ? stream.pid : '-'}</div>
                </div>
              </>
            )}
            {(streamRetrying || streamRetriesFailed) && (
              <div>
                {!streamRetriesFailed && (
                  <>
                    ERROR STARTING<br />
                    &gt; RETRY DELAY: {stream.retryDelay} secs<br />
                    &gt; RETRY ATTEMPTS: {stream.retryCount} / {stream.retryMaxAttempts}<br />
                  </>
                )}
                {streamRetriesFailed && (
                  <>
                    ERROR STARTING<br />
                    RETRY ATTEMPTS FAILED
                  </>
                )}
              </div>
            )}
            {/* </Table.Cell><Table.Cell> */}{/* NB: was 'started at' - now moved to the 'status' column */}
          </Table.Cell>
          <Table.Cell>{streamUrl}</Table.Cell>
          <Table.Cell className={styles.tableActions} textAlign='right' style={{ minWidth: 200, verticalAlign: 'middle' }}>
            <div className={styles.actionButtons}>
              {streamStatus === FFMpegStreamStatus.running && (
                <ArkButton size='mini' className={styles.restartButton} onClick={() => { this.restartStream(stream.id) }}>R</ArkButton>
              )}
              <div className={styles.startStopButtonWrapper}>
                <ArkButton
                  color={streamUpdateError ? 'orange' : (stream.isActive ? 'red' : 'green')}
                  size='mini'
                  loading={streamUpdating || streamRetrying}
                  style={{ display: 'block', color: 'red !important' }}
                  onClick={() => {
                    console.log('StreamhubStreamsView - renderAllStreamsTableRows - streamStatus: ', streamStatus, ' stream: ', stream)
                    if (streamStatus === FFMpegStreamStatus.running || streamRetrying) {
                      this.stopStream(stream.id)
                    } else if (streamStatus === FFMpegStreamStatus.stopped || streamStatus === FFMpegStreamStatus.error || streamStatus === FFMpegStreamStatus.initial) {
                      this.startStream(stream.id)
                    }
                  }}
                  className={streamUpdateError ? styles.buttonError : ''}
                >
                  {streamStatus === FFMpegStreamStatus.running ? 'STOP' : 'START'}
                </ArkButton>
                {streamUpdateError && (
                  <div className={styles.startError}>
                    ERROR
                    {stream.errorMsg && (<div className={styles.startErrorMsg}>{stream.errorMsg}</div>)}
                  </div>
                )}
              </div>
              <ArkButton size='mini' className={styles.buttonLeftPad} onClick={() => { this.showEditStreamModal(stream.id) }}>EDIT</ArkButton>
              <ArkManagerDeleteButton
                className={styles.buttonLeftPad}
                itemId={stream.id}
                itemName={'Stream ' + stream.id}
                itemTypeName='Stream'
                buttonTitle='X'
                onDelete={this.onDeleteStream}
                onDeleteComplete={this.onDeleteStreamComplete}
                fluid={false}
                size='mini'
                disabled={stream.isActive}
                style={{ display: 'inline' }}
                buttonStyle={{ fontSize: '12px', padding: '8px 10px', marginLeft: 6 }}
              />
            </div>
          </Table.Cell>
        </Table.Row>
      )
    })
  }

  // -------

  renderStreamsSummary = (currentStreams?: Array<FFMpegStream>, allStreams?: Array<FFMpegStream>, isFilterActive: boolean = false) => {
    const currentStreamsCount = currentStreams && currentStreams.length > 0 ? currentStreams.length : 0
    const allStreamsCount = allStreams && allStreams.length > 0 ? allStreams.length : 0
    const currentActiveStreamsCount = currentStreams ? currentStreams.filter((s) => s.isActive).length : 0
    const allActiveStreamsCount = allStreams ? allStreams.filter((s) => s.isActive).length : 0
    return (
      <>
        Showing: {isFilterActive ? <>{currentStreamsCount} / </> : null}{allStreamsCount} stream{allStreamsCount !== 1 ? 's' : ''}
        &nbsp;({isFilterActive ? <>{currentActiveStreamsCount} / </> : null}{allActiveStreamsCount} active)
      </>
    )
  }

  renderStreamActions = (currentStreams?: Array<FFMpegStream>, allStreams?: Array<FFMpegStream>, isFilterActive: boolean = false) => {
    // NB: currentStreams will be the same as `allStreams` when no filter is applied, so we can use it directly here for all the button states
    const currentActiveStreamsCount = currentStreams ? currentStreams.filter((s) => s.isActive).length : 0
    return (
      <>
        <ArkButton size='mini' className={styles.addStream} onClick={() => { this.showAddStreamModal() }}>ADD STREAM</ArkButton>
        {/* {currentActiveStreamsCount > 0 && ( */}
        <>
          <div className={styles.divider}></div>
          <ArkButton
            color='orange'
            size='mini'
            className={styles.restartAllStreams}
            onClick={() => { this.setState({ showRestartAllStreamsModal: true }) }}
            disabled={currentActiveStreamsCount === 0}
          >
            RESTART {isFilterActive ? <>{currentActiveStreamsCount}</> : <>ALL ({currentActiveStreamsCount})</>} STREAM{currentActiveStreamsCount !== 1 ? 'S' : ''}
          </ArkButton>
          <ArkButton
            color='red'
            size='mini'
            className={styles.stopAllStreams}
            onClick={() => { this.setState({ showStopAllStreamsModal: true }) }}
            disabled={currentActiveStreamsCount === 0}
          >
            STOP {isFilterActive ? <>{currentActiveStreamsCount}</> : <>ALL ({currentActiveStreamsCount})</>} STREAM{currentActiveStreamsCount !== 1 ? 'S' : ''}
          </ArkButton>
        </>
        {/* )} */}
        <div className={styles.divider}></div>
        <ArkButton color='blue' size='mini' className={styles.refreshBtn} onClick={async () => { this.onDataChanged() }}>REFRESH</ArkButton>
      </>
    )
  }

  // -------

  renderStreamsFilterForm = () => {
    const { filterValues } = this.state
    return (
      <StreamhubStreamFilterForm
        autoComplete={false}
        filterValues={filterValues}
        onFilterChange={(fieldKey: string, value?: string) => {
          console.log('StreamhubStreamsView - renderStreamsFilterForm - onFilterChange - fieldKey:', fieldKey, ' value:', value)
          const prevFilterValues = this.state.filterValues
          const newFilterValues: StreamhubStreamFilterValues = { ...prevFilterValues }
          switch (fieldKey) {
            case 'name': newFilterValues.name = value; break
            case 'url': newFilterValues.url = value; break
            case 'server': newFilterValues.server = value; break
            case 'org': newFilterValues.org = value; break
            case 'project': newFilterValues.project = value; break
            case 'program': newFilterValues.program = value; break
            default: {
              console.warn('StreamhubStreamsView - renderStreamsFilterForm - onFilterChange - ERROR: UNHANDLED FIELD KEY - fieldKey:', fieldKey, ' value:', value)
              return
            }
          }
          this.setState({ filterValues: newFilterValues })
        }}
        onFilterClear={() => {
          this.setState({ filterValues: {} })
        }}
      />
    )
  }

  isFilterActive = () => {
    const { filterValues } = this.state
    if (filterValues.name !== undefined) return true
    if (filterValues.url !== undefined) return true
    if (filterValues.server !== undefined) return true
    if (filterValues.org !== undefined) return true
    if (filterValues.project !== undefined) return true
    if (filterValues.program !== undefined) return true
    return false
  }

  filterStreams = (streams: Array<FFMpegStream>) => {
    const { filterValues } = this.state
    if (!this.isFilterActive()) return streams
    const filteredStreams: Array<FFMpegStream> = []
    for (const stream of streams) {
      const nameOk = (filterValues.name !== undefined ? (stream.name !== undefined && stream.name.toLowerCase().indexOf(filterValues.name.toLowerCase()) >= 0) : true)
      const urlOk = (filterValues.url !== undefined ? (stream.url !== undefined && stream.url.toLowerCase().indexOf(filterValues.url.toLowerCase()) >= 0) : true)
      const serverOk = (filterValues.server !== undefined ? (stream.programData !== undefined && stream.programData.server !== undefined && stream.programData.server.toLowerCase().indexOf(filterValues.server.toLowerCase()) >= 0) : true)
      const orgOk = (filterValues.org !== undefined ? (stream.programData !== undefined && stream.programData.companyName !== undefined && stream.programData.companyName.toLowerCase().indexOf(filterValues.org.toLowerCase()) >= 0) : true)
      const projectOk = (filterValues.project !== undefined ? (stream.programData !== undefined && stream.programData.projectName !== undefined && stream.programData.projectName.toLowerCase().indexOf(filterValues.project.toLowerCase()) >= 0) : true)
      const programOk = (filterValues.program !== undefined ? (stream.programData !== undefined && stream.programData.programName !== undefined && stream.programData.programName.toLowerCase().indexOf(filterValues.program.toLowerCase()) >= 0) : true)
      if (nameOk && urlOk && serverOk && orgOk && projectOk && programOk) {
        filteredStreams.push(stream)
      }
    }
    return filteredStreams
  }

  // -------

  loadData = async () => {
    this.props.actions.fetchStreams()
    this.props.sourcesContext.actions.fetchSources()
  }

  onDataChanged = () => {
    this.loadData()
    if (this.props.onDataChanged) this.props.onDataChanged()
  }

  // -------

  showAddStreamModal = () => {
    this.setState({ editStream: undefined, showFormModal: true, formSaved: false })
  }

  showEditStreamModal = (streamId: number) => {
    const stream = this.getStream(streamId)
    this.setState({ editStream: stream, showFormModal: true, formSaved: false })
  }

  hideStreamModal = () => {
    this.setState({ editStream: undefined, showFormModal: false })
  }

  // -------

  // ArkManagerDeleteButton onDelete callback, return true on success, or throw an error to have it displayed by the delete modal
  onDeleteStream = async (streamId: number) => {
    await this.props.actions.deleteStreamWithId(streamId)
    if (this.props.store.streamAction === StreamhubStreamAction.delete) {
      if (this.props.store.streamStatus === StreamhubStreamStatus.done) {
        return true
      } else if (this.props.store.streamStatus === StreamhubStreamStatus.error && this.props.store.streamError) {
        throw this.props.store.streamError // throw the error so the ArkManagerDeleteButton handling can catch & display it
      }
    }
    return false
  }

  // ArkManagerDeleteButton onDeleteComplete callback, called once the success result has been dismissed by the user
  onDeleteStreamComplete = () => {
    this.onDataChanged()
  }

  // -------

  renderStreamFormModal = () => {
    return (
      <Modal
        onClose={() => this.hideStreamModal()}
        /* onOpen={() => this.setState({ showProjectFormModal: true })} */
        open={this.state.showFormModal}
        trigger={null /* <ArkButton>Show Modal</ArkButton> */}
        closeOnEscape={true}
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <Modal.Content>
          <StreamhubStreamForm
            mode={this.state.editStream ? ArkTestStreamFormMode.Edit : ArkTestStreamFormMode.Add}
            stream={this.state.editStream}
            streamSources={this.props.sourcesContext.store.sources ?? []}
            error={this.state.formError}
            onGetStreamSourcePreviewImageURL={(source: FFMpegSource, width?: number) => {
              return this.props.sourcesContext.actions.getSourcePreviewImageURL(source, width)
            }}
            onCancel={() => { this.hideStreamModal() }}
            onSave={async (data: any) => {
              if (this.state.editStream) {
                await this.props.actions.updateStream(
                  this.state.editStream.id,
                  data.sourceId, data.name,
                  data.url,
                  data.duration,
                  data.retryEnabled,
                  data.retryDelay,
                  data.retryMaxAttempts,
                  data.programData
                )
              } else {
                await this.props.actions.createStream(
                  data.sourceId,
                  data.name,
                  data.url,
                  data.duration,
                  data.retryEnabled,
                  data.retryDelay,
                  data.retryMaxAttempts,
                  data.programData
                )
              }
              // NB: ignore the source provider results if they aren't for the performed action
              if (this.props.store.streamAction === StreamhubStreamAction.create || this.props.store.streamAction === StreamhubStreamAction.update) {
                if (this.props.store.streamStatus === StreamhubStreamStatus.done) {
                  // TODO: change to call the local onDataChanged, but add an optional stream arg to it, so we can pass it directly in the callback
                  this.loadData() // reload sources
                  if (this.props.onDataChanged) this.props.onDataChanged(this.props.store.stream)
                  if (this._isMounted) this.setState({ formSaved: true, editStream: this.props.store.stream })
                  return true
                } else if (this.props.store.streamError) {
                  if (this._isMounted) this.setState({ formError: this.props.store.streamError, formSaved: false })
                  return false
                }
              }
              return false
              // NB: we don't auto close/hide the modal form when it saves, leave it up for a success message to show & the user to dismiss manually
            }}
            onDelete={() => {
              // trigger a data re-load so the deleted one no longer shows
              // this.loadPrograms()
              this.onDataChanged()
              // NB: we don't auto close/hide the modal form when it deletes, leave it up for a delete success message to show & the user to dismiss manually
            }}
            onClose={() => { this.hideStreamModal() }}
          />
        </Modal.Content>
      </Modal>
    )
  }

  // -------

  renderConfirmStopAllStreamsModal = (currentStreams?: Array<FFMpegStream>, isFilterActive: boolean = false) => {
    const currentActiveStreams = currentStreams ? currentStreams.filter((s) => s.isActive) : undefined
    const currentActiveStreamsCount = currentActiveStreams ? currentActiveStreams.length : 0
    return (
      <ArkConfirmModal
        show={this.state.showStopAllStreamsModal}
        title={<>Stop {isFilterActive ? <>{currentActiveStreamsCount} (filtered)</> : <>ALL {currentActiveStreamsCount}</>} stream{currentActiveStreamsCount !== 1 ? 's' : ''}?</>}
        message={<>
          <p>Are you sure you want to STOP {isFilterActive ? <>{currentActiveStreamsCount} (filtered)</> : <>ALL {currentActiveStreamsCount}</>} stream{currentActiveStreamsCount !== 1 ? 's' : ''}?</p>
        </>}
        onCancel={() => this.setState({ showStopAllStreamsModal: false })}
        onClose={() => this.setState({ showStopAllStreamsModal: false })}
        onConfirm={() => {
          this.setState({ showStopAllStreamsModal: false })
          if (isFilterActive) {
            if (currentActiveStreams) {
              const streamIds = currentActiveStreams.map((s) => s.id)
              this.stopStreams(streamIds)
            } else {
              // TODO: handle/flag if we get here?
            }
          } else {
            this.stopAllActiveStreams()
          }
        }}
      />
    )
  }

  renderConfirmRestartAllStreamsModal = (currentStreams?: Array<FFMpegStream>, isFilterActive: boolean = false) => {
    const currentActiveStreams = currentStreams ? currentStreams.filter((s) => s.isActive) : undefined
    const currentActiveStreamsCount = currentActiveStreams ? currentActiveStreams.length : 0
    return (
      <ArkConfirmModal
        show={this.state.showRestartAllStreamsModal}
        title={<>Restart {isFilterActive ? <>{currentActiveStreamsCount} (filtered)</> : <>ALL {currentActiveStreamsCount}</>} stream{currentActiveStreamsCount !== 1 ? 's' : ''}?</>}
        message={<>
          <p>Are you sure you want to RESTART {isFilterActive ? <>{currentActiveStreamsCount} (filtered)</> : <>ALL {currentActiveStreamsCount}</>} stream{currentActiveStreamsCount !== 1 ? 's' : ''}?</p>
        </>}
        onCancel={() => this.setState({ showRestartAllStreamsModal: false })}
        onClose={() => this.setState({ showRestartAllStreamsModal: false })}
        onConfirm={() => {
          this.setState({ showRestartAllStreamsModal: false })
          if (isFilterActive) {
            if (currentActiveStreams) {
              const streamIds = currentActiveStreams.map((s) => s.id)
              this.restartStreams(streamIds)
            } else {
              // TODO: handle/flag if we get here?
            }
          } else {
            this.restartAllActiveStreams()
          }
        }}
      />
    )
  }

  // -------

  getStream = (streamId: number) => {
    if (this.props.store.streams && this.props.store.streams.length > 0) {
      return this.props.store.streams.find((stream) => stream.id === streamId)
    }
    return undefined
  }

  // getActiveStreams = (): Array<FFMpegStream> => {
  //   const activeStreams: Array<FFMpegStream> = []
  //   if (this.props.store.streams && this.props.store.streams.length > 0) {
  //     for (const stream of this.props.store.streams) {
  //       if (stream.isActive) {
  //         activeStreams.push(stream)
  //       }
  //     }
  //   }
  //   return activeStreams
  // }

  // -------

  startStream = async (streamId: number) => {
    console.log('AdminTestStreamsPage - startStream - streamId: ', streamId)
    try {
      const startResult = await this.props.actions.startStream(streamId)
      if (startResult) {
        // TODO: show a success message
        // this.onDataChanged() // NB: provider now auto updates the relevant stream entry from the response without an extra api call
      }
    } catch (error) {
      console.error('AdminTestStreamsPage - startStream - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  restartStream = async (streamId: number) => {
    try {
      const restartResult = await this.props.actions.restartStream(streamId)
      console.log('AdminTestStreamsPage - restartStream - restartResult: ', restartResult)
    } catch (error) {
      console.error('AdminTestStreamsPage - restartStream - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  restartStreams = async (streamIds: Array<number>) => {
    try {
      // TODO: check & handle if no streamIds?
      const restartResults = await this.props.actions.restartStreams(streamIds)
      console.log('AdminTestStreamsPage - restartStreams - restartResults: ', restartResults)
    } catch (error) {
      console.error('AdminTestStreamsPage - restartStreams - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  restartAllActiveStreams = async () => {
    try {
      const restartResult = await this.props.actions.restartAllActiveStreams()
      if (restartResult) {
        // TODO: show a success message
        this.onDataChanged()
      }
    } catch (error) {
      console.error('AdminTestStreamsPage - restartAllStreams - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  stopStream = async (streamId: number) => {
    try {
      const stopResult = await this.props.actions.stopStream(streamId)
      if (stopResult) {
        // TODO: show a success message
        // this.onDataChanged() // NB: provider now auto updates the relevant stream entry from the response without an extra api call
      }
    } catch (error) {
      console.error('AdminTestStreamsPage - stopStream - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  stopStreams = async (streamIds: Array<number>) => {
    try {
      // TODO: check & handle if no streamIds?
      const stopResults = await this.props.actions.stopStreams(streamIds)
      if (stopResults) {
        // TODO: show a success message
        // this.onDataChanged() // NB: provider now auto updates the relevant stream entry from the response without an extra api call
      }
    } catch (error) {
      console.error('AdminTestStreamsPage - stopStreams - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  stopAllActiveStreams = async () => {
    try {
      const stopResult = await this.props.actions.stopAllActiveStreams()
      if (stopResult) {
        // TODO: show a success message
        this.onDataChanged()
      }
    } catch (error) {
      console.error('AdminTestStreamsPage - stopAllStreams - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }

  // -------

  startQuickStream = async (sourceId: number, url: string) => {
    try {
      const startResult = await this.props.actions.startQuickStream(sourceId, url)
      if (startResult) {
        // TODO: show a success message
        this.onDataChanged()
      }
    } catch (error) {
      console.error('AdminTestStreamsPage - startQuickStream - error: ', error)
      // this.setState({ }) // TODO: show error in the UI
    }
  }
  // TODO: stopQuickStream
  // TODO: stopAllQuickStreams

  // -------
}

export class StreamhubStreamsViewWithContext extends Component<IProps, {}> {
  render () {
    return (
      <StreamhubStreamsContext.Consumer>
        {(streamsContext) => { // { status }
          if (streamsContext === null) {
            throw new Error('StreamhubStreamConsumer must be used within a StreamhubStreamProvider')
          }
          // console.log('StreamhubStreamsViewWithContext - render - StreamhubStreamsContext.Consumer - streamsContext.store.streams: ', streamsContext.store.streams)
          return (
            <StreamhubSourcesContext.Consumer>
              {(sourcesContext) => { // { status }
                if (sourcesContext === null) {
                  throw new Error('StreamhubSourceConsumer must be used within a StreamhubSourcesProvider')
                }
                // console.log('StreamhubStreamsViewWithContext - render - StreamhubSourcesContext.Consumer - sourcesContext.store.sources: ', sourcesContext.store.sources)
                return (
                  <StreamhubStreamsView
                    {...this.props}
                    {...streamsContext}
                    {...{ sourcesContext: sourcesContext }}
                  />
                )
              }}
            </StreamhubSourcesContext.Consumer>
          )
        }}
      </StreamhubStreamsContext.Consumer>
    )
  }
}
export default StreamhubStreamsViewWithContext
export { StreamhubStreamsView }
