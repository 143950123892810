import React, { useContext } from 'react'

import { withUserContext, IUserMultiContext } from '.'
import { Company, CompanyUser, UserCompanyRole, Project, CompanyLoginService, CompanyLoginServiceConfigOptions, CompanyGroup, GroupUserOperation, CompanyVideoEngine, ProjectUser } from '../models'
import { IProjectAddData, IProjectUpdateData } from '../models/project'

import ServerAPIClient from '../services/ServerAPIClient'
import ServerCompanyAPI, { CompanyInfoUpdateResult } from '../services/ServerCompanyAPI'
import ServerProjectAPI from '../services/ServerProjectAPI'
import ServerUserAPI from '../services/ServerUserAPI' // NB: for site-admin use only (see prop declaration comments further down for more info)
import ServerGroupAPI from '../services/ServerGroupAPI'
import ServerVideoEngineAPI from '../services/ServerVideoEngineAPI'
import { ICompanyVideoEngineUpdateData } from '../models/video_engine'
import { ICompanyGroupAddData, ICompanyGroupUpdateData, ProjectGroup } from '../models/group'

export type { CompanyInfoUpdateResult }

export interface ICompanyAdminStore {
}

export interface ICompanyAdminActions {
  // company users
  getCompanyUsers: (companyId: number) => Promise<Array<CompanyUser> | null>
  inviteUserToCompany: (companyId: number, email: string, firstName?: string, lastName?: string, companyRole?: UserCompanyRole) => Promise<boolean>
  reinviteUserToCompany: (companyId: number, userId: number) => Promise<boolean>
  removeUserFromCompany: (companyId: number, userId: number) => Promise<boolean>
  updateUserCompanyRole: (companyId: number, userId: number, companyRole: UserCompanyRole) => Promise<boolean>
  // company groups
  getCompanyGroups: (companyId: number) => Promise<Array<CompanyGroup> | null>
  getCompanyGroup: (companyId: number, groupId: number) => Promise<CompanyGroup | null>
  addCompanyGroup: (companyId: number, groupData: ICompanyGroupAddData) => Promise<CompanyGroup>
  updateCompanyGroup: (companyId: number, groupId: number, groupData: ICompanyGroupUpdateData) => Promise<CompanyGroup>
  deleteCompanyGroup: (companyId: number, groupId: number) => Promise<boolean>
  // company group access/status
  updateCompanyGroupAccessEnabled: (companyId: number, groupId: number, accessEnabled: boolean) => Promise<boolean>
  // company group users
  getCompanyGroupUsers: (companyId: number, groupId: number) => Promise<Array<CompanyUser> | null>
  updateCompanyGroupUsers: (companyId: number, groupId: number, operations: Array<GroupUserOperation>) => Promise<boolean | Map<number, any>>
  // company group projects (visibility)
  getCompanyGroupProjectVisibility: (companyId: number, groupId: number) => Promise<Array<Project>>
  updateCompanyGroupProjectVisibility: (companyId: number, groupId: number, projectId: number, visible: boolean) => Promise<boolean>
  // company projects
  getAllCompanyProjects: (companyId: number) => Promise<Array<Project>>
  addCompanyProject: (companyId: number, projectData: IProjectAddData) => Promise<Project>
  updateCompanyProject: (companyId: number, projectId: number, projectData: IProjectUpdateData) => Promise<Project>
  deleteCompanyProject: (companyId: number, projectId: number) => Promise<boolean>
  // company project groups
  getCompanyProjectGroups: (companyId: number, projectId: number) => Promise<Array<ProjectGroup>>
  // company project group users
  getCompanyProjectGroupUsers: (companyId: number, projectId: number, groupId: number) => Promise<Array<ProjectUser> | null>
  updateCompanyProjectGroupUsers: (companyId: number, projectId: number, groupId: number, operations: Array<GroupUserOperation>) => Promise<boolean | Map<number, any>>
  // company info/settings
  getCompanyInfo: (companyId: number) => Promise<Company | null>
  updateCompanyInfo: (companyId: number, data: {[key: string]: any}) => Promise<CompanyInfoUpdateResult>
  syncCompanyTranscoderSettingsToAllProjects: (companyId: number, force?: boolean) => Promise<boolean>
  // company login services (SSO providers)
  getCompanyLoginServices: (companyId: number) => Promise<Array<CompanyLoginService>>
  addCompanyLoginService: (companyId: number, loginServiceId: number, configOptions?: CompanyLoginServiceConfigOptions) => Promise<CompanyLoginService>
  updateCompanyLoginService: (companyId: number, loginServiceInstanceId: number, configOptions: CompanyLoginServiceConfigOptions, enabled?: boolean) => Promise<CompanyLoginService>
  enableDisableCompanyLoginService: (companyId: number, loginServiceInstanceId: number, enabled: boolean) => Promise<CompanyLoginService>
  deleteCompanyLoginService: (companyId: number, loginServiceInstanceId: number) => Promise<boolean>
  // company video engines
  getAllCompanyVideoEngines: (companyId: number) => Promise<Array<CompanyVideoEngine>>
  updateCompanyVideoEngine: (companyId: number, videoEngineId: number, videoEngineData: ICompanyVideoEngineUpdateData) => Promise<CompanyVideoEngine>
  // user admin (site wide) - site admin controls (NB: these will move to the SiteAdminPriovider when we have an 'admin > users' section, waiting on the api to support it)
  siteAdminDisableUser2FA: (userId: number) => Promise<boolean>
  siteAdminDeleteUser: (userId: number) => Promise<boolean>
}

export interface ICompanyAdminContext {
  actions: ICompanyAdminActions
  store: ICompanyAdminStore
}

export interface ICompanyAdminMultiContext {
  companyAdminContext: ICompanyAdminContext
}

export const CompanyAdminContext = React.createContext<ICompanyAdminContext>({} as ICompanyAdminContext)

export const useCompanyAdmin = () => useContext(CompanyAdminContext)

export interface CompanyAdminProviderProps extends IUserMultiContext {
  apiClient: ServerAPIClient
  companyApi?: ServerCompanyAPI
  projectsApi?: ServerProjectAPI
  userAPI?: ServerUserAPI // NB: for site-admin use only - can be removed once site wide user features are moved (back) to the SiteAdminProvider (once the api supports site-admin user listing so we can keep it all in an site-admin section)
  groupApi?: ServerGroupAPI
  videoEngineAPI?: ServerVideoEngineAPI // for org-admin access to available video-engine servers
}
export interface CompanyAdminProviderState extends ICompanyAdminStore {
  companyApi: ServerCompanyAPI
  projectsApi: ServerProjectAPI
  userAPI: ServerUserAPI // NB: for site-admin use only (see comments above)
  groupApi: ServerGroupAPI
  videoEngineAPI: ServerVideoEngineAPI // for org-admin access to available video-engine servers
}

class CompanyAdminProvider extends React.Component<CompanyAdminProviderProps, CompanyAdminProviderState> {
  constructor (props: CompanyAdminProviderProps) {
    super(props)
    this.state = {
      companyApi: props.companyApi ?? new ServerCompanyAPI(this.props.apiClient),
      projectsApi: props.projectsApi ?? new ServerProjectAPI(this.props.apiClient),
      userAPI: props.userAPI ?? new ServerUserAPI(this.props.apiClient),
      groupApi: props.groupApi ?? new ServerGroupAPI(this.props.apiClient),
      videoEngineAPI: props.videoEngineAPI ?? new ServerVideoEngineAPI(this.props.apiClient)
    }
  }

  // -------

  componentDidMount () {
  }

  // -------

  getCompanyUsers = async (companyId: number): Promise<Array<CompanyUser> | null> =>
    this.state.companyApi.getCompanyUsers(companyId)

  inviteUserToCompany = async (companyId: number, email: string, firstName?: string, lastName?: string, companyRole?: UserCompanyRole): Promise<boolean> =>
    this.state.companyApi.inviteUserToCompany(companyId, email, firstName, lastName, companyRole)

  reinviteUserToCompany = async (companyId: number, userId: number): Promise<boolean> =>
    this.state.companyApi.reinviteUserToCompany(companyId, userId)

  removeUserFromCompany = async (companyId: number, userId: number): Promise<boolean> =>
    this.state.companyApi.removeUserFromCompany(companyId, userId)

  updateUserCompanyRole = async (companyId: number, userId: number, companyRole: UserCompanyRole): Promise<boolean> =>
    this.state.companyApi.updateUserCompanyRole(companyId, userId, companyRole)

  // -------

  getCompanyGroups = async (companyId: number): Promise<Array<CompanyGroup> | null> =>
    this.state.groupApi.getCompanyGroups(companyId)

  getCompanyGroup = async (companyId: number, groupId: number): Promise<CompanyGroup | null> =>
    this.state.groupApi.getCompanyGroup(companyId, groupId)

  addCompanyGroup = async (companyId: number, groupData: ICompanyGroupAddData): Promise<CompanyGroup> =>
    this.state.groupApi.addCompanyGroup(companyId, groupData)

  updateCompanyGroup = async (companyId: number, groupId: number, groupData: ICompanyGroupUpdateData): Promise<CompanyGroup> =>
    this.state.groupApi.updateCompanyGroup(companyId, groupId, groupData)

  deleteCompanyGroup = async (companyId: number, groupId: number): Promise<boolean> =>
    this.state.groupApi.deleteCompanyGroup(companyId, groupId)

  // -------

  updateCompanyGroupAccessEnabled = async (companyId: number, groupId: number, accessEnabled: boolean): Promise<boolean> => {
    await this.state.groupApi.updateCompanyGroupAccessEnabled(companyId, groupId, accessEnabled)
    return true // NB: will throw an error if the api call fails
  }

  // -------

  // company group users
  getCompanyGroupUsers = async (companyId: number, groupId: number): Promise<Array<CompanyUser> | null> =>
    this.state.groupApi.getCompanyGroupUsers(companyId, groupId)

  updateCompanyGroupUsers = async (companyId: number, groupId: number, operations: Array<GroupUserOperation>): Promise<boolean | Map<number, any>> =>
    this.state.groupApi.updateCompanyGroupUsers(companyId, groupId, operations)

  // -------

  // company group projects (visibility)
  getCompanyGroupProjectVisibility = async (companyId: number, groupId: number): Promise<Array<Project>> =>
    this.state.groupApi.getCompanyGroupProjectVisibility(companyId, groupId)

  updateCompanyGroupProjectVisibility = async (companyId: number, groupId: number, projectId: number, visible: boolean): Promise<boolean> =>
    this.state.groupApi.updateCompanyGroupProjectVisibility(companyId, groupId, projectId, visible)

  // -------

  getAllCompanyProjects = async (companyId: number): Promise<Array<Project>> =>
    this.state.projectsApi.getAllCompanyProjects(companyId)

  addCompanyProject = async (companyId: number, projectData: IProjectAddData): Promise<Project> => {
    // NB: throws an exception if the api call fails
    const project = await this.state.projectsApi.addCompanyProject(companyId, projectData)
    if (project) {
      // trigger a user data update so changes are available (e.g. to update the projects dropdown in the header etc.)
      // TODO: only trigger the update if this user is assigned to the project being created/edited? (if this is admin/god?)
      // TODO: only trigger a partial reload? (NB: was originally calling Server loadUserSelections in the pre-provider code)
      await this.props.userContext.actions.reloadUserData()
    }
    return project
  }

  updateCompanyProject = async (companyId: number, projectId: number, projectData: IProjectUpdateData): Promise<Project> => {
    // NB: throws an exception if the api call fails
    const project = await this.state.projectsApi.updateCompanyProject(companyId, projectId, projectData)
    if (project) {
      // trigger a user data update so changes are available (e.g. to update the projects dropdown in the header etc.)
      // TODO: only trigger the update if this user is assigned to the project being created/edited? (if this is admin/god?)
      // TODO: only trigger a partial reload? (NB: was originally calling Server loadUserSelections in the pre-provider code)
      // NB: the updateCompanyProject api call does return the new project data model, but doesn't currently return it & we just trigger a full user data reload so it updates across all loaded instances (as is likely loaded in a few places that all need updating)
      await this.props.userContext.actions.reloadUserData()
    }
    return project
  }

  deleteCompanyProject = async (companyId: number, projectId: number): Promise<boolean> => {
    // TODO: reload user data for deletes as well?
    return await this.state.projectsApi.deleteCompanyProject(companyId, projectId)
  }

  // -------

  // company project groups
  getCompanyProjectGroups = async (companyId: number, projectId: number): Promise<Array<ProjectGroup>> =>
    this.state.groupApi.getProjectGroups(companyId, projectId)

  // -------

  // company project group users
  getCompanyProjectGroupUsers = async (companyId: number, projectId: number, groupId: number): Promise<Array<ProjectUser> | null> =>
    this.state.groupApi.getProjectGroupUsers(companyId, projectId, groupId)

  updateCompanyProjectGroupUsers = async (companyId: number, projectId: number, groupId: number, operations: Array<GroupUserOperation>): Promise<boolean | Map<number, any>> => {
    const result = await this.state.groupApi.updateProjectGroupUsers(companyId, projectId, groupId, operations)
    // trigger a user data update so changes are available in the viewer straight away
    // NB: only trigger the update if this user was in the group>user changes applied
    if (result && operations.length > 0 && this.props.userContext.store.user?.id && operations.find((op) => op.user_id === this.props.userContext.store.user?.id)) {
      // TODO: this currently forces the whole UI to update, & reset the project groups admin page this is triggered from, find a way to not do that! <<<
      await this.props.userContext.actions.reloadUserData() // TODO: ideally only reload of related user data (NB: was originally calling Server loadUserSelections in the pre-provider code)
    }
    return result
  }

  // -------

  getCompanyInfo = async (companyId: number): Promise<Company | null> => {
    return await this.state.companyApi.getCompanyInfo(companyId)
  }

  updateCompanyInfo = async (companyId: number, data: {[key: string]: any}): Promise<CompanyInfoUpdateResult> => {
    const result = await this.state.companyApi.updateCompanyInfo(companyId, data)
    if (result.company) {
      // trigger a user data update so changes are available (to update the company projects sync status if its changed, & force 2fa value etc.)
      // TODO: only trigger the update if certain fields have changed, e.g transcoder related fields that cause the company projects sync flag to change, & force 2fa flag etc.
      // TODO: ideally only update/reload the projects for the selected/current company not all user data (we don't have an easy way to only update certain user selection data so triggering a full update for now)
      await this.props.userContext.actions.reloadUserData()
    }
    return result
  }

  syncCompanyTranscoderSettingsToAllProjects = async (companyId: number, force?: boolean): Promise<boolean> => {
    const result = await this.state.companyApi.syncCompanyTranscoderSettingsToAllProjects(companyId, force)
    if (result) {
      // trigger a user data update so changes are available (to update the company projects sync status if its changed)
      // TODO: only trigger the update if certain transcoder fields have changed that cause the company projects sync flag to change?
      // TODO: ideally only update/reload the projects for the selected/current company not all user data (we don't have an easy way to only update certain user selection data so triggering a full update for now)
      await this.props.userContext.actions.reloadUserData()
    }
    return result
  }

  // -------

  // company login methods (SSO providers)
  getCompanyLoginServices = async (companyId: number): Promise<Array<CompanyLoginService>> =>
    await this.state.companyApi.getCompanyLoginServices(companyId)

  addCompanyLoginService = async (companyId: number, loginServiceId: number, configOptions?: CompanyLoginServiceConfigOptions): Promise<CompanyLoginService> =>
    await this.state.companyApi.addCompanyLoginService(companyId, loginServiceId, configOptions)

  updateCompanyLoginService = async (companyId: number, loginServiceInstanceId: number, configOptions: CompanyLoginServiceConfigOptions, enabled?: boolean): Promise<CompanyLoginService> =>
    await this.state.companyApi.updateCompanyLoginService(companyId, loginServiceInstanceId, configOptions, enabled)

  enableDisableCompanyLoginService = async (companyId: number, loginServiceInstanceId: number, enabled: boolean): Promise<CompanyLoginService> =>
    await this.state.companyApi.enableDisableCompanyLoginService(companyId, loginServiceInstanceId, enabled)

  deleteCompanyLoginService = async (companyId: number, loginServiceInstanceId: number): Promise<boolean> =>
    await this.state.companyApi.deleteCompanyLoginService(companyId, loginServiceInstanceId)

  // -------

  getAllCompanyVideoEngines = async (companyId: number): Promise<Array<CompanyVideoEngine>> =>
    this.state.videoEngineAPI.getAllCompanyVideoEngines(companyId)

  updateCompanyVideoEngine = async (companyId: number, videoEngineId: number, videoEngineData: ICompanyVideoEngineUpdateData): Promise<CompanyVideoEngine> =>
    this.state.videoEngineAPI.updateCompanyVideoEngine(companyId, videoEngineId, videoEngineData)

  // -------
  // user admin (site wide)

  siteAdminDisableUser2FA = async (userId: number): Promise<boolean> => {
    const result = await this.state.userAPI.disableUser2FA(userId)
    return result
  }

  // WARNING: this completely removes/wipes the user from the system (site-admin/god use only)
  siteAdminDeleteUser = async (userId: number): Promise<boolean> => {
    const result = await this.state.userAPI.deleteUser(userId)
    return result
  }

  // -------

  actions: ICompanyAdminActions = {
    // company users
    getCompanyUsers: this.getCompanyUsers,
    inviteUserToCompany: this.inviteUserToCompany,
    reinviteUserToCompany: this.reinviteUserToCompany,
    removeUserFromCompany: this.removeUserFromCompany,
    updateUserCompanyRole: this.updateUserCompanyRole,
    // company groups
    getCompanyGroups: this.getCompanyGroups,
    getCompanyGroup: this.getCompanyGroup,
    addCompanyGroup: this.addCompanyGroup,
    updateCompanyGroup: this.updateCompanyGroup,
    deleteCompanyGroup: this.deleteCompanyGroup,
    // company group access/status
    updateCompanyGroupAccessEnabled: this.updateCompanyGroupAccessEnabled,
    // company group users
    getCompanyGroupUsers: this.getCompanyGroupUsers,
    updateCompanyGroupUsers: this.updateCompanyGroupUsers,
    // company group projects (visibility)
    getCompanyGroupProjectVisibility: this.getCompanyGroupProjectVisibility,
    updateCompanyGroupProjectVisibility: this.updateCompanyGroupProjectVisibility,
    // company projects
    getAllCompanyProjects: this.getAllCompanyProjects,
    addCompanyProject: this.addCompanyProject,
    updateCompanyProject: this.updateCompanyProject,
    deleteCompanyProject: this.deleteCompanyProject,
    // company project groups
    getCompanyProjectGroups: this.getCompanyProjectGroups,
    // company project group users
    getCompanyProjectGroupUsers: this.getCompanyProjectGroupUsers,
    updateCompanyProjectGroupUsers: this.updateCompanyProjectGroupUsers,
    // company info/settings
    getCompanyInfo: this.getCompanyInfo,
    updateCompanyInfo: this.updateCompanyInfo,
    syncCompanyTranscoderSettingsToAllProjects: this.syncCompanyTranscoderSettingsToAllProjects,
    // company login methods (SSO providers)
    getCompanyLoginServices: this.getCompanyLoginServices,
    addCompanyLoginService: this.addCompanyLoginService,
    updateCompanyLoginService: this.updateCompanyLoginService,
    enableDisableCompanyLoginService: this.enableDisableCompanyLoginService,
    deleteCompanyLoginService: this.deleteCompanyLoginService,
    // company video engines
    getAllCompanyVideoEngines: this.getAllCompanyVideoEngines,
    updateCompanyVideoEngine: this.updateCompanyVideoEngine,
    // user admin (site wide)
    siteAdminDisableUser2FA: this.siteAdminDisableUser2FA,
    siteAdminDeleteUser: this.siteAdminDeleteUser
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: ICompanyAdminStore = {
  //  ...
  // }

  render () {
    return (
      <CompanyAdminContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for ICompanyAdminStore */ }}
      >
        {this.props.children}
      </CompanyAdminContext.Provider>
    )
  }
}

const withCompanyAdminContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withCompanyAdminContextHOC = (props: any) => (
    <CompanyAdminContext.Consumer>
      {(companyAdminContext) => {
        if (companyAdminContext === null) {
          throw new Error('CompanyAdminConsumer must be used within a CompanyAdminProvider')
        }
        // console.log('withCompanyAdminContext - render - CompanyAdminContext.Consumer - companyAdminContext.store: ', companyAdminContext.store)
        return (<Component {...props} {...{ companyAdminContext: companyAdminContext }} />)
      }}
    </CompanyAdminContext.Consumer>
  )
  return withCompanyAdminContextHOC
}

const CompanyAdminProviderWithContext = withUserContext(CompanyAdminProvider)

export { CompanyAdminProviderWithContext as CompanyAdminProvider }
export { withCompanyAdminContext }
